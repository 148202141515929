import React, { Component } from 'react'
import PropTypes from 'prop-types';
import moment from 'moment'
import { connect } from 'react-redux'

const _1min = 60 * 1000;
const _1hour = 3600 * 1000;
/*
const pad = (val, size = 2) => {
	if (val.toString().length >= size) {
		return val.toString();
	}
	return `00000${val}`.slice(-size);
}

const formatTime = (milliseconds) => {
	let rest = milliseconds;
	const min = Math.floor(rest / _1min);
	rest -= min * _1min;
	const sec = Math.floor(rest / 1000);
	return `${pad(min)}:${pad(sec)}`;
}
*/

class Timer extends Component {
	constructor(props) {
		super(props)
		this.state = {
			time: '00:00',
			started: false,
		}
		this.timeout = null
		props.getDiftime();
	}

	componentDidMount() {
		this.setState({ started: true })
		this.setTime()
		console.log('Timer.props', this.props)
	}

	componentWillUnmount() {
		if (this.timeout) clearTimeout(this.timeout)
	}

	setTime = () => {
		let { until, onTimeout, diftime } = this.props
		const a = until;
		const b = Date.now();
		const diff = a - b - diftime;
		if (diff <= 0) {
			this.setState({ started: false })
			onTimeout();
		} else {
			let format = 'mm:ss';
			if (diff >= _1hour) {
				format = 'HH:mm:ss';
			}
			this.setState({ time: moment.utc(diff).format(format) }, this.tick)
		}
	}

	tick = () => {
		const { started } = this.state
		if (started) {
			this.timeout = setTimeout(() => {
				this.setTime()
			}, 1000)
		} else {
			if (this.timeout) clearTimeout(this.timeout)
		}
	}

	render() {
		const { time } = this.state
		return <>{time}</>
	}
}

Timer.propTypes = {
	onTimeout: PropTypes.func,
	until: PropTypes.number,
}

Timer.defaultProps = {
	onTimeout: () => { },
}

export default connect(
	state => ({
		diftime: state.Timer.diftime,
	}), {
		getDiftime: () => ({ type: 'TM_DIFTIME_FETCH' }),
	}
)(Timer);

