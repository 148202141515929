/**
 * Defines property [[OpenViduError.name]]
 */
export const OpenViduError = {

  /**
   * Browser is not supported by OpenVidu.
   * Returned upon unsuccessful [[Session.connect]]
   */
  BROWSER_NOT_SUPPORTED: {
    description: 'Браузер не поддерживается',
    searchText: '',
  },

  /**
   * The user hasn't granted permissions to the required input device when the browser asked for them.
   * Returned upon unsuccessful [[OpenVidu.initPublisher]] or [[OpenVidu.getUserMedia]]
   */
  DEVICE_ACCESS_DENIED: {
    description: 'Ошибка при подключении камеры и микрофона',
    searchText: 'Как разрешить браузеру доступ к камере',
  },

  /**
   * The required input device is probably being used by other process when the browser asked for it.
   * This error can also be triggered when the user granted permission to use the devices but a hardware
   * error occurred at the OS, browser or web page level, which prevented access to the device.
   * Returned upon unsuccessful [[OpenVidu.initPublisher]] or [[OpenVidu.getUserMedia]]
   */
  DEVICE_ALREADY_IN_USE: {
    description: "DEVICE_ALREADY_IN_USE",
    searchText: '',
  },

  /**
   * The user hasn't granted permissions to capture some desktop screen when the browser asked for them.
   * Returned upon unsuccessful [[OpenVidu.initPublisher]] or [[OpenVidu.getUserMedia]]
   */
  SCREEN_CAPTURE_DENIED: {
    description: 'Ошибка при записи экрана',
    searchText: 'Как разрешить браузеру запись экрана',
  },

  /**
   * Browser does not support screen sharing.
   * Returned upon unsuccessful [[OpenVidu.initPublisher]] or [[OpenVidu.getUserMedia]]
   */
  SCREEN_SHARING_NOT_SUPPORTED: {
    description: 'Ваш браузер не поддерживает запись экрана',
    searchText: '',
  },

  /**
   * Only for Chrome, there's no screen sharing extension installed
   * Returned upon unsuccessful [[OpenVidu.initPublisher]] or [[OpenVidu.getUserMedia]]
   */
  SCREEN_EXTENSION_NOT_INSTALLED: {
    description: 'SCREEN_EXTENSION_NOT_INSTALLED',
    searchText: 'Как установить расширения для Chrome для записи экрана',
  },

  /**
   * Only for Chrome, the screen sharing extension is installed but is disabled
   * Returned upon unsuccessful [[OpenVidu.initPublisher]] or [[OpenVidu.getUserMedia]]
   */
  SCREEN_EXTENSION_DISABLED: {
    description: 'SCREEN_EXTENSION_DISABLED',
    searchText: 'Как включить расширение для Chrome для записи экрана',
  },

  /**
   * No video input device found with the provided deviceId (property [[PublisherProperties.videoSource]])
   * Returned upon unsuccessful [[OpenVidu.initPublisher]] or [[OpenVidu.getUserMedia]]
   */
  INPUT_VIDEO_DEVICE_NOT_FOUND: {
    description: 'Вебкамера не найдена',
    searchText: '',
  },

  /**
   * No audio input device found with the provided deviceId (property [[PublisherProperties.audioSource]])
   * Returned upon unsuccessful [[OpenVidu.initPublisher]] or [[OpenVidu.getUserMedia]]
   */
  INPUT_AUDIO_DEVICE_NOT_FOUND: {
    description: 'Микрофон не найден',
    searchText: '',
  },

  /**
   * There was an unknown error when trying to access the specified audio device
   * Returned upon unsuccessful [[OpenVidu.initPublisher]] or [[OpenVidu.getUserMedia]]
   */
  INPUT_AUDIO_DEVICE_GENERIC_ERROR: {
    description: 'INPUT_AUDIO_DEVICE_GENERIC_ERROR',
    searchText: '',
  },

  /**
   * Method [[OpenVidu.initPublisher]] or  [[OpenVidu.getUserMedia]] has been called with properties `videoSource` and `audioSource` of
   * [[PublisherProperties]] parameter both set to *false* or *null*
   */
  NO_INPUT_SOURCE_SET: {
    description: 'NO_INPUT_SOURCE_SET',
    searchText: '',
  },

  /**
   * Some media property of [[PublisherProperties]] such as `frameRate` or `resolution` is not supported
   * by the input devices (whenever it is possible they are automatically adjusted to the most similar value).
   * Returned upon unsuccessful [[OpenVidu.initPublisher]] or [[OpenVidu.getUserMedia]]
   */
  PUBLISHER_PROPERTIES_ERROR: {
    description: 'PUBLISHER_PROPERTIES_ERROR',
    searchText: '',
  },

  /**
   * The client tried to call a method without the required permissions. This can occur for methods [[Session.publish]],
   * [[Session.forceUnpublish]], [[Session.forceDisconnect]], [[Stream.applyFilter]], [[Stream.removeFilter]]
   */
  OPENVIDU_PERMISSION_DENIED: {
    description: 'OPENVIDU_PERMISSION_DENIED',
    searchText: '',
  },

  /**
   * _Not in use yet_
   */
  OPENVIDU_NOT_CONNECTED: {
    description: 'OPENVIDU_NOT_CONNECTED',
    searchText: '',
  },

  /**
   * _Not in use yet_
   */
  GENERIC_ERROR: {
    description: 'GENERIC_ERROR',
    searchText: '',
  },
}
