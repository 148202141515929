import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { persistStore, persistReducer } from 'redux-persist'
import { composeWithDevTools } from 'redux-devtools-extension'
import { sagaInit } from '../reducers/saga.js'
import reducers, { persistConfig } from '../reducers';
import { _store } from '../reducers/_store'
import lazyStore from '../redux/calls/lazyStore.js'

const persistedReducer = persistReducer(persistConfig, reducers)

const sagaMiddleware = createSagaMiddleware()
const middlewareList = [sagaMiddleware]
const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(...middlewareList)))

const persistor = persistStore(store, null, () => {
  store.getState()
})

lazyStore.store = store
lazyStore.persistor = persistor
sagaMiddleware.run(sagaInit, store)
_store.store = store
_store.persistor = persistor

export { store, persistor }
