function stringifySafe(obj) {
  if (typeof obj === 'string') {
    return obj
  }
  const seen = []
  return JSON.stringify(obj, function (key, val) {
    if (val != null && typeof val == "object") {
      if (seen.indexOf(val) >= 0) {
        return;
      }
      seen.push(val);
    }
    return typeof val === 'function' ? `function ${val.name}` : val
  })
}

export default stringifySafe