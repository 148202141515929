import React, { Component } from 'react'
import Input from '../Input'
import './style.scss'

class MaterialDesignInput extends Component {
	constructor(props) {
		super(props)

		this.state = {
			focused: false,
		}
	}

	render() {
		const { focused } = this.state
		const { onChange, icon, type, title, value, placeholder, autoFocus } = this.props
		return (
			<div className={`material-design-input${focused ? ' focused' : ''}`}>
				{icon && icon}
				<Input
					ref={(ref) => (this.input = ref)}
					title={title}
					type={type}
					value={value}
					onChange={onChange}
					autoFocus={autoFocus}
					placeholder={placeholder}
					onFocus={() => {
						this.setState({ focused: true })
					}}
					onBlur={() => {
						this.setState({ focused: false })
					}}
				/>
			</div>
		)
	}
}

export default MaterialDesignInput
