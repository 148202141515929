import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import './style.scss';

import throttle from 'lodash/throttle';
import Button from '../../components/Button';
import { CallIcon, DeclineCallIcon } from '../../resources/icons';
import sound from './sound'

class CallManager extends React.Component {
	constructor(props) {
		super(props);

		this.interval = false;
		this.sound = sound;
		this.tryPlay = throttle(this.tryPlay, 3000);
	}

	componentDidMount() {
		this.sound.init(this.tryPlay);
		setTimeout(document.body.click, 1000);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.token !== this.props.token && this.props.token) {
			this.props.history.replace('/Conference');
		}
		if (this.props.calls.length) {
			this.tryPlay();
			clearInterval(this.interval);
			this.interval = setInterval(this.tryPlay, 3000);
		} else {
			clearInterval(this.interval);
		}
	}

	tryPlay = () => {
		if (this.props.calls.length) {
			const res = this.sound.play();
			if (res.then) {
				res.catch((e) => console.log('[error] sound.play ', e))
			}
		}
	}

	render() {
		const calls = this.props.calls || [];
		const lastCall = calls[calls.length - 1] || {};
		const isCalling = !!calls.length;
		return <div className='callerPlane' style={isCalling ? {} : { display: 'none' }}>
			<div className='background' />
			<div className='control'>
				<div className='caller'>
					Вас вызывает {(lastCall.caller || {}).login}
				</div>
				<Button
					className={'Decline'}
					title={<DeclineCallIcon />}
					onClick={() => this.props.Decline(lastCall.session)}
					radius={60}
					style={{ background: '#ce112f' }}
				/>
				<Button
					className={'Answer'}
					title={<CallIcon />}
					onClick={() => this.props.Answer(lastCall.session)}
					radius={60}
					style={{ background: '#32a852' }}
				/>
			</div>
		</div>
	}
}

export default withRouter(connect(state => (
	{
		calls: state.Conference.calls,
		token: state.Conference.token,
	}),
	{
		Answer: params => ({ type: 'ANSWER_CALL', params }),
		Decline: params => ({ type: 'DECLINE_CALL', params }),
	},
)(CallManager));