const lazyStore = {
  /**
   * @return {import('redux').Store}
   */
  get store() {
    return this._store
  },
  /**
   * @param {import('redux').Store}
   */
  set store(store) {
    this._store = store
  },
  /**
   * @return {import('redux-persist/es/types').Persistor}
   */
  get persistor() {
    return this._persistor
  },
  /**
   * @param {import('redux-persist/es/types').Persistor} persistor
   */
  set persistor(persistor) {
    this._persistor = persistor
  },
}

export default lazyStore