import axios from 'axios';
import { store } from '../store';
import config from '../config';

const commonHeaders = {
	'Content-Type': 'application/json',
	'X-Requested-With': 'XMLHttpRequest',
	'Access-Control-Allow-Origin': '*',
};

const getAuth = config.isMobile ? () => store.getState().telemedicine.User.session : () => store.getState().User.session;

const handleError = (e) => {
	if (e.response && e.response.status === 401) {
		store.dispatch({ type: 'LOGOUT' });
		return true;
	}
}

const timeout = 10000;

export const get = async ({ url, params }) => {
	try {
		const req = await axios.get(url, { params, headers: { ...commonHeaders, 'X-Session-Key': getAuth() }, withCredentials: true, timeout });
		return req.data;
	} catch (error) {
		console.log('error: GET ', url, error);
		const res = handleError(error);
		if (!res) {
			store.dispatch({ type: 'ERROR_PUSH', params: error.message });
		}
		return { success: 0, error: error.message };
	}
};

export const post = async ({ url, data, params }) => {
	try {
		const req = await axios.post(url, data, { params, headers: { ...commonHeaders, 'X-Session-Key': getAuth() }, withCredentials: true, timeout });
		return req.data;
	} catch (error) {
		console.log('error: POST ', url, error);
		const res = handleError(error);
		if (!res) {
			store.dispatch({ type: 'ERROR_PUSH', params: error.message });
		}
    if (error.response && error.response.data && error.response.data.error) {
      return { success: 0, error: error.response.data.error }
    }
		return { success: 0, error: error.message };
	}
};

export const put = async ({ url, data, params }) => {
	try {
		const req = await axios.put(url, data, { params, headers: { ...commonHeaders, 'X-Session-Key': getAuth() }, withCredentials: true, timeout });
		return req.data;
	} catch (error) {
		console.log('error: PUT ', url, error);
		const res = handleError(error);
		if (!res) {
			store.dispatch({ type: 'ERROR_PUSH', params: error.message });
		}
		return { success: 0, error: error.message };
	}
};

export const del = async ({ url, params }) => {
	try {
		const req = await axios.delete(url, { params, headers: { ...commonHeaders, 'X-Session-Key': getAuth() }, withCredentials: true, timeout });
		return req.data;
	} catch (error) {
		console.log('error: DEL ', url, error);
		const res = handleError(error);
		if (!res) {
			store.dispatch({ type: 'ERROR_PUSH', params: error.message });
		}
		return { success: 0, error: error.message };
	}
};

export const api = {
	get,
	post,
	put,
	del,
};
