import React from 'react';
import './style.scss';

export default class Modal extends React.Component {
	render() {
		if (!this.props.visible) return <></>
		else return <div className='modalPlane'>
			<div className='modalShutter' onClick={this.props.dismissable?() => this.props.onClose():()=>{}}/>
			<div className='modalBody'>
				{this.props.dismissable && <div className='close' onClick={() => this.props.onClose()}>
					<svg height="24" viewBox="0 0 24 24" width="24">
						<path fill="var(--bright)" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
						<path d="M0 0h24v24H0z" fill="none"/>
					</svg>
				</div>}
				<div className='title'>
					{this.props.title}
				</div>
				<div className='body'>
					{this.props.children}
				</div>
			</div>
		</div> 
	}
}