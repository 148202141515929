import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import ScreenWrapper from '../../components/ScreenWrapper';

import './style.scss';

import Input from '../../components/Input';
import Button from '../../components/Button';
import { CallIcon } from '../../resources/icons';

class RoomJoin extends React.Component {
	constructor(props) {
		super(props);
		
		this.state = {
			user: '',
		}
	}

	componentDidMount() {
		if (!this.props.navigation) {
			this.props.showNavigation();
		}
	}

	render() {
		return <ScreenWrapper>
			<div className='infoBlock'>
				<h2>Позвонить пациенту</h2>
				<div className='CallBlock'>
					<Input title={'Логин пользователя'} value={this.state.user} onChange={user => this.setState({ user })}/>
					<Button
						title={<CallIcon/>}
						onClick={() => this.props.CallPatient({ sessionName: +new Date(), user: this.state.user })}
						disabled={!this.state.userid && this.state.userid === ''}
						radius={60}
						style={{ background: '#32a852' }}
					/>
				</div>
			</div>
		</ScreenWrapper>
	}
}

export default withRouter(connect(state => (
    {
		fetch: state.Conference.fetch,
    }),
    {
		CallPatient: params => ({ type: 'CREATE_CONFERENCE', params })
    },
)(RoomJoin));