import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import moment from 'moment';
import { TrashIcon, FileIcon } from '../../resources/icons'
import config from '../../config'
import './style.scss'
import 'moment/locale/ru';

const formatDateTime = (date) => {
	return moment(moment.utc(date).toDate()).format('DD MMM, HH:mm').replace('.', '');
};

window._moment = moment;

class AttachmentList extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	componentDidMount() { }

	renderRows = () => {
		const { onDelete = () => { }, attachments, hideDelButton, showDate, user } = this.props
		return (
			<div className='attachments rows'>
				{attachments.map((file, i) => (
					<div key={file.attachment_id.toString()} className='attachment'>
						<div className='filename'>
							<FileIcon />
							<a target='_blank' rel='noopener noreferrer' href={`${config.url}/${file.path}`}>
								{file.name}
							</a>
						</div>
						{!hideDelButton && user.user_id === file.user_id && <div
							className='trash'
							onClick={() => {
								onDelete(file)
							}}>
							<TrashIcon />
						</div>}
						{!!showDate && <div className="date">
							{formatDateTime(file.inserted_at)}
						</div>}
					</div>
				))}
			</div>
		)
	}

	renderColumns = () => {
		const attachments = this.props.attachments.reduce((acc, file, index) => {
			const i = Math.floor(index / 2)
			if (!acc[i]) acc[i] = []
			acc[i].push(file)
			return acc
		}, [])
		return (
			<div className='attachments columns'>
				{attachments.map((array, index) => {
					return (
						<div key={index.toString()} className='attachments_row'>
							{array.map((file) => (
								<div key={file.attachment_id.toString()} className='attachment'>
									<div className='filename'>
										<FileIcon />
										<a target='_blank' rel='noopener noreferrer' href={`${config.url}/${file.path}`}>
											{file.name}
										</a>
									</div>
								</div>
							))}
						</div>
					)
				})}
			</div>
		)
	}

	render() {
		const { type = 'rows' } = this.props
		switch (type) {
			case 'rows':
				return this.renderRows()
			case 'columns':
				return this.renderColumns()
			default:
				return this.renderRows()
		}
	}
}

AttachmentList.propTypes = {
	type: PropTypes.oneOf(['rows', 'columns']),
	attachments: PropTypes.arrayOf(PropTypes.object),
	onDelete: PropTypes.func,
	hideDelButton: PropTypes.bool,
	showDate: PropTypes.bool,
}

export default connect(
	state => ({
		user: state.User.user,
	}),
	{
	},
)(AttachmentList)
