import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../components/MModal';
import CheckButton from '../../components/CheckButton';
import CheckBox from '../../components/CheckBox';
import BButton from '../../components/BButton';
import {
  ClinikaIcon, CameraIcon, MPhoneIcon, ChatIcon,
} from '../../resources/icons';

import './style.scss';

const AppointmentTypeView = (props) => {
  const {
    onBack,
    onShutter,
    visible,
    onClickVideo,
    onClickAudio,
    onClickChat,
    videoChecked,
    audioChecked,
    chatChecked,
    checkedPrivacy,
    checkedMedic,
    isActiveNextStep,
    onContinue,
  } = props;
  return <Modal
    visible={visible}
    onBack={onBack}
    onShutter={onShutter}
    className="AppointmentTypeModal"
    title={'Выберите формат записи'}
  >
    <div className="AppointmentType">
      <CheckButton
        Icon={<ClinikaIcon />}
        title={'Прием в клинике'}
        description={'Очный прием врача'}
        disabled
      />
      <div className="Info">
        <div className="Title">Онлайн-консультация</div>
        <div className="Description">
          <span>Если у вас не было <span className="Bold">очного визита</span> к врачу на онлайн-консультацию, доктор <span className="Bold">не имеет права</span> выписать вам лекарства или поставить <span className="Bold">диагноз - это закон</span></span>
        </div>
      </div>
      <CheckButton
        Icon={<CameraIcon />}
        iconProps={{ color: 'currentColor' }}
        title={'Видео звонок'}
        description={'Как в скайпе только с врачом'}
        onClick={onClickVideo}
        checked={videoChecked}
      />
      <CheckButton
        Icon={<MPhoneIcon />}
        iconProps={{ color: 'currentColor' }}
        title={'Аудио'}
        description={'Как по обычному телефону'}
        onClick={onClickAudio}
        checked={audioChecked}
      />
      <CheckButton
        Icon={<ChatIcon />}
        iconProps={{ color: 'currentColor' }}
        title={'Чат'}
        description={'Когда писать удобнее или скорость интернета не позволяет'}
        onClick={onClickChat}
        checked={chatChecked}
      />
      <CheckBox
        onClick={() => { }}
        label={'Согласие на обработку персональных данных и условия предоставления услуг'}
        checked={checkedPrivacy}
      />
      <CheckBox
        onClick={() => { }}
        label={'Согласие на медицинское вмешательство'}
        checked={checkedMedic}
      />
      <BButton onClick={onContinue} disabled={!isActiveNextStep}>Далее</BButton>
    </div>
  </Modal>;
}

AppointmentTypeView.propTypes = {
  onBack: PropTypes.func,
  onShutter: PropTypes.func,
  onContinue: PropTypes.func,
  visible: PropTypes.bool,
  onClickAudio: PropTypes.func,
  onClickVideo: PropTypes.func,
  onClickChat: PropTypes.func,
  videoChecked: PropTypes.bool,
  audioChecked: PropTypes.bool,
  chatChecked: PropTypes.bool,
  isActiveNextStep: PropTypes.bool,
  checkedPrivacy: PropTypes.bool,
  checkedMedic: PropTypes.bool,
}

export default AppointmentTypeView;