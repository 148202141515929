import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import './style.scss'
import Header from '../Header'
import { formatDoctorName } from '../../screens/DoctorsDiary/utils'
// import config from '../../config';
// import { LogoutIcon } from '../../resources/icons';

class ScreenWrapper extends React.Component {
	render() {
		const { ScreenHeader, onBack, showLogo, user, conferenceId } = this.props
		return (
			<div className={`ScreenWrapper ${this.props.className}`}>
				<div className='ScreenHeaderContainer'>
					{ScreenHeader || (
						<Header
							showLogo={showLogo}
							onBack={onBack}
							conferenceId={conferenceId}
							onLogout={user && user.role === 'doctor' ? () => this.props.history.replace('/Auth') : null}
							doctor={{
								name: user && user.role === 'doctor' ? formatDoctorName(user) : '',
								photo: this.props.user.photo,
							}}
						/>
					)}
				</div>
				<div className='content' style={this.props.style}>
					{this.props.children}
				</div>
			</div>
		)
	}
}

ScreenWrapper.propTypes = {
	ScreenHeader: PropTypes.node,
}

export default withRouter(
	connect(
		(state) => ({
			user: state.User.user,
		}),
		{}
	)(ScreenWrapper)
)
