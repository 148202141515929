import { get, post, del } from './networking';
import { call, put } from 'redux-saga/effects';
import config from '../config';

const initialState = {
    update: 0,
    presets: [],
    activity: {},
}

export const ChatReducer = (state = initialState, action) => {
    let index;
    let roomActivity;
    switch (action.type) {
        case 'COUP_CHAT':
            return { ...state, [action.params]: [], update: state.update + 1 }
        case 'DECOUP_CHAT':
            if (state[action.params]) delete state[action.params];
			return { ...state, update: state.update + 1 }
		case 'CHAT_MESSAGE':
            if (state[action.params.room]) state[action.params.room].push(action.params);
            return { ...state, update: state.update + 1 }
        case 'CHAT_MESSAGE_DELETED':
            if (!state[action.params.room]) {
                return { ...state, update: state.update + 1 };
            }
            return {
                ...state,
                [action.params.room]: state[action.params.room].filter(m => m.chat_message_id !== action.params.chat_message_id),
                update: state.update + 1,
            };
        case 'SET_CHAT_ACTIVITY':
            roomActivity = state.activity[action.params.room] || [];
            index = roomActivity.findIndex(u => u.user_id === action.params.user.user_id);
            if (index !== -1) {
                return state;
            }
            return {
                ...state,
                activity: {
                    ...state.activity,
                    [action.params.room]: [...roomActivity, action.params.user],
                },
                update: state.update + 1,
            };
        case 'DELETE_CHAT_ACTIVITY':
            roomActivity = state.activity[action.params.room] || [];
            index = roomActivity.findIndex(u => u.user_id === action.params.user.user_id);
            if (index === -1) {
                return state;
            }
            roomActivity = [...roomActivity];
            roomActivity.splice(index, 1);
            return {
                ...state,
                activity: {
                    ...state.activity,
                    [action.params.room]: roomActivity,
                },
                update: state.update + 1,
            }
		case 'CHAT_HISTORY':
			state[action.params.room] = action.params.history;
            return { ...state, update: state.update + 1 }
        case 'SET_PRESETS':
            return { ...state, presets: action.params }
        case 'APPENDED_PRESET':
            index = state.presets.findIndex(datum => datum.id === action.params.id);
            if (index+1) {
                state.presets[index] = action.params;
                return { ...state }
            } else {
                return { ...state, presets: [ action.params, ...state.presets ] }
            }
        case 'DETACH_PRESET':
            state.presets.splice(state.presets.findIndex(datum => datum.id === action.params.id), 1);
            return { ...state, presets: [ ...state.presets ] }
        case 'persist/REHYDRATE':
            if (action.payload) return { ...state, presets: action.payload.Chats.presets || [] }
            else return state
        default:
            return state;
    }
}

export function* _attachPreset({ params }) {
    const result = yield call(post, {
        url: `${config.url}/api/chat-presets`,
        data: params,
	});
	if (result.success) {
		yield put({ type: 'APPENDED_PRESET', params: result.data });
	}
}

export function* _getPresets() {
    const result = yield call(get, {
		url: `${config.url}/api/chat-presets`,
	});
	if (result.success) {
		yield put({ type: 'SET_PRESETS', params: result.data });
	}
}

export function* _deletePreset({ params }) {
    yield call(del, {
		url: `${config.url}/api/chat-presets/${params.id}`,
	});
}