const initialState = {
  online: [],
  offline: [],
}

function findUser(user) {
  return (this.user_id && this.user_id === user.user_id) || (this.login && this.login === user.login);
}

export const UsersReducer = (state = initialState, action) => {
  const { payload: user } = action;
  let onlineIndex;
  let offlineIndex;
  switch (action.type) {
    case 'SET_USER_ONLINE':
      onlineIndex = state.online.findIndex(findUser, user);
      if (onlineIndex !== -1) {
        state.online[onlineIndex] = user;
      } else {
        state.online = [...state.online, user];
      }
      offlineIndex = state.offline.findIndex(findUser, user);
      if (offlineIndex !== -1) {
        state.offline = state.offline.filter((_, i) => i !== offlineIndex);
      }
      return {
        ...state,
        online: [...state.online],
        offline: [...state.offline],
      }
    case 'SET_USER_OFFLINE':
      onlineIndex = state.online.findIndex(findUser, user);
      if (onlineIndex !== -1) {
        state.online = state.online.filter((_, i) => i !== onlineIndex);
      }
      offlineIndex = state.offline.findIndex(findUser, user);
      if (offlineIndex !== -1) {
        state.offline[offlineIndex] = user;
      } else {
        state.offline = [...state.offline, user];
      }
      return {
        ...state,
        online: [...state.online],
        offline: [...state.offline],
      };
    case 'SHIFT_USER_OFFLINE':
      return {
        ...state,
        offline: [...state.offline.slice(1)],
      };
    case 'SHIFT_USER_ONLINE':
      state.online.shift();
      return {
        ...state,
        online: [...state.online.slice(1)],
      }
    default:
      return state;
  }
}