import React from 'react';
import { formatName } from '../../screens/DoctorsDiary/utils'
import ActivityBubbles from '../ActivityBubbles';

class ChatActivity extends React.Component {
  render() {
    const { user } = this.props;
    return <div className="ChatActivity" style={{ opacity: user ? 1 : 0 }}>
      <ActivityBubbles className="ChatActivityBubbles" />
      <p className="ChatUser">
        <span className="Bold">{formatName(user)}</span>
        <span>{' печатает'}</span>
      </p>
    </div>
  }
}

export default ChatActivity;