import React from 'react';
import PropTypes from 'prop-types'
import CheckBox from '../CheckBox';
import Button from '../BButton'
import './style.scss'

const privacyPolicy = 'https://mcmedic.ru/sites/default/files/personal_processing.pdf';
const idcPolicy = 'https://mcmedic.ru/sites/default/files/idc_telemedicina.pdf';

export default class ConfirmPolicies extends React.PureComponent {
  state = { privacy: false, service: false }
  render() {
    const { onSubmit } = this.props;
    const { checkedPrivacy, checkedMedic } = this.state;
    return <div className="ConfirmPolicies">
      <div className="ConfirmPoliciesContent">
        <CheckBox
          onClick={() => this.setState({ checkedPrivacy: !checkedPrivacy })}
          label={<span>Согласие на обработку <a href={privacyPolicy} target="_blank">персональных данных и условия предоставления услуг</a></span>}
          checked={checkedPrivacy}
        />
        <CheckBox
          onClick={() => this.setState({ checkedMedic: !checkedMedic })}
          label={<span>Согласие на <a href={idcPolicy} target="_blank">медицинское вмешательство</a></span>}
          checked={checkedMedic}
        />
      </div>
      <Button
        className="ConfirmPoliciesSubmit"
        disabled={!checkedPrivacy || !checkedMedic}
        onClick={onSubmit}
      >
        {'Далее'}
      </Button>
    </div>
  }
}

ConfirmPolicies.propTypes = {
  onSubmit: PropTypes.func,
}