import React from 'react'
import PropTypes from 'prop-types'
import { LoadingIcon } from '../../resources/icons'
import './style.scss'

const BButton = (props) => {
	const { onClick, disabled, className, style = {}, loading, color = 'red' } = props
	let iconColor, buttonColor, textColor
	switch (color) {
		case 'red':
			textColor = iconColor = 'bright'
			buttonColor = 'primary'
			break
		case 'pink':
			textColor = iconColor = 'primary'
			buttonColor = 'primaryDim'
			break
		default:
			textColor = iconColor = 'bright'
			buttonColor = 'primaryDim'
			break
	}
	if (disabled) {
		buttonColor = 'iconsDim'
	}
	return (
		<div
			style={{ ...style, color: `var(--${textColor})`, background: `var(--${buttonColor})` }}
			className={`BButton ${disabled ? 'disabled' : ''} ${className || ''}`}
			onClick={disabled || loading ? undefined : onClick}>
			{loading ? <LoadingIcon color={iconColor} /> : props.children}
		</div>
	)
}

BButton.propTypes = {
	disabled: PropTypes.bool,
	loading: PropTypes.bool,
	onClick: PropTypes.func,
	className: PropTypes.string,
	color: PropTypes.oneOf(['red', 'pink']),
}

export default BButton
