import React from 'react';
import View from './view';
import { connect } from 'react-redux';

class AppointmentTypeContainer extends React.Component {
  render() {
    return <View
    />;
  }
};

export default connect(
  state => ({
    user: state.User.user,
  }),
  {},
)(AppointmentTypeContainer);
