import React, { Component } from 'react'
import { RatingIcon, RatingIconFilled } from '../../resources/icons'
import './style.scss'

class Rating extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {}

  render() {
    const { value = 0, onChange = () => {}, style = {} } = this.props
    return (
      <div className="rating-area" style={style}>
        {[1, 2, 3, 4, 5].map((v) => (
          <div
            className="rating-star"
            onClick={() => {
              if (+v === 1 && +value === 1) {
                onChange(0)
              } else {
                onChange(v)
              }
            }}
          >
            {value >= v ? <RatingIconFilled /> : <RatingIcon />}
          </div>
        ))}
      </div>
    )
  }
}

export default Rating
