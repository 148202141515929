export const ErrorReducer = (state = [], action) => {
	switch (action.type) {
		case 'ERROR_PUSH':
			state.push(action.params);
			return [ ...state ];
		case 'ERROR_POP':
			state.pop(action.params);
			return [ ...state ];
		default:
			return [ ...state ];
	}
}