import socketIO from 'socket.io-client'
import { eventChannel } from 'redux-saga'
import { all, put, call, select, takeLatest, takeEvery, take, spawn, fork } from 'redux-saga/effects'
import config from '../config'
import events from './events';

import { _Login, _MedicLogin, _AutoLogin, _Logout, _CheckTMAuth } from './user'

import { _getAppointments, _getAppointment } from './appointments'

import { _CreatePayment } from './payment'
import { _attachPreset, _getPresets, _deletePreset } from './chat';
import { _getChatRoomByAppointment, _getChatRoomUnreadCount, _updateChatRoomUnreadCount } from './chatRooms';
import { patientsSaga } from './pateints'
import historySaga from './historySaga'
import { _getAttachments, _addAttachment, _delAttachment } from './attachments'
import { _sendDoctorRating } from './doctors';
import * as connectionTestTypes from './connectionTest/types'
import * as connectionTestSaga from './connectionTest/connectionTest.saga'
import { getDiftime } from './timer';
import { _store } from './_store'
import getAppState from './getAppState'
import { watchBonus } from './bonus'
import { forceConnect } from '../redux/calls/calls.socket';
import * as uuid from 'uuid'

const time = () => `[${new Date().toISOString().slice(0, -1).replace('T', ' ')}]`;
let socket = null

export function* sagaInit(store) {
	yield all([_waitPersist(), WatchActions(store), patientsSaga(), historySaga()])
}

const chatActivities = {};

//eslint-disable-next-line
export function* WatchActions() {
	try {
		yield fork(watchBonus)
		yield takeLatest('LOGIN', _Login)
		yield takeLatest('CHECK_TM_AUTH', _CheckTMAuth);
		yield takeLatest('MEDIC_LOGIN', _MedicLogin)
		yield takeLatest('AUTOLOGIN', _AutoLogin)
		yield takeLatest('LOGOUT', _Logout)
		yield takeLatest('SOCKET:CONNECT', connect)

		yield takeLatest('TM_DIFTIME_FETCH', getDiftime)

		yield takeEvery('PAYMENT_CREATE', _CreatePayment)

		yield takeLatest('PATIENT_APPOINTMENTS_GET', _getAppointments);
		yield takeLatest('PATIENT_APPOINTMENT_GET', _getAppointment);

		yield takeLatest('SEND_DOCTOR_RATING', _sendDoctorRating);

		yield takeLatest(connectionTestTypes.CONNECTION_TEST_START, connectionTestSaga.startConnectionTest)
		yield takeLatest(connectionTestTypes.CONNECTION_TEST_RECORD_START, connectionTestSaga.startTestRecord)
		yield takeLatest(connectionTestTypes.CONNECTION_TEST_RECORD_STOP, connectionTestSaga.stopTestRecord)
		yield takeLatest(connectionTestTypes.CONNECTION_TEST_STOP, connectionTestSaga.stopConnectionTest)

		yield takeLatest('APPEND_PRESET', _attachPreset);
		yield takeLatest('GET_PRESETS', _getPresets);
		yield takeLatest('DETACH_PRESET', _deletePreset);
		yield takeLatest('CHAT_ROOM_BY_APPOINTMENT_REQUEST', _getChatRoomByAppointment)
		yield takeLatest('CHAT_ROOM_UNREAD_REQUEST', _getChatRoomUnreadCount)
		yield takeLatest('CHAT_ROOM_UNREAD_UPDATE', _updateChatRoomUnreadCount)
		yield takeLatest('GET_NEW_TOKEN', ({ payload }) => {
			socket.emit('conference:new-token', payload)
		});

		yield takeLatest('CREATE_CONFERENCE', ({ params }) => {
			socket.emit('conference:create', params)
			events.emit('play-sound');
		})
		yield takeLatest('SOCKET:DISCONNECT_FORCE', () => {
      if (socket) {
        socket.disconnect();
      }
		})
		yield takeEvery('SEND_CHAT_ACTIVITY', ({ params }) => {
			const { room } = params;
			socket.emit('chat:activity', { room });
		})
		yield takeEvery('AUTO_ANSWER_CALL', ({ params }) => {
			socket.emit('conference:answer', params)
		})
		yield takeLatest('RECALL_USER', ({ payload: session }) => {
			socket.emit('conference:recall_user', session);
			events.emit('play-sound');
		})
		yield takeEvery('ANSWER_CALL', ({ params }) => {
			socket.emit('conference:answer', params)
		})
		yield takeEvery('DECLINE_CALL', ({ params }) => {
			socket.emit('conference:decline', params)
		})
		yield takeEvery('STOP_CALLING', ({ payload: session }) => {
			socket.emit('conference:stop_calling', session)
			events.emit('stop-sound');
		})
		yield takeLatest('ROOM_DISCONNECT', ({ params }) => {
			socket.emit('conference:leave', params)
			events.emit('stop-sound');
		})
		yield takeLatest('REJOIN_CONFERENCE', ({ payload: session }) => {
			socket.emit('conference:new-token', { session });
		})
		yield takeLatest('FETCH_CONFERENCE', ({ payload: session }) => {
			socket.emit('conference:fetch', { session });
		})
		yield takeEvery('SEND_CHUNK', (event) => {
			socket.emit('file:chunk', event.params)
		})
		yield takeEvery('COUP_CHAT', (event) => {
			socket.emit('chat:connect', event.params)
		})
		yield takeEvery('SEND_MESSAGE', (event) => {
			socket.emit('chat:send_message', event.params)
		})
		yield takeEvery('DELETE_MESSAGE', (action) => {
			socket.emit('chat:delete_message', action.params);
		})
		yield takeLatest('CONFERENCE_STARTED', function* (action) {
			const conference = yield select(getConference);
			const { user, appointment_id } = conference;
			if (user && user.role === 'doctor') {
				// проверяем, если appointment_id больше 1 млрд, то это фейковый id
				if (appointment_id >= 1e9) {
					yield put({ type: 'DELETE_CURRENT_DOCTOR' });
				} else {
					yield put({ type: 'SET_CURRENT_DOCTOR', payload: { ...user, appointment_id } });
				}
			}
		})
		yield takeLatest('ATTACHMENTS_GET', _getAttachments)
		yield takeLatest('ATTACHMENT_ADD', _addAttachment)
		yield takeEvery('ATTACHMENT_DEL', _delAttachment)
	} catch (error) {
		console.error('WatchAction Error: ', error)
	}
}

function getConference(state) {
	return (config.isMobile ? state.telemedicine : state).Conference;
}

//eslint-disable-next-line
function* connectSocket(key) {
	const id = uuid.v4()
	forceConnect(id)
	if (socket && (socket.connected || !socket.disconnected)) {
		socket.disconnect();
	}
	const url = `${config.socketurl}?session_key=${key}&app=web_telemedicine&uuid=${id}`
	socket = socketIO(url, { secure: true, transports: ['websocket'] });
	return eventChannel((emitter) => {
		events.on('trusted-sound', () => {
			emitter({ type: 'TRUSTED_SOUND' })
		})
		socket.on('connect', () => {
			console.log(time(), 'socket.connected')
			emitter({ type: 'SOCKET:CONNECTED' })
			emitter({ type: 'CHECK_TM_AUTH' })
		})
		socket.on('disconnect', () => {
			console.log(time(), 'socket.disconnected')
			emitter({ type: 'SOCKET:DISCONNECT' })
			emitter({ type: 'CHECK_TM_AUTH' })
		})
		socket.on('conference:token', (params) => {
			emitter({ type: 'SET_ROOM_TOKEN', params })
		})
		socket.on('conference:new-token', ({ session, token }) => {
			emitter({ type: 'SET_NEW_TOKEN', payload: { session, token } });
		})
		socket.on('conference:call_end', ({ token, conference, session }) => {
			events.emit('stop-sound', { token, conference, session });
		})
		socket.on('conference:call_stop', ({ session, conference }) => {
			emitter({ type: 'REJECT_CALL', payload: session })
		})
		socket.on('conference:call_timeout', ({ conference, session }) => {
			events.emit('stop-sound', { conference, session });
			emitter({ type: 'HOLDON_CONFERENCE', payload: { reason: 'timeout' } })
		})
		socket.on('conference:call_rejected', ({ conference, session }) => {
			emitter({ type: 'REJECT_CALL', payload: session })
		})
		socket.on('conference:declined', (session) => {
			events.emit('stop-sound', { session });
			emitter({ type: 'HOLDON_CONFERENCE', payload: { reason: 'declined' } })
		})
		socket.on('conference:closed', ({ session }) => {
			emitter({ type: 'CLEAN_CONFERENCE', payload: { reason: 'closed' } })
			events.emit('stop-sound', { session });
		})
		socket.on('conference:active', ({ session }) => {
			emitter({ type: 'CLEAN_TOKEN', payload: session })
		})
		socket.on('conference:medic_user', ({ user }) => {
			emitter({ type: 'SET_MEDIC_USER', params: user });
		})
		socket.on('conference:fail', (reason) => {
			emitter({ type: 'CLEAN_CONFERENCE', payload: { reason: reason || 'failed' } })
			events.emit('stop-sound');
		})
		socket.on('appointments:update', () => {
			emitter({ type: 'PATIENTS_FETCH' });
		})
		// recall errors
		socket.on('conference:notfound', (session) => {
			console.log('conference with sessionId = ', session, 'not found')
			emitter({ type: 'HOLDON_CONFERENCE', payload: { reason: 'notfound' } })
		})
		socket.on('conference:accessdenied', (session) => {
			console.log('you do not have access to conference with sessionId = ', session)
			emitter({ type: 'HOLDON_CONFERENCE', payload: { reason: 'accessdenied' } })
		})
		socket.on('conference:useraccessdenied', (session) => {
			console.log('user you called does not have access to join this conference (sessionId = ', session, ')');
			emitter({ type: 'HOLDON_CONFERENCE', payload: { reason: 'useraccessdenied' } })
		})
		socket.on('conference:usernotfound', (session) => {
			console.log('user you called is not found (sessionId = ', session, ')');
			emitter({ type: 'HOLDON_CONFERENCE', payload: { reason: 'usernotfound' } })
		})
		socket.on('conference:failedtoken', (session) => {
			console.log('failed to generate token for user you called (sessionId = ', session, ')')
			emitter({ type: 'HOLDON_CONFERENCE', payload: { reason: 'failedtoken' } })
		})
		//
		socket.on('conference:call', (params) => {
			emitter({ type: 'INCOMING_CALL', params })
		})
		socket.on('conference:update_attachments', ({ appointment_id }) => {
			emitter({ type: 'ATTACHMENTS_GET', params: { appointment_id } });
		})
		socket.on('file:chunk_upload', (params) => {
			emitter({ type: 'SUCCESS_CHUNK', params })
		})
		socket.on('chat:message', (params) => {
			emitter({ type: 'CHAT_MESSAGE', params })
			const { room, user_id } = params;
			const user = { user_id };
			events.emit('CHAT_MESSAGE', params);
			emitter({ type: 'DELETE_CHAT_ACTIVITY', params: { room, user } });
		})
		socket.on('chat:message_deleted', (message) => {
			console.log(time(), 'chat:message_deleted', message)
			emitter({ type: 'CHAT_MESSAGE_DELETED', params: message })
			events.emit('CHAT_MESSAGE_DELETED', message);
		})
		socket.on('chat:history', (params) => {
			emitter({ type: 'CHAT_HISTORY', params })
		})
		socket.on('chat:unread', ({ appointment_id, unread }) => {
			emitter({ type: 'CHAT_ROOM_UNREAD_SUCCESS', params: { appointment_id, unread }})
		})
		socket.on('chat:is-active-room', ({ room, appointment_id }, callback) => {
			try {
				const rooms = _store.store.getState().ChatRooms
				const isActive = Boolean(rooms[appointment_id] && rooms[appointment_id].active)
				const appState = getAppState()
				callback({ isActive, appState })
			} catch (e) {
				const appState = getAppState()
				callback({ isActive: false, appState })
			}
		})
		socket.on('user:is_online', (user) => {
			if (user.status === 'offline') {
				emitter({ type: 'SET_USER_OFFLINE', payload: user })
			}
			if (user.status === 'online') {
				emitter({ type: 'SET_USER_ONLINE', payload: user });
			}
		})
		socket.on('chat:activity', ({ room, user }) => {
			chatActivities[room] = chatActivities[room] || {}
			clearTimeout(chatActivities[room][user.user_id]);
			emitter({ type: 'SET_CHAT_ACTIVITY', params: { room, user } })
			chatActivities[room][user.user_id] = setTimeout(() => {
				emitter({ type: 'DELETE_CHAT_ACTIVITY', params: { room, user } });
			}, 1000);
		});
		return () => { }
	})
}

export function* connect({ params }) {
	yield spawn(_socketSaga, params)
}

export function* _socketSaga(key) {
	const channel = yield call(connectSocket, key)
	while (true) {
		const channelAction = yield take(channel)
		yield put(channelAction)
	}
}

export function* _waitPersist() {
	try {
		yield takeLatest('persist/REHYDRATE', _InitApp)
	} catch (error) {
		console.log('persist/REHYDRATE error', error)
	}
}

export function* _InitApp({ payload }) {
	yield put({ type: 'TM_DIFTIME_FETCH' })
	yield put({ type: 'APP_INIT' })
	const session = ((payload || {}).User || {}).session
	console.log(time(), session)
	if (session) {
		yield put({ type: 'SOCKET:CONNECT', params: session })
	}
}
