import { get } from './networking'
import { call, put, delay } from 'redux-saga/effects'
import config from '../config'

const initialState = {
	data: [],
	appointment: undefined,
	fetch: false,
}

export const AppointmentsReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'PATIENT_APPOINTMENT_GET':
			return { ...state, data: [], fetch: true }
		case 'PATIENT_APPOINTMENT_SET':
			return { ...state, fetch: false, appointment: action.params }
		case 'PATIENT_APPOINTMENTS_GET':
			return { ...state, fetch: true }
		case 'PATIENT_APPOINTMENTS_SET':
			return { ...state, fetch: false, data: action.params }
		default:
			return { ...state }
	}
}

const _1day = 24 * 3600 * 1000;
const fakeAppointments = [{
  direction_service_id: 1,
  started_at: new Date(Date.now() + 10 * 60000).toISOString(),
	attachments: [],
}, {
  direction_service_id: 4,
  started_at: new Date(Date.now() + _1day - 3600 * 1000).toISOString(),
	attachments: [],
}, {
  direction_service_id: 2,
  started_at: new Date(Date.now() + _1day + 6 * 60000).toISOString(),
	attachments: [],
}, {
  direction_service_id: 3,
	started_at: new Date(Date.now() + 39 * _1day).toISOString(),
	attachments: [],
}];

export function* _getAppointments() {
	// const result = yield delay(250, { success: true, data: fakeAppointments })
	const result = yield call(get, {
		url: `${config.url}/api/appointments`,
	})
	if (result.success) {
		yield put({ type: 'PATIENT_APPOINTMENTS_SET', params: result.data })
	}
}

export function* _getAppointment({ params }) {
	const result = yield call(get, {
		url: `${config.url}/api/appointment`,
		params,
	})
	if (result.success) {
		yield put({ type: 'PATIENT_APPOINTMENT_SET', params: result.data })
	}
}
