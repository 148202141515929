import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ScreenWrapper from '../../components/ScreenWrapper'
import CheckBox from '../../components/CheckBox'
import TextArea from '../../components/TextArea'
import Button from '../../components/BButton'
import Rating from '../../components/Rating'
import Modal from '../../components/MModal';
import config from '../../config';

import './style.scss'
import DoctorImg from './doctor-img.png'

const getName = (name) => {
  if (name) {
    return name[0].toUpperCase() + name.slice(1).toLowerCase();
  }
  return '';
}

const formatName = (user) => {
  if (!user) {
    return '';
  }
  return `${getName(user.last_name)} ${getName(user.first_name)} ${getName(user.middle_name)}`.trim();
}

class DoctorRating extends Component {
  constructor(props) {
    super(props)
    this.state = {
      publish: true,
      comment: '',
      rating: 0,
    }
  }

  componentDidMount() {
    this.checkDoctor();
  }

  componentDidUpdate() {
    this.checkDoctor();
  }

  checkDoctor = () => {
    const { doctor } = this.props;
    if (!doctor) {
      this.props.history.replace('/');
    }
  }

  submit = () => {
    const { rating, comment, publish } = this.state;
    const { doctor, user, medic_user } = this.props;
    if (!rating) {
      this.setState({ error: 'Выберите оценку' })
      return;
    }
    if (!doctor || !user) {
      const code = parseInt(!!doctor, 10) + parseInt(!!user, 10) * 2;
      this.setState({ error: `Произошла непредвиденная ошибка, код ошибки = ${code}` });
      return;
    }
    this.props.sendDoctorRating({
      token: user.medic_token,
      stars: rating,
      comment,
      public_value: publish,
      doctor_id: doctor.id,
      appointment_id: doctor.appointment_id,
    });
    setTimeout(() => {
      window.location.href = `${config.lkurl}/menu/main/medcard/visits${medic_user ? `?medic_user_id=${medic_user.medic_user_id}` : ''}`;
    }, 200);
  }

  closeModal = () => {
    this.setState({ error: null });
  }

  render() {
    const { publish, comment, rating } = this.state
    const { doctor } = this.props;
    const doctorName = formatName(doctor);
    return (
      <ScreenWrapper style={{ display: 'flex', padding: 0 }}>
        <div className="doctor-rating">
          <h2>Оцените врача и прием</h2>
          <div className="doctor-rating__doctor">
            <div className="doctor-rating__doctor-img">
              <img src={doctor && doctor.photo || DoctorImg} alt={doctorName} />
            </div>
            <div className="doctor-rating__doctor-info">
              <div className="doctor-rating__doctor-info-name">{formatName(doctor)}</div>
              <div className="doctor-rating__doctor-service">{doctor && doctor.speciality}</div>
            </div>
          </div>
          <div className="doctor-rating__rating">Выберите оценку</div>
          <Rating
            value={rating}
            onChange={(value) => {
              this.setState({ rating: value })
            }}
            style={{ marginBottom: 30 }}
          />
          <TextArea
            value={comment}
            placeholder="Напишите отзыв о враче и приеме"
            onChange={(value) => {
              this.setState({ comment: value })
            }}
          />
          <Button style={{ maxWidth: 300, marginBottom: 20 }} onClick={this.submit}>
            Оценить
          </Button>
          <CheckBox
            checked={publish}
            label="Разрешаю разместить анонимно мой отзыв в отрытых источниках"
            onClick={() => {
              this.setState({ publish: !publish })
            }}
          />
          <Modal
            visible={!!this.state.error}
            onShutter={this.closeModal}
            onClose={this.closeModal}
            title="Ошибка"
          >
            <div className="ErrorRating">{this.state.error}</div>
          </Modal>
        </div>
      </ScreenWrapper>
    )
  }
}

DoctorRating.defaultProps = {
  doctor: {
    user_id: 1, // telemedicine user id
    id: 123, // medic doctor id
    first_name: 'Наталия',
    last_name: 'Андреева',
    middle_name: 'Геннадьевна',
    spciality: 'УЗИ',
    appointment_id: -1,
  }
}

export default withRouter(connect((state) => ({
  doctor: state.Doctors.currentDoctor,
  user: state.User.user,
  medic_user: state.User.medic_user,
}), {
  cleanDoctor: () => ({ type: 'DELETE_CURRENT_DOCTOR' }),
  sendDoctorRating: (params) => ({ type: 'SEND_DOCTOR_RATING', params }),
})(DoctorRating))
