import React from 'react';
import PropTypes from 'prop-types';
import './style.scss'

const getAnimation = (props, index) => {
  const { keyframes, duration, count } = props;
  const delay = index ? (duration * index / count * 0.8).toFixed(2) : 0;
  return `${keyframes} ${duration}s ease-in-out ${delay}s infinite`;
}

const getAnimations = (props) => {
  const { count } = props;
  const animations = [];
  for (let index = 0; index < count; ++index) {
    animations.push(getAnimation(props, index));
  }
  return animations;
}

const ActivityBubbles = (props) => {
  const animations = getAnimations(props);
  const { className } = props;
  return <div className={`ActivityContainer ${className || ''}`}>
    {animations.map((animation, index) => (
    <div className="Activity" key={index}>
      <div style={{ animation, backgroundColor: props.color }} />
    </div>))}
  </div>
}

ActivityBubbles.propTypes = {
  duration: PropTypes.number,
  count: PropTypes.number,
  color: PropTypes.string,
  keyframes: PropTypes.string,
  className: PropTypes.string,
}

ActivityBubbles.defaultProps = {
  duration: 0.75,
  count: 3,
  color: 'var(--secondaryDim)',
  keyframes: 'growUp',
}

export default ActivityBubbles;