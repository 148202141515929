import { combineReducers } from 'redux'
import { call, put, select, takeLatest } from "redux-saga/effects";
import config from '../config';
import { create } from 'apisauce'

const api = create({
  baseURL: `${config.lkurl}:10011`,
})

/**
 * @typedef {{
 *  isFetching: boolean;
 *  error: boolean;
 *  data: IBonusSettingsResponse;
 * }} IBonusSettingsState
 * 
 * @typedef {{
 *  max_percent: number;
 *  cashback: number;
 * }} IBonusSettings
 * 
 * @typedef {{
 *  success: 0 | 1;
 *  data: {
 *    settings: IBonusSettings;
 *  };
 *  error?: {
 *    code?: string | number;
 *    message: string;
 *  };
 * }} IBonusSettingsResponse
 */


/** @type {IBonusSettingsResponse} */
export const initialBonusSettingsResponse = {
  success: 1,
  data: {
    settings: {
      max_percent: 30,
      cashback: 5,
    }
  }
}

/** @type {IBonusSettingsState} */
export const initialBonusSettingsState = {
  isFetching: false,
  error: false,
  data: initialBonusSettingsResponse,
}

export const BONUS_SETTINGS_REQUEST = 'BONUS_SETTINGS_REQUEST'
export const BONUS_SETTINGS_SUCCESS = 'BONUS_SETTINGS_SUCCESS'
export const BONUS_SETTINGS_FAILURE = 'BONUS_SETTINGS_FAILURE'

/**
 * 
 * @param {IBonusSettingsState} state 
 * @param {{
 *  type: string;
 *  params: any;
 * }} action 
 * @return {IBonusSettingsState}
 */
export const BonusSettingsReducer = (state = initialBonusSettingsState, action) => {
  switch (action.type) {
    case BONUS_SETTINGS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      }
    case BONUS_SETTINGS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        data: action.params,
      }
    case BONUS_SETTINGS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
        data: action.params,
      }
    default:
      return state;
  }
}

export const bonusSettingsRequest = () => ({ type: BONUS_SETTINGS_REQUEST })

/** @param {IBonusSettingsResponse} data */
export const bonusSettingsSuccess = (data) => ({ type: BONUS_SETTINGS_SUCCESS, params: data })

export const bonusSettingsFailure = (error) => ({ type: BONUS_SETTINGS_FAILURE, params: { error } });


/**
 * @typedef {{
 *  isFetching: boolean;
 *  error: boolean;
 *  data: IBonusPointsResponse;
 * }} IBonusPointsState
 * 
 * @typedef {{
 *  amount: number;
 *  full_amount: number;
 *  burning_at: string;
 * }} IBonusPoints
 * 
 * @typedef {{
 *  success: 0 | 1;
 *  data?: {
 *    points: IBonusPoints;
 *  };
 *  error?: {
 *    code?: string | number;
 *    message: string;
 *  };
 * }} IBonusPointsResponse
 */


/** @type {IBonusPointsResponse} */
export const initialBonusPointsResponse = {
  success: 1,
  data: {
    points: {
      amount: 0,
      full_amount: 0,
      burning_at: new Date().toISOString(),
    },
  },
}

/** @type {IBonusPointsState} */
export const initialBonusPointsState = {
  isFetching: false,
  error: false,
  data: initialBonusPointsResponse,
}

export const BONUS_POINTS_REQUEST = 'BONUS_POINTS_REQUEST'
export const BONUS_POINTS_SUCCESS = 'BONUS_POINTS_SUCCESS'
export const BONUS_POINTS_FAILURE = 'BONUS_POINTS_FAILURE'

/**
 * @param {IBonusPointsState} state 
 * @param {{ type: string; params: any; }} action 
 * @return {IBonusPointsState}
 */
export const BonusPointsReducer = (state = initialBonusPointsState, action) => {
  switch (action.type) {
    case BONUS_POINTS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      }
    case BONUS_POINTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false,
        data: action.params,
      }
    case BONUS_POINTS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
        data: action.params,
      }
    default:
      return state;
  }
}

/** @param {string} bonus_code */
export const bonusPointsRequest = (bonus_code) => ({ type: BONUS_POINTS_REQUEST, params: bonus_code })

/** @param {IBonusPointsResponse} data */
export const bonusPointsSuccess = (data) => ({ type: BONUS_POINTS_SUCCESS, params: data })

export const bonusPointsFailure = (error) => ({ type: BONUS_POINTS_FAILURE, params: { error } });

/**
 * @typedef {{
 *  points: IBonusPointsState;
 *  settings: IBonusSettingsState;
 * }} IBonusState
 */

/** @type {IBonusState} */
export const initialBonusState = {
  points: initialBonusPointsState,
  settings: initialBonusSettingsState,
}

export const BonusReducer = combineReducers({
  points: BonusPointsReducer,
  settings: BonusSettingsReducer,
})


export const selectUser = (state) => state.User.user

/**
 * 
 * @typedef {{
 *  success: 0;
 *  error: {
 *    code?: string | number;
 *    message: string;
 *  };
 * }} BonusError
 */

/**
 * @param {any} response 
 * @return {BonusError}
 */
export const extractError = (response) => {
  if (response.data && response.data.success) {
    return null
  }
  if ((
    response.data
    && !response.data.success
  ) || (
    !response.ok
    && response.data
  )) {
    return response.data
  }
  if (!response.ok && !response.data && response.problem) {
    return { success: 0, error: { message: response.problem } }
  }
  return { success: 0, error: { message: 'unknown error' } };
}


/** @param {string} message */
const createError = (message) => ({ success: 0, error: { message } })

export function* bonusPointsRequestSaga(action) {
  try {
    const user = yield select(selectUser)
    const response = yield call(api.get, '/get_bonus_points', {
      token: user.medic_token,
      bonus_code: action.params
    })
    const error = extractError(response)
    if (error) {
      yield put(bonusPointsFailure(error))
      yield put({ type: 'ERROR_PUSH', params: error.error.message });
    } else {
      yield put(bonusPointsSuccess(response.data))
    }
  } catch (e) {
    console.log('bonusPointsRequest error', e)
    const error = createError(e.message)
    yield put(bonusPointsFailure(error))
    yield put({ type: 'ERROR_PUSH', params: error.error.message });
  }
}

export function* bonusSettingsRequestSaga() {
  try {
    const user = yield select(selectUser)
    const response = yield call(api.get, '/get_bonus_settings', { token: user.medic_token })
    const error = extractError(response)
    if (error) {
      yield put(bonusSettingsFailure(error))
      yield put({ type: 'ERROR_PUSH', params: error.error.message });
    } else {
      yield put(bonusSettingsSuccess(response.data))
    }
  } catch (e) {
    console.log('bonusSettingsRequest error', e)
    const error = createError(e.message)
    yield put(bonusSettingsFailure(error))
    yield put({ type: 'ERROR_PUSH', params: error.error.message });
  }
}


export function* watchBonus() {
  yield takeLatest(BONUS_POINTS_REQUEST, bonusPointsRequestSaga)
  yield takeLatest(BONUS_SETTINGS_REQUEST, bonusSettingsRequestSaga)
}