import React from 'react'
import ScreenWrapper from '../../components/ScreenWrapper'
// import BButton from '../../components/BButton'
import RCTOpenVidu from '../../openvidu-react';
import Video from '../../components/Archive/Video'
import { Circle as Preloader } from 'react-preloaders'
import config from '../../config';
import './style.scss'
import throttle from 'lodash/throttle'
import OvTestCenterButtons from '../../components/OvTestCenterButtons';
import { OpenViduError } from './OpenViduError'
import uaParser from '../../utils/uaParser'

const time = () => `[${new Date().toISOString()}]`;
const TAG = 'DEBUG'

const ScreenHolder = (props) => {
  return (<div className="ScreenHolder">
    <div className={`ScreenHolderGradient ${props.className || ''}`}>
      {props.children}
    </div>
  </div>)
}

const initialState = {
  viewRecord: false,
  videoEnded: false,
  error: null,
}

export default class ConnectionTest extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
    this.downloadVideo = throttle(this.downloadVideo, 1000);
  }

  componentDidMount() {
    this.props.startConnectionTest();
  }

  componentDidUpdate(prevProps) {
    const { test } = this.props;
    if (test.starting && !prevProps.test.starting) {
      this.setState({ ...initialState });
    }
  }

  componentWillUnmount() {
    this.props.endConnectionTest();
  }

  joinSession = (error) => {
    console.log(time(), TAG, 'joinSession', error);
  }

  leaveSession = () => {
    console.log(time(), TAG, 'leaveSession')
    this.props.showNavigation();
  }

  onLeaveSession = () => {
    console.log(time(), TAG, 'onLeaveSession')
    this.props.stopConnectionTest();
  }

  onInitPublisher = (error) => {
    if (!error) {
      return;
    }
    console.log('ERROR', error)
    const e = OpenViduError[error.name];
    this.setState({ error: e ? e.description : error.name, search: e ? e.searchText : '' })
  }

  onConnectionCreated = (data) => {
    console.log(time(), TAG, 'onConnectionCreated ', JSON.stringify(data, null, 2));
    this.setState({ connectionCreated: true })
  }

  onConnectionDestroyed = (data) => {
    console.log(time(), TAG, 'onConnectionDestroyed', JSON.stringify(data, null, 2));
    this.setState({ connectionDestroyed: true })
  }

  onError = (e) => {
    console.log(time(), TAG, 'onError', e);
    this.setState({ error: e });
  }

  onChangeSubscribers = (...args) => {
    console.log(time(), TAG, 'onChangeSubscribers', args);
  }

  onSessionDisconnected = (event) => {
    console.log(time(), TAG, 'onSessionDisconnected', event);
    const { record, stopped, maxDuration, insertedAt } = this.props.test;
    const endTime = insertedAt + maxDuration;
    if ((!stopped || !record.recordingUrl) && endTime < Date.now()) {
      this.setState({ error: 'Время тестирования завершилось', search: '' })
    }
  }

  getRef = (ref) => {
    this.opv = ref;
  }

  downloadVideo = (source, filename) => {
    const a = document.createElement('a');
    a.href = source;
    a.pathname = `${a.pathname}/${filename}`
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      document.body.removeChild(a);
    }, 1000)
  }

  renderOpenVidu = () => {
    const { sessionId, token, record, stopped } = this.props.test;
    if (!sessionId || !token) {
      return null;
    }
    const { viewRecord, videoEnded, error, search } = this.state;
    if (error) {
      return <ScreenHolder className="Centered Column Error">
        <OvTestCenterButtons
          forceShow
          title={error}
          rightText="Повторить подключение"
          onRight={() => this.props.startConnectionTest()}
        />
        {search && <p onClick={() => window.open('https://google.com/search?q=' + search + ' ' + uaParser.getBrowser().name)}>Подробнее об устранении этой проблемы</p>}
      </ScreenHolder>
    }
    if (viewRecord && stopped && !videoEnded) {
      const source = `${config.url}${record.recordingUrl}`;
      if (uaParser.getBrowser().name.toLowerCase().indexOf('safari') !== -1) {
        return <ScreenHolder className="Centered">
          <OvTestCenterButtons
            forceShow
            title="Тест завершен!"
            leftText="Повторить тест"
            rightText="Скачать запись"
            onLeft={() => this.props.startConnectionTest()}
            onRight={() => this.downloadVideo(source, `video_${new Date().toISOString().slice(0, -5).replace(/\-/g, '_').replace('T', '_').replace(/\:/g, '_')}.webm`)}
          >
            <small style={{ marginTop: '10px' }}>Ваш браузер не поддерживает данный формат видео</small>
          </OvTestCenterButtons>
        </ScreenHolder>
      }
      // const sources = [{ source, type: 'video/webm' }, { source: `${source}&ext=mp4`, type: 'video/mp4' }];
      return <Video
        // sources={sources}
        source={source}
        type="video/webm"
        onEnded={() => this.setState({ videoEnded: true })}
      />;
    }
    if (viewRecord && videoEnded && stopped) {
      return <ScreenHolder className="Centered">
        <OvTestCenterButtons
          forceShow
          title="Просмотр завершен"
          leftText="Повторить тест"
          rightText="Записаться на прием"
          onLeft={() => this.props.startConnectionTest()}
          onRight={() => {
            window.location.href = `${config.lkurl}/menu/main/makeappointment`;
          }}
        />
      </ScreenHolder>
    }
    return (
      <RCTOpenVidu
        id="opv-session-debug"
        test
        onViewTestRecord={() => this.setState({ viewRecord: true, videoEnded: false })}
        onRepeatTest={() => this.props.startConnectionTest()}
        sessionName={sessionId}
        user=""
        token={token}
        format={'video'}
        resolution={'1280x720'}
        joinSession={this.joinSession}
        leaveSession={this.leaveSession}
        onLeaveSession={this.onLeaveSession}
        onInitPublisher={this.onInitPublisher}
        onConnectionCreated={this.onConnectionCreated}
        onConnectionDestroyed={this.onConnectionDestroyed}
        onSessionDisconnected={this.onSessionDisconnected}
        error={this.onError}
        title={''}
        onChangeSubscribers={this.onChangeSubscribers}
        standByText={''}
        centerChat={null}
        centerAudio={null}
        ref={this.getRef}
      />
    )
  }

  renderStart = () => {
    return <Preloader color={'var(--primaryLight)'} />
  }

  render() {
    const { sessionId, token } = this.props.test;
    return <ScreenWrapper onBack={() => this.props.history.goBack()}>
      {sessionId && token && (
        <div className="ConferencePlane">
          <div className="conferenceGUI">
            {this.renderOpenVidu()}
          </div>
        </div>)}
      {(!sessionId || !token) && this.renderStart()}
    </ScreenWrapper>
  }
}