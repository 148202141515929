/**
 * @typedef {{ visible?: boolean; title?: string; message?: string; onClose?: () => void; }} INetworkModalState
 * @typedef {{
 *  modal: INetworkModalState;
 *  status: 'offline' | 'online';
 * }} INetworkState
 */

/** @type {INetworkState} */
export const initialNetworkState = {
  modal: {
    visible: false,
    onClose: () => {},
  },
  title: '',
  message: '',
  status: 'online',
}

export const SET_NETWORK_MODAL = 'SET_NETWORK_MODAL'
export const SET_NETWORK_STATUS = 'SET_NETWORK_STATUS'

/** @param {INetworkModalState} modal */
export const setNetworkModal = (modal) => ({ type: SET_NETWORK_MODAL, params: modal })

/** @param {'offline' | 'online'} status */
export const setNetworkStatus = (status) => ({ type: SET_NETWORK_STATUS, params: status })

const networkReducer = (state = initialNetworkState, action) => {
  /** @type {INetworkModalState} */
  let modal;
  /** @type {'offline' | 'online'} */
  let status;
  switch (action.type) {
    case 'SET_NETWORK_MODAL':
      modal = action.params
      return {
        ...state,
        modal,
      }
    case 'SET_NETWORK_STATUS':
      status = action.params
      return {
        ...state,
        status,
      }
    default:
      return state
  }
}

export default networkReducer