import events from '../../reducers/events'

function SoundController(source) {
  this.source = source;
  this.inited = false;
  this.audio = null;
  this.isTrusted = false;
}

/**
 * @param {function} callback
 */
SoundController.prototype.init = function init(callback) {
  if (this.inited) {
    return;
  }
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', () => this.init(callback));
    return;
  }
  const handle = (e) => {
    if (this.isTrusted && this.audio) {
      return;
    }
    this.audio = new Audio(this.source);
    this.audio.load();
    this.isTrusted = e.isTrusted;
    if (e.isTrusted) {
      events.emit('trusted-sound');
    }
    if (typeof callback == 'function') {
      callback();
    }
  };
  document.body.addEventListener('click', handle);
  this.inited = true;
};

SoundController.prototype.play = function play() {
  if (!this.audio) {
    this.audio = new Audio(this.source);
    this.audio.load();
    this.isTrusted = false;
  }
  if (this.isTrusted) {
    console.log('[SoundController] TRUSTED play')
  } else {
    console.log('[SoundController] NOT TRUSTED play')
  }
  const res = this.audio.play();
  if (res && res.then) {
    res.catch(() => { });
  }
  return res;
};

SoundController.prototype.stop = function stop() {
  if (!this.audio) {
    return;
  }
  const res = this.audio.pause();
  if (res && res.then) {
    res.catch(() => {})
  }
  return res;
}

export default SoundController;