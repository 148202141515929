import { all, call, put, takeLatest } from 'redux-saga/effects';
import { historyTypes } from './history';
import { api } from './networking';
import config from '../config';

export default function* historySaga() {
  try {
    yield all([
      watchHistoryActions(),
    ]);
  } catch (e) {
    console.log('[error] history saga: ', e);
  }
}

function* watchHistoryActions() {
  try {
    yield takeLatest(historyTypes.HISTORY_FETCH, historyFetch);
  } catch (e) {
    console.log('[error] watch history actions: ', e);
  }
}

/*const historyFetchMock = async () => {
  const data = [{
    started_at: '2020-04-01 00:00:00',
    ended_at: '2020-04-01 01:03:13',
    duration: (3600 + 180 + 13) * 1000,
    video: {
      size: 16296965,
      duration: 256.088,
      id: 'NewSession',
      sessionId: 'NewSession',
      createdAt: 1585050708402,
      url: 'https://lk.mcmedic.ru:4443/recordings/NewSession/NewSession.mp4',
      status: 'ready',
      properties:
      {
        name: 'NewSession',
        outputMode: 'COMPOSED',
        hasAudio: true,
        hasVideo: true,
        resolution: '1920x1080',
        recordingLayout: 'BEST_FIT'
      }
    },
    users: [{
      first_name: 'Врач',
      last_name: 'Доктор',
      role_id: 1,
      role: 'doctor',
    }, {
      first_name: 'Вася',
      last_name: 'Пупкин',
      role_id: 2,
      role: 'patient',
    }],
  }, {
    started_at: '2020-03-31 14:00:00',
    ended_at: '2020-03-31 14:23:33',
    duration: (23 * 60 + 33) * 1000,
    users: [{
      first_name: 'Врач2',
      last_name: 'Доктор2',
      role_id: 1,
      role: 'doctor',
    }, {
      first_name: 'Вася2',
      last_name: 'Пупкин2',
      role_id: 2,
      role: 'patient',
    }],
  }];
  return { success: 1, data };
};*/

function* historyFetch() {
  try {
    const { success, error, data: history } = yield call(api.get, { url: `${config.url}/api/conferences` });
    // const { success, error, data: history } = yield call(historyFetchMock);
    if (success) {
      yield put({ type: historyTypes.HISTORY_FETCH_SAVE, payload: { history } });
    } else {
      yield put({ type: historyTypes.HISTORY_FETCH_ERROR, payload: { error } });
    }
  } catch (e) {
    console.log('[error] history fetch: ', e);
    yield put({ type: historyTypes.HISTORY_FETCH_ERROR, payload: { error: e.message } });
  }
}