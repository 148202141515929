import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Modal from '../MModal'
import sound from '../CallManager/sound'
import 'moment/locale/ru';
import './style.scss'

class ModalAppointmentConfirmation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {
    this.check();
    this.interval = setInterval(() => this.check(), 30 * 1000)
    const { fetchAppointments } = this.props;
    if (this.isAuthorizedPatient()) {
      fetchAppointments();
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  componentDidUpdate(prevProps) {
    const { fetchAppointments, session, appointments, conference } = this.props;
    if (this.isAuthorizedPatient() && prevProps.session !== session) {
      fetchAppointments();
      this.check();
    }
    if (appointments.length !== prevProps.appointments.length) {
      this.check();
    }
    if (prevProps.conference.appointment_id !== conference.appointment_id && conference.appointment_id) {
      this.setState({ visible: false })
    }
  }

  check = () => {
    const { conference } = this.props;
    if (!this.isAuthorizedPatient() || conference.appointment_id) {
      return;
    }
    const appointments = this.findTodaysNotHandled();
    if (appointments.length) {
      const { handled = [] } = this.state;
      this.setState({
        handled: [...handled, ...appointments.map(a => a.appointment_id)],
        visible: !sound.isTrusted,
      })
    }
  }

  isAuthorizedPatient = () => {
    const { session, user } = this.props;
    return session && user && user.role === 'patient';
  }

  findTodaysNotHandled = () => {
    const { appointments } = this.props;
    const { handled = [] } = this.state;
    const now = moment();
    return appointments
      .filter((a) => {
        return handled.indexOf(a.appointment_id) == -1 && moment(moment(a.started_at).toDate()).startOf('day').diff(now.startOf('day'), 'days') === 0;
      });
  }

  findFirst = () => {
    const { appointments } = this.props;
    const { handled = [] } = this.state;
    const sorted = appointments.slice().sort((a, b) => {
      return +new Date(a.started_at) - +new Date(b.started_at);
    });
    return sorted.find(a => handled.indexOf(a.direction_service_id) == -1)
  }

  close = () => this.setState({ visible: false })

  render() {
    const { visible } = this.state;
    return <Modal
      className={'AppointmentConfirmation'}
      closeIconColor={'#fff'}
      visible={visible}
      title="Онлайн-консультация"
      onShutter={this.close}
      onClose={this.close}
    >
      <div className="AppointmentWarning">У Вас на сегодня назначен прием</div>
    </Modal>;
  }
}

ModalAppointmentConfirmation.propTypes = {
  appointments: PropTypes.arrayOf(PropTypes.shape()),
  session: PropTypes.string,
  user: PropTypes.shape(),
  fetchAppointments: PropTypes.func,
}

export default ModalAppointmentConfirmation;
