import React from 'react';

import ScreenWrapper from '../../components/ScreenWrapper';
import './style.scss';

import ReadonlyLabel from '../../components/ReadonlyLabel';

export default class Contacts extends React.Component {
	render() {
		return <ScreenWrapper>
			<div className='infoBlock'>
				<h2>Контакты</h2>
				<ReadonlyLabel title={'Клиника ЮЗР'}>г. Чебоксары, ул. Тополиная, 11а</ReadonlyLabel>
				<ReadonlyLabel basis={'calc(100% - 300px)'} title={'Клиника СЗР'}>г. Чебоксары, ул. Сверчкова, 8</ReadonlyLabel>
				<div className='line'/>
				<ReadonlyLabel title={'Телефон'}>8 (8352) 23-77-23</ReadonlyLabel>
				<ReadonlyLabel basis={'calc(100% - 300px)'} title={'E-mail'}>info@mcmedic.ru</ReadonlyLabel>
			</div>
			<div className='delimeter'/>
			<div className='infoBlock'>
				<h3>Реквизиты</h3>
				<ReadonlyLabel basis={'calc(100% - 300px)'} title={'Наименование предприятия'}>ООО «МЕДИК»</ReadonlyLabel>
				<div className='line'/>
				<ReadonlyLabel title={'ИНН'}>2130138476</ReadonlyLabel>
				<ReadonlyLabel basis={'calc(100% - 300px)'} title={'КПП'}>213001001</ReadonlyLabel>
				<div className='line'/>
				<ReadonlyLabel title={'Расчетный счет'}>40702810010530001824</ReadonlyLabel>
				<ReadonlyLabel basis={'calc(100% - 300px)'} title={'Наименование банка'}>ФИЛИАЛ "ЦЕНТРАЛЬНЫЙ" БАНКА ВТБ (ПАО)</ReadonlyLabel>
				<div className='line'/>
				<ReadonlyLabel title={'БИК'}>044525411</ReadonlyLabel>
				<ReadonlyLabel basis={'calc(100% - 300px)'} title={'Кор/Счет'}>30101810145250000411</ReadonlyLabel>
			</div>
		</ScreenWrapper>
	}
}