import React from 'react';
import './style.scss';

export default class ReadonlyLabel extends React.Component {
	render() {
		return <div className='dataPanel' style={this.props.basis?{flexBasis:this.props.basis}:{}}>
			<label>{this.props.title}</label>
			<div className='value'>
				{this.props.children}
			</div>
		</div>
	}
}