import React from 'react';
import { Player, ControlBar } from 'video-react';

class Video extends React.Component {
  componentDidMount() {
    if (this.player) {
      this.player.subscribeToStateChange(this.onChangeState)
      this._subscribed = true;
    }
  }

  componentDidUpdate() {
    if (this.player && !this._subscribed) {
      this.player.subscribeToStateChange(this.onChangeState)
      this._subscribed = true;
    }
  }

  getRef = (ref) => {
    this.player = ref;
  }

  onChangeState = (state, prevState) => {
    if (state.ended && state.ended !== prevState.ended && !Number.isNaN(state.duration)) {
      const { onEnded } = this.props;
      if (onEnded) {
        onEnded()
      }
    }
  }

  render() {
    const { source, type, sources = [] } = this.props;
    return <Player
      autoPlay
      ref={this.getRef}
      fluid={false}
      width="auto"
      height="100%"
    >
      {source && <source src={source} type={type} />}
      {sources.length ? sources.map(s => <source src={s.source} type={s.type} />) : null}
    </Player>;
  }
}

export default Video;