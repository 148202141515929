import React from 'react'
import './style.scss'

export default class Input extends React.Component {
	constructor(props) {
		super(props)

		this.id = 'input-' + Math.floor(Math.random() * Math.pow(10, 7))
	}

	render() {
		const { onFocus = () => {}, onBlur = () => {}, maxLength, autoFocus } = this.props
		return (
			<div className={`Input${this.props.className ? ' ' + this.props.className : ''}`} style={this.props.style}>
				{(!this.props.value || this.props.value === '') && <label htmlFor={this.id}>{this.props.title}</label>}
				<input
					type={this.props.type}
					maxLength={maxLength}
					autoFocus={autoFocus}
					id={this.id}
					value={this.props.value || ''}
					placeholder={this.props.placeholder || ''}
					onChange={(e) => this.props.onChange(e.target.value)}
					onFocus={onFocus}
					onBlur={onBlur}
					onKeyPress={(e) => {
						if (e.key === 'Enter' && this.props.onEnter) this.props.onEnter();
					}}
					onKeyDown={(e) => {
						if (e.key === 'ArrowUp' && this.props.onUp) this.props.onUp();
						if (e.key === 'ArrowDown' && this.props.onDown) this.props.onDown();
					}}
				/>
			</div>
		)
	}
}
