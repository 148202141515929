class EventEmitter {
  constructor() {
    this.events = {};
    this.on = this.on.bind(this);
    this.emit = this.emit.bind(this);
    this.off = this.off.bind(this);
  }

  on(event, callback) {
    if (!this.events[event]) {
      this.events[event] = [];
    }
    if (this.events[event].indexOf(callback) === -1) {
      this.events[event].push(callback);
    }
  }

  emit(event, data) {
    if (!this.events[event]) {
      return;
    }
    for (const callback of this.events[event]) {
      callback(data);
    }
  }

  off(event, callback) {
    if (!event) {
      this.events = {};
      return;
    }
    if (!this.events[event]) {
      return;
    }
    if (!callback) {
      delete this.events[event];
      return;
    }
    const index = this.events[event].indexOf(callback);
    if (index !== -1) {
      this.events[event].splice(index, 1);
    }
    if (!this.events[event].length) {
      delete this.events[event];
    }
  }
}

export default EventEmitter;