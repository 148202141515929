import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import ConnectionTest from './ConnectionTest';
import * as connectionTest from '../../reducers/connectionTest/types';

export default connect(
  (state) => ({
    user: state.User.user,
    test: state.ConnectionTest,
  }),
  {
    startConnectionTest: () => ({ type: connectionTest.CONNECTION_TEST_START }),
    startTestRecord: () => ({ type: connectionTest.CONNECTION_TEST_RECORD_START }),
    stopTestRecord: () => ({ type: connectionTest.CONNECTION_TEST_RECORD_STOP }),
    stopConnectionTest: () => ({ type: connectionTest.CONNECTION_TEST_STOP }),
    endConnectionTest: () => ({ type: connectionTest.CONNECTION_TEST_END }),
  }
)(withRouter(ConnectionTest))
