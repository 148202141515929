import apisauce from 'apisauce'
import config from '../config'
import { _store } from '../reducers/_store'

export const api = apisauce.create({
  baseURL: `${config.url}/api/attachments/upload`,
})

api.addRequestTransform((req) => {
  const state = _store.store.getState()
  if (state.User.user.medic_token) {
    req.params = {
      ...req.params,
      auth_token: state.User.user.medic_token,
    }
  } else {
    req.params = {
      ...req.params,
      session: state.User.session,
    }
  }
  if (!req.headers['x-session-key']) {
    req.headers['x-session-key'] = state.User.session
  }
  req.headers['x-requested-with'] = 'XMLHttpRequest'
})

export const ALLOWED_EXTENSIONS = [
  'pdf',
  'jpg',
  'jpeg',
  'bmp',
  'tiff',
  'png',
  'jp2',
  'gif',
  'doc',
  'txt',
  'rtf',
]

export const MAX_SIZE = 30 * 1024 * 1024 // 30 MBi

/** @param {string} ext */
export const isAllowedExtension = (ext) => ALLOWED_EXTENSIONS.includes(ext)

/**
 * @typedef {{
 *  appointment_id: number
 *  conference_id?: number
 *  file: File
 *  onDone?: (name?: string) => void
 *  onError?: (error?: Error) => void
 *  onStart?: () => void
 *  onEnd?: () => void
 *  onProgress?: (event: ProgressEvent) => void
 * }} UploadFileParams
 */
/**
 * @param {UploadFileParams} params 
 */
const uploadFile = async (params) => {
  const { appointment_id, conference_id, file, onDone, onError, onStart, onEnd, onProgress } = params
  try {
    const ext = file.type.split('/')[1]
    if (!isAllowedExtension(ext)) {
      const message = 'Ошибка. Этот тип файла не поддерживается. Допустимые форматы: ' + ALLOWED_EXTENSIONS.join(', ')
			_store.store.dispatch({ type: 'ERROR_PUSH', params: message });
      onError && onError(new Error(message))
      return
    } else if (file.size > MAX_SIZE) {
      const message = 'Файл весит больше ' + (MAX_SIZE / (1024 * 1024)).toFixed(0) + ' Мб'
			_store.store.dispatch({ type: 'ERROR_PUSH', params: message });
      onError && onError(new Error(message))
      return
    }
    const formData = new FormData()
    formData.append('document', file)
    formData.append('appointment_id', `${appointment_id}`)
    conference_id && formData.append('conference_id', `${conference_id}`)
    const onUploadProgress = (event) => {
      onProgress && onProgress(event)
    }
    onStart && onStart()
    const res = await api.post(`/${appointment_id}`, formData, { onUploadProgress })
    onEnd && onEnd()
    if (res.ok) {
      onDone && onDone(res.data && res.data.name)
    } else {
      const message = `Ошибка во время загрузки файла. ${res.status} ${res.problem}`
			_store.store.dispatch({ type: 'ERROR_PUSH', params: message });
      onError && onError(new Error(message))
    }
  } catch (e) {
    onError && onError(e)
  }
}

export default uploadFile