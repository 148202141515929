import { connect } from 'react-redux'
import OvTestCenterButtons from './OvTestCenterButtons'

export default connect(
  state => ({
    test: state.ConnectionTest,
  }),
  {
  }
)(OvTestCenterButtons)
