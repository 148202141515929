import React, { Component } from 'react'
import './style.scss'

class TextArea extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {}

  render() {
    const { value = '', onChange = () => {}, cols = 63, rows = 11, placeholder = '', style = {} } = this.props
    return (
      <textarea
        style={style}
        className="custom-textarea"
        cols={cols}
        rows={rows}
        placeholder={placeholder}
        onChange={(e) => {
          onChange(e.target.value)
        }}
      >
        {value}
      </textarea>
    )
  }
}

export default TextArea
