import React from 'react';
import ScreenWrapper from '../../components/ScreenWrapper';
import ReadonlyLabel from '../../components/ReadonlyLabel';
import { DocumentIcon } from '../../resources/icons';
import MirCardIcon from '../../resources/mir_card_x1.png';
import VisaCardIcon from '../../resources/visa_card_x1.png';
import MasterCardIcon from '../../resources/master_card_x1.png';
import JCBCardIcon from '../../resources/jcb_card_x1.png';

import './style.scss';

const Header = () => {
  return <div className="InformationHeader">
    <span>
      <span>+7 (8352) 23-77-23</span>
      <span>г. Чебоксары, ул. Тополиная, 11а</span>
    </span>
  </div>
}

const Information = () => {
  return <ScreenWrapper style={{ paddingLeft: 0, paddingRight: 0 }} ScreenHeader={<Header />}>
    <div className="Information">
      <div className="Header">Информация</div>
      <div className="Section">
        <div className="Title">О клинике</div>
        <div className="Content">
            <p><span className="Bold">Сеть клиник «МЕДИК»</span> - это современные, многопрофильные клиники для взрослых и детей.</p>
            <p>Мы оказываем полный спектр медицинских услуг: профилактику, диагностику, лечение и операции.</p>
            <p>Также у нас работает стоматологическое отделение и есть дневной стационар.</p>
            <p>Наши клиники оснащены современным оборудованием, которое позволяет не только поставить верный диагноз, но и провести эффективное лечение. Этому способствует и команда сильных специалистов. Каждый из них настоящий профессионал, любящий свою работу и людей.</p>
            <p>Мы гордимся нашим детским отделением, куда дети просятся сами. И не столько из-за игровой комнаты и подарков, сколько из-за добрых и опытных врачей.</p>
            <p>Наши клиники работают без выходных, без обедов и без очередей. К нам на диагностику и лечение приезжают не только со всех уголков Чувашии из таких городов как Новочебоксарск, Канаш, Шумерля, Ядрин, Алатырь, Цивильск, Батырево, Моргауши, Порецкое, Ибреси, Мариинский Посад, но и со всей России.</p>
            <p>В наших клиниках действует постоянная система скидок. Мы любим приятно удивлять своих пациентов акциями и подарками. Обсуживаем пациентов по полисам ДМС.</p>
        </div>
      </div>
      <div className="Delimiter" />
      <div className="Section ContactsSection">
        <div className="Title Contacts">Контакты</div>
        <div className="Content">
          <ReadonlyLabel title={'Клиника ЮЗР'}>г. Чебоксары, ул. Тополиная, 11а</ReadonlyLabel>
          <ReadonlyLabel basis={'calc(100% - 300px)'} title={'Клиника СЗР'}>г. Чебоксары, ул. Сверчкова, 8</ReadonlyLabel>
          <div className='line' />
          <ReadonlyLabel title={'Телефон'}>8 (8352) 23-77-23</ReadonlyLabel>
          <ReadonlyLabel basis={'calc(100% - 300px)'} title={'E-mail'}>info@mcmedic.ru</ReadonlyLabel>
        </div>
      </div>
      <div className="Delimiter" />
      <div className="Section RequisitesSection">
        <div className="Title Requisites">Реквизиты</div>
        <div className="Content">
          <ReadonlyLabel title={'Наименование предприятия'}>ООО «МЕДИК»</ReadonlyLabel>
          <ReadonlyLabel title={'ИНН'}>2130138476</ReadonlyLabel>
          <ReadonlyLabel basis={'calc(100% - 300px)'} title={'КПП'}>213001001</ReadonlyLabel>
          <ReadonlyLabel title={'Расчетный счет'}>40702810310530001824</ReadonlyLabel>
          <ReadonlyLabel basis={'calc(100% - 300px)'} title={'Наименование банка'}>ФИЛИАЛ № 6318 БАНКА ВТБ (ПАО) Г. САМАРА</ReadonlyLabel>
          <ReadonlyLabel title={'БИК'}>043601968</ReadonlyLabel>
          <ReadonlyLabel basis={'calc(100% - 300px)'} title={'Кор/Счет'}>30101810422023601968</ReadonlyLabel>
        </div>
      </div>
      <div className="Delimiter" />
      <div className="Section DocumentsSection">
        <div className="Title Documents">Документы</div>
        <div className="Content">
          <div>
            <div className="Icon"><DocumentIcon color="currentColor" /></div>
            <a href="https://mcmedic.ru/sites/default/files//lic2019new.pdf " target="_blank" rel="noopener noreferrer">Лицензия</a>
          </div>
          <div>
            <div className="Icon"><DocumentIcon color="currentColor" /></div>
            <a href="https://mcmedic.ru/sites/default/files/politika.pdf" target="_blank" rel="noopener noreferrer">Политика конфиденциальности</a>
          </div>
        </div>
      </div>
      <div className="Delimiter" />
      <div className="Section PaymentsSection">
        <div className="Title Payments">Способы оплаты</div>
        <div className="Content">
          <div>
            <span>Наличный расчёт</span>
            <br />
            <span>Оплата происходит непосредственно <span className="Bold">в клинике «МЕДИК»</span> Оплата наличными денежными средствами или банковской картой через администратора в кассу. Либо самостоятельным путём через терминал оплаты установленный в клинке Медик.</span>
          </div>
          <div>
            <span>Банковской картой онлайн</span>
            <br />
            <span>Для выбора оплаты товара с помощью банковской карты на соответствующей странице необходимо нажать кнопку <span className="Bold">Оплатить</span>. Оплата происходит через ПАО СБЕРБАНК с использованием банковских карт следующих платёжных систем: <span className="Bold">МИР, VISA International, Mastercard Worldwide, JCB</span></span>
          </div>
          <div className="Cards">
            <img src={MirCardIcon} alt='МИР' />
            <img src={VisaCardIcon} alt='VISA' />
            <img src={MasterCardIcon} alt='MasterCard' />
            <img src={JCBCardIcon} alt='JCB' />
          </div>
        </div>
      </div>
      <div className="Delimiter" />
      <div className="Section">
        <div className="Title">Возврат денежных средств</div>
        <div className="Content">
          <p>Срок возврата товара надлежащего качества составляет 30 дней с момента получения товара. Возврат переведенных средств, производится на Ваш банковский счет в течение 5—30 рабочих дней (срок зависит от банка, который выдал Вашу банковскую карту).</p>
        </div>
      </div>
      <div className="Section DataTransferSection">
        <div className="Title">Процесс передачи данных</div>
        <div className="Content">
          <p>Для оплаты (ввода реквизитов Вашей карты) Вы будете перенаправлены на платежный шлюз ПАО СБЕРБАНК. Соединение с платежным шлюзом и передача информации осуществляется в защищенном режиме с использованием протокола шифрования SSL. В случае если Ваш банк поддерживает технологию безопасного проведения интернет-платежей Verified By Visa, MasterCard SecureCode, MIR Accept, J-Secure для проведения платежа также может потребоваться ввод специального пароля.</p>
          <p>Настоящий сайт поддерживает 256-битное шифрование. Конфиденциальность сообщаемой персональной информации обеспечивается ПАО СБЕРБАНК. Введенная информация не будет предоставлена третьим лицам за исключением случаев, предусмотренных законодательством РФ. Проведение платежей по банковским картам осуществляется в строгом соответствии с требованиями платежных систем МИР, Visa Int., MasterCard Europe Sprl, JCB</p>
        </div>
      </div>
    </div>
  </ScreenWrapper>;
}

export default Information;