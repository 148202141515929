import { get, post, del } from './networking';
import { call, put } from 'redux-saga/effects';
import config from '../config';

const initialState = {
  totalUnread: 0,
  lastRoom: {},
}

export const ChatRoomsReducer = (state = initialState, action) => {
  let nextState
  switch (action.type) {
    case 'CHAT_ROOM_BY_APPOINTMENT_REQUEST':
      return {
        ...state,
        [action.params]: {
          ...state[action.params],
          error: '',
          fetching: true,
          appointment_id: action.params,
        },
      }
    case 'CHAT_ROOM_BY_APPOINTMENT_SUCCESS':
      return {
        ...state,
        [action.params.appointment_id]: {
          ...state[action.params.appointment_id],
          error: '',
          fetching: false,
          room: action.params.room,
        },
      }
    case 'CHAT_ROOM_BY_APPOINTMENT_FAILURE':
      return {
        ...state,
        [action.params.appointment_id]: {
          ...state[action.params.appointment_id],
          error: action.params.error,
          fetching: false,
          room: {},
        },
      }
    case 'CHAT_ROOM_INACTIVE_SET':
      return {
        ...state,
        [action.params]: {
          ...state[action.params],
          active: false,
        }
      }
    case 'CHAT_ROOM_ACTIVE_SET':
      return Object.keys(state).reduce((acc, key) => {
        if (key == 'totalUnread') {
          acc[key] = state[key]
          return acc
        }
        acc[key] = {
          ...state[key],
          active: key == action.params,
        }
        return acc;
      }, {})
    case 'CHAT_ROOM_UNREAD_UPDATE':
    case 'CHAT_ROOM_UNREAD_REQUEST':
      return {
        ...state,
        [action.params]: {
          ...state[action.params],
          unreadError: '',
          unreadFetching: true,
          appointment_id: action.params,
        },
      }
    case 'CHAT_ROOM_UNREAD_SUCCESS':
      nextState = {
        ...state,
        [action.params.appointment_id]: {
          ...state[action.params.appointment_id],
          unreadError: '',
          unreadFetching: false,
          unread: action.params.unread,
          appointment_id: action.params.appointment_id,
        },
        lastRoom: {
          ...state[action.params.appointment_id],
          unreadError: '',
          unreadFetching: false,
          unread: action.params.unread,
          appointment_id: action.params.appointment_id,
        },
      }
      nextState.totalUnread = Object.keys(nextState).filter(key => (key !== 'lastRoom' && key !== 'totalUnread')).reduce((acc, key) => {
        acc += nextState[key].unread || 0;
        return acc
      }, 0)
      return nextState
    case 'CHAT_ROOM_UNREAD_FAILURE':
      return {
        ...state,
        [action.params.appointment_id]: {
          unreadError: action.params.error,
          unreadFetching: false,
        }
      }
    default:
      return state
  }
}

export function* _getChatRoomByAppointment(action) {
    const { params: appointment_id } = action
    const result = yield call(get, {
        url: `${config.url}/api/chat-id/${appointment_id}`,
    })
    if (result.success) {
        yield put({ type: 'CHAT_ROOM_BY_APPOINTMENT_SUCCESS', params: { appointment_id, room: result.data } })
    } else if (result.error) {
        yield put({ type: 'CHAT_ROOM_BY_APPOINTMENT_FAILURE', params: { appointment_id, error: result.error } })
    } else {
        yield put({ type: 'CHAT_ROOM_BY_APPOINTMENT_FAILURE', params: { appointment_id, error: 'unknown error' } })
    }
}

export function* _updateChatRoomUnreadCount(action) {
  const { params: appointment_id } = action
  const result = yield call(post, {
    url: `${config.url}/api/chat-unread/${appointment_id}`,
  })
  if (result.success) {
    yield put({ type: 'CHAT_ROOM_UNREAD_SUCCESS', params: { appointment_id, unread: result.data } })
  } else if (result.error) {
    yield put({ type: 'CHAT_ROOM_UNREAD_FAILURE', params: { appointment_id, error: result.error} })
  } else {
    yield put({ type: 'CHAT_ROOM_UNREAD_FAILURE', params: { appointment_id, error: 'unknown error' } })
  }
}

export function* _getChatRoomUnreadCount(action) {
  const { params: appointment_id } = action
  const result = yield call(get, {
    url: `${config.url}/api/chat-unread/${appointment_id}`,
  })
  if (result.success) {
    yield put({ type: 'CHAT_ROOM_UNREAD_SUCCESS', params: { appointment_id, unread: result.data } })
  } else if (result.error) {
    yield put({ type: 'CHAT_ROOM_UNREAD_FAILURE', params: { appointment_id, error: result.error} })
  } else {
    yield put({ type: 'CHAT_ROOM_UNREAD_FAILURE', params: { appointment_id, error: 'unknown error' } })
  }
}

/** @param {number} appointment_id */
export const chatRoomByAppointmentRequest = (appointment_id) => ({ type: 'CHAT_ROOM_BY_APPOINTMENT_REQUEST', params: appointment_id })

/** @param {number} appointment_id */
export const chatRoomUnreadRequest = (appointment_id) => ({ type: 'CHAT_ROOM_UNREAD_REQUEST', params: appointment_id })

/** @param {number} appointment_id */
export const chatRoomUnreadUpdate = (appointment_id) => ({ type: 'CHAT_ROOM_UNREAD_UPDATE', params: appointment_id })

/** @param {number} appointment_id */
export const chatRoomActiveSet = (appointment_id) => ({ type: 'CHAT_ROOM_ACTIVE_SET', params: appointment_id })

/** @param {number} appointment_id */
export const chatRoomInActiveSet = (appointment_id) => ({ type: 'CHAT_ROOM_INACTIVE_SET', params: appointment_id })