import React from 'react';
import PropTypes from 'prop-types';
import Video from './Video'
import Chat from '../Chat';
import config from '../../config';
import AttachmentList from '../AttachmentList';
import './style.scss';
import 'video-react/dist/video-react.css'

class ArchiveConference extends React.Component {
  onFileMessage = () => {
    const { historyFetch } = this.props;
    if (historyFetch) {
      historyFetch();
    }
  }

  render() {
    const {
      showNavigation,
      history,
    } = this.props;
    let { item } = this.props;
    if (!item || !item.conference_id) {
      history.replace('/DoctorsDiary/History')
      showNavigation()
      return null;
    }
    const {
      conference_id: room,
      patient: { user_id: patient_id } = {},
      appointment_id,
    } = item;
    const hasAttachments = !!item.attachments.length;
    return <div className="ArchiveConferenceContainer">
      <div className="left">
        <Video source={`${config.url}/video/record-${room}-${patient_id}`} />
      </div>
      <div className={`right ${hasAttachments ? 'HasAttachments' : ''}`}>
        {hasAttachments && <div className="ConferenceAttachments">
          <div className="Title">Документы</div>
          <AttachmentList attachments={item.attachments} hideDelButton showDate title="Документы" />
        </div>}
        <Chat room={room} title="Чат" appointment_id={appointment_id} onFileMessage={this.onFileMessage} />
      </div>
    </div>;
  }
}

ArchiveConference.propTypes = {
  item: PropTypes.shape({
    conference_id: PropTypes.number,
    type: PropTypes.string, // one of "video", "audio"
    patient: PropTypes.shape({
      user_id: PropTypes.number,
      login: PropTypes.string,
      phone: PropTypes.string,
    }),
  }),
  user: PropTypes.shape({
    user_id: PropTypes.number,
    login: PropTypes.string,
    phone: PropTypes.string,
  }),
};

export default ArchiveConference;