import { call } from 'redux-saga/effects';
import { api } from './networking';
import config from '../config';

const initialState = {
  currentDoctor: null,
}

export const DoctorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CURRENT_DOCTOR':
      return {
        ...state,
        currentDoctor: action.payload,
      };
    case 'DELETE_CURRENT_DOCTOR':
      return {
        ...state,
        currentDoctor: null,
      };
    default:
      return state;
  }
}


export function* _sendDoctorRating(action) {
  try {
    const { params } = action;
    console.log('send doctor rating', params);
    const response = yield call(api.post, { url: `${config.url}/api/doctor/rating`, data: params });
  } catch (e) {
    console.error('sendDoctorRating.error', e);
  }
}