import { call, put, select } from 'redux-saga/effects';
import { api } from '../networking'
import config from '../../config'
import * as types from './types'

const time = () => `[${new Date().toISOString()}]`

export function* startConnectionTest() {
  try {
    const { data } = yield call(api.get, { url: `${config.url}/api/testconnection/start_test?_=${Date.now()}` });
    console.log(time(), 'start_test response: ', data)
    yield put({ type: types.CONNECTION_TEST_STARTED, payload: { sessionId: data.sessionId, token: data.token, secondToken: data.secondToken, maxDuration: data.maxDuration } });
  } catch (e) {
    console.log(time(), 'start_test ERROR', e);
    yield put({ type: types.CONNECTION_TEST_START_ERROR, error: e.message });
  }
}

export function* startTestRecord() {
  try {
    const sessionId = yield select(state => (config.isMobile ? state.telemedicine : state).ConnectionTest.sessionId);
    const { data } = yield call(api.get, { url: `${config.url}/api/testconnection/start_test_record?session=${sessionId}&_=${Date.now()}` });
    yield put({
      type: types.CONNECTION_TEST_RECORD_STARTED,
      payload: { startTime: data.startTime, recordingId: data.recordingId, recordingUrl: data.recordingUrl, maxDuration: data.maxDuration },
    });
  } catch (e) {
    console.log(time(), 'start_test_record ERROR', e);
    yield put({ type: types.CONNECTION_TEST_RECORD_START_ERROR, error: e.message });
  }
}

export function* stopTestRecord() {
  try {
    const session = yield select(state => (config.isMobile ? state.telemedicine : state).ConnectionTest);
    const { data } = yield call(api.get, { url: `${config.url}/api/testconnection/stop_test_record?session=${session.sessionId}&record=${session.record.recordingId}&_=${Date.now()}` });
    yield put({ type: types.CONNECTION_TEST_RECORD_STOPPED, payload: { recordingUrl: data && data.recordingUrl } });
  } catch (e) {
    console.log(time(), 'stop_test_record ERROR', e);
    yield put({ type: types.CONNECTION_TEST_RECORD_STOP_ERROR, error: e.message });
  }
}

export function* stopConnectionTest() {
  try {
    const session = yield select(state => (config.isMobile ? state.telemedicine : state).ConnectionTest);
    const { data } = yield call(api.get, { url: `${config.url}/api/testconnection/stop_test?session=${session.sessionId}&_=${Date.now()}` });
    yield put({ type: types.CONNECTION_TEST_STOPPED, payload: { recordingUrl: data && data.recordingUrl } });
  } catch (e) {
    yield put({ type: types.CONNECTION_TEST_STOP_ERROR, error: e.message });
  }
}
