import React from 'react';
import PropTypes from 'prop-types';
import { CheckedIcon, UncheckedIcon } from '../../resources/icons';
import './style.scss';

const CheckBox = (props) => {
  const { checked, label, onClick, className, iconProps, disabled } = props;
  const Icon = checked ? CheckedIcon : UncheckedIcon;
  return <div className={`CheckBox ${className || ''} ${disabled ? 'disabled' : ''}`} onClick={disabled ? undefined : onClick}>
    <div className="Icon"><Icon {...iconProps}/></div>
    <div className="Label">{label}</div>
  </div>
};

CheckBox.propTypes = {
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  iconProps: PropTypes.shape({ color: PropTypes.string }),
};

export default CheckBox;