import moment from 'moment';
import 'moment/locale/ru'
import {
  CameraIcon,
  MPhoneIcon as PhoneIcon,
  ChatIcon,
} from '../../resources/icons';
/**
 * @param {string|Date} date YYYY-MM-DD HH:mm:ss
 * @return {string} DD MMMM, dddd
 */
export const formatDate = (date) => moment(moment.utc(date).toDate()).format('DD MMMM, dddd');

/**
 * @param {string|Date} date YYYY-MM-DD HH:mm:ss
 * @param {string|Date} [rhs] YYYY-MM-DD HH:mm:ss
 * @return {number} разница в шт. дней
 */
export const dayDiff = (date, rhs) => moment(moment.utc(date).toDate()).startOf('day').diff(moment(rhs).startOf('day'), 'days');

/**
 * @param {string|Date} date YYYY-MM-DD HH:mm:ss
 * @param {string|Date} [rhs] YYYY-MM-DD HH:mm:ss
 * @return {number} разница в шт. минут
 */
export const minuteDiff = (date, rhs) => moment(moment.utc(date).toDate()).diff(moment(rhs), 'minutes');

/**
 * @param {string|Date} date
 * @return {string} HH:mm
 */
export const formatTime = (date) => moment(moment.utc(date).toDate()).format('HH:mm');

/**
 * 
 * @param {number} val
 * @param {number} [size]
 * @return {string}
 */
export const pad = (val, size = 2) => {
  val = (val || '').toString();
  if (val.length >= size) {
    return val;
  }
  return `000000000${val}`.slice(-size);
};
/**
 * @param {number} milliseconds
 * @return {string} HH:mm:ss
 */
export const formatDuration = (milliseconds) => {
  const _1Hour = 3600 * 1000;
  const _1Minute = 60 * 1000;
  let rest = milliseconds;
  const hours = Math.floor(rest / _1Hour);
  rest -= hours * _1Hour;
  const minutes = Math.floor(rest / _1Minute);
  rest -= minutes * _1Minute;
  const seconds = Math.floor(rest / 1000);
  return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
};

/**
 * @param {Object} param0 
 * @param {string} param0.last_name
 * @param {string} param0.first_name
 * @param {string} [param0.middle_name]
 * @return {string}
 * 
 * @example
 * const user = {
 *   last_name: 'Пупкин',
 *   first_name: 'Василий',
 *   middle_name: 'Васильевич',
 * };
 * const name = formatName(user);
 * console.log(name); // П. Василий Васильевич
 */
export const formatName = ({ last_name, first_name = '', middle_name, login } = {}) => {
  let result = '';
  if (last_name) {
    result += `${(last_name[0] || '').toUpperCase()}. `;
  }
  result += first_name;
  if (middle_name) {
    result += ` ${middle_name}`;
  }
  return result.trim() || login || '';
};

export const getFullName = ({ last_name, first_name = '', middle_name, login } = {}) => {
  let result = '';
  if (last_name) {
    result += `${(last_name[0] || '').toUpperCase()}${(last_name || '').slice(1).toLowerCase()} `;
  }
  result += first_name;
  if (middle_name) {
    result += ` ${middle_name}`;
  }
  return result.trim() || login || '';
}

export const formatDoctorName = ({ last_name, first_name = '', middle_name } = {}) => {
  let result = '';
  if (last_name) {
    result += `${(last_name[0] || '').toUpperCase()}${last_name.slice(1)}`;
  }
  result += ` ${(first_name[0] || '').toUpperCase()}.`;
  result += ` ${(middle_name[0] || '').toUpperCase()}.`;
  return result;
};

/**
 * @param {Object} param0
 * @param {string} param0.gender
 * @param {number} param0.years
 * @return {string} e.i. Мужчина, 23
 */
export const formatUserMeta = ({ gender, years } = {}) => {
  let retval = [];
  if (gender) {
    gender = gender.toLowerCase();
    switch (gender) {
      case 'f':
        retval.push('Женщина');
        break;
      case 'm':
      default:
        retval.push('Мужчина');
        break;
    }
  }
  if (years) {
    retval.push(years);
  }
  return retval.join(', ');
};

/**
 * @param {string} type
 * @return {{ description: string, Icon: Node }}
 */
export const getAppointmentTypeData = (type) => {
  let description;
  let Icon;
  switch (type) {
    case 'video':
      description = 'Видео консультация';
      Icon = CameraIcon;
      break;
    case 'audio':
      description = 'Аудио консультация';
      Icon = PhoneIcon;
      break;
    case 'chat':
    default:
      description = 'Чат';
      Icon = ChatIcon;
  }
  return { description, Icon };
};