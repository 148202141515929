import keyMirror from './keyMirror';

export const historyTypes = keyMirror({
  HISTORY_FETCH: null,
  HISTORY_FETCH_ERROR: null,
  HISTORY_FETCH_SAVE: null,
});

export const historyActions = {
  historyFetch: () => ({ type: historyTypes.HISTORY_FETCH }),
};

export const initialState = {
  list: [],
  fetch: false,
  error: '',
};

const HistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case historyTypes.HISTORY_FETCH:
      return {
        ...state,
        fetch: true,
        error: '',
      };
    case historyTypes.HISTORY_FETCH_ERROR:
      return {
        ...state,
        fetch: false,
        error: action.payload.error,
      };
    case historyTypes.HISTORY_FETCH_SAVE:
      return {
        ...state,
        list: action.payload.history,
        fetch: false,
        error: '',
      };
    default:
      return state;
  }
};

export default HistoryReducer;