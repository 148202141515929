import stringifySafe from './stringifySafe'
import { api } from '../reducers/networking'
import config from '../config'
class Log {
  constructor(args, type) {
    this.timestamp = Date.now()
    this.data = (type ? [type, ...args] : args).map(stringifySafe)
    this.type = type
    this.length = this.data.reduce((acc, cur) => {
      return acc + cur.length
    }, 0)
  }

  static maxSize = 5 * 1024

  getLength() {
    return this.length
  }

  slice() {
    const data = []
    let size = 0;
    for (const el of this.data) {
      size += el.length
      if (size < Log.maxSize) {
        data.push(el)
      } else {
        data.push(el.slice(0, Log.maxSize - size))
        break
      }
    }
    this.data = data;
    this.length = this.data.reduce((acc, cur) => {
      return acc + cur.length
    }, 0)
  }
}

class Logs {
  constructor() {
    this.items = []
  }
  static maxSize = 2 * 1024 * 1024

  add(args, type) {
    const item = new Log(args, type)
    if (item.getLength() > Log.maxSize) {
      item.slice()
    }
    this.items.push(item)
    clearTimeout(this.timerId)
    console[type](...args)
    const size = this.getLength()
    if (size > Logs.maxSize) {
      this.save()
    } else {
      this.timerId = setTimeout(() => {
        this.save()
      }, 5000)
    }
  }

  getLength() {
    return this.items.reduce((acc, cur) => {
      return acc + cur.getLength()
    }, 0)
  }

  async save() {
    const items = this.items.slice()
    this.items.length = 0
    if (!items.length) {
      return
    }
    try {
      const response = await api.post({
        url: `${config.url}/api/openvidu-logs`,
        data: items,
      })
    } catch (e) {
      console.log('error', e)
    }
  }
}

class Logger {
  constructor() {
    this.logs = new Logs()
  }

  log(...args) {
    this.logs.add(args, 'log')
    console.log(...args)
  }

  debug(...args) {
    this.logs.add(args, 'debug')
    console.debug(...args)
  }

  info(...args) {
    this.logs.add(args, 'info')
    console.info(...args)
  }

  warn(...args) {
    this.logs.add(args, 'warn')
    console.warn(...args)
  }

  error(...args) {
    this.logs.add(args, 'error')
    console.error(...args)
  }
}

export { Logger }
export default new Logger()