import React from 'react'
import PropTypes from 'prop-types'
import './style.scss';
import BButton from '../BButton';
// import uaParser from '../../utils/uaParser'

// const getPlayRecordText = () => uaParser.getBrowser().name.toLowerCase().indexOf('safari') !== -1 ? 'Скачать запись' : 'Просмотр записи';

export default class OvTestCenterButtons extends React.Component {
  render() {
    const { test, title, leftText, rightText, forceShow, children } = this.props;
    if ((!test.stopped || !test.record.recordingUrl) && !forceShow) {
      return null;
    }
    return <div className="ConnectionTestCenterButtons">
      <span>{title}</span>
      <div className="Buttons">
        {this.props.onLeft && <BButton
          className="RepeatTestBtn"
          onClick={this.props.onLeft}
        >
          <span>{leftText}</span>
        </BButton>}
        <BButton
          className="ViewRecordBtn"
          onClick={this.props.onRight}
        >
          <span>{rightText}</span>
        </BButton>
      </div>
      {children}
    </div>;
  }
}

OvTestCenterButtons.propTypes = {
  onRight: PropTypes.func,
  onLeft: PropTypes.func,
  title: PropTypes.string,
  leftText: PropTypes.string,
  rightText: PropTypes.string,
  test: PropTypes.shape({
    stopped: PropTypes.bool,
    record: PropTypes.shape({
      recordingUrl: PropTypes.string,
    }),
  }),
}

OvTestCenterButtons.defaultProps = {
  title: 'Тест завершен!',
  leftText: 'Повторить тест',
  rightText: 'Просмотр записи',
}