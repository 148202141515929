import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ScreenWrapper from '../../components/ScreenWrapper'
import Button from '../../components/BButton'
import FileUpload from '../../components/FileUpload'
import Timer from '../../components/Timer'
import AttachmentList from '../../components/AttachmentList'
import AppointmentInfo from '../../components/AppointmentInfo'
import Modal from '../../components/MModal'
import ConfirmPolicies from '../../components/ConfirmPolicies'
import moment from 'moment'
import 'moment/locale/ru'
import './style.scss'
import '../../config'
import config from '../../config'
import { chatRoomByAppointmentRequest, chatRoomActiveSet, chatRoomInActiveSet } from '../../reducers/chatRooms'
import Chat from '../../components/Chat'
import { formatDoctorName, formatName } from '../DoctorsDiary/utils'
import BonusPaymentModal from '../../components/BonusPaymentModal/BonusPaymentModal'
import uploadFile from '../../utils/uploadFile'
moment().locale('ru')

class PatientDiary extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			fileInputRef: undefined,
			appointment_id: null,
			uploading: false,
			bonusPayment: false,
		}
	}

	componentDidMount() {
		this.props.getAppointments()
	}

	componentDidUpdate(prevProps, prevState) {
		const { attachments } = this.props
		if (prevProps.attachments.fetching && !attachments.fetching) {
			this.props.getAppointments()
		}
		if (prevProps.payFormUrl !== this.props.payFormUrl && !this.props.payFetch && this.props.payFormUrl) {
			window.location.replace(this.props.payFormUrl)
		}
	}

	chooseFile = (appointment) => {
		const { fileInputRef } = this.state
		const { direction_service_id: appointment_id } = appointment
		this.setState({ appointment_id })
		fileInputRef.current.click()
	}

	onProgress = ({ progress }) => {
		if (progress !== 1) {
			this.setState({ uploading: true })
		}
	}

	onUpload = (path, name) => {
		const { appointment_id } = this.state
		this.setState({ uploading: false })
		this.props.addAttachment({ path, name, appointment_id })
	}

	/**
	 * @param {File} file
	 */
	_uploadFile = (file) => {
		const { appointment_id } = this.state
		uploadFile({
			appointment_id,
			file,
			onProgress: (event) => {
				this.onProgress({ progress: event.loaded / event.total })
			},
			onDone: () => {
				this.setState({ uploading: false })
				this.props.getAppointments()
			},
			onError: (error) => {
				this.setState({ uploading: false })
			},
		})
	}

	jumpToAppointment = (appointment_id) => {
		this.props.history.push('/Appointment')
		this.props.history.push({
			pathname: '/Appointment',
			state: { appointment_id },
		})
	}

	/**
	 * 
	 * @param {*} appointment 
	 * @param {{
	 * 	bonus_points?: number;
	 * 	auth_token?: string;
	 * 	bonus_code?: string;
	 * }} params 
	 */
	createPayment = (appointment, params) => {
		const { createPayment, user } = this.props
		console.log('createPayment.appointment: ', typeof createPayment, appointment, params)
		let payload = {
			amount: appointment.price * 100,
			direction_service_id: appointment.direction_service_id,
		}
		if (params.bonus_code || params.auth_token || params.bonus_code) {
			const bonus_points = params.bonus_points || 0
			payload = Object.assign({}, payload, {
				amount: payload.amount - bonus_points * 100,
				bonus_points,
				bonus_code: params.bonus_code,
				auth_token: user.medic_token,
			})
		}
		createPayment(payload)
		this.setState({ appointmentToPay: null })
	}

	onTimeout = (appointment, index) => {
		console.log('onTimeout.appointment: ', appointment)
		const { direction_service_id } = appointment
		const { timedOut = [] } = this.state
		this.setState(
			{
				timedOut: [...timedOut, direction_service_id],
			},
			() => {
				const { attachments } = appointment
				for (const att of attachments) {
					this.props.delAttachment(att)
				}
			}
		)
	}

	hasActiveConference = () => {
		const { session, token } = this.props
		return session && !token
	}

	rejoinConference = () => {
		console.log('rejoinConference.session', this.props.session)
		console.log('this.rejoinConference: ', typeof this.props.rejoinConference)
		this.props.rejoinConference(this.props.session)
	}

	chat = (props) => {
		const { rooms } = props || this.props
		const { chatRoomAppointmentId } = this.state
		return rooms[chatRoomAppointmentId] || {}
	}

	chatRoom = (props) => {
		const chat = this.chat(props)
		return chat.room ? chat.room.room : -1
	}

	appointmentId = (props) => {
		const chat = this.chat(props)
		return chat.appointment_id || 0
	}

	render() {
		const { uploading, timedOut = [], appointmentToPay, chatRoomAppointmentId } = this.state
		const { appointments, fetching, payFetching, rooms } = this.props
		const { data: list = [] } = appointments
		const chat = this.chat()
		const room = this.chatRoom()
		const appointmentId = this.appointmentId()
		return (
			<ScreenWrapper style={{ display: 'flex', padding: 0 }}>
				<Modal
					title="Оплата"
					visible={!!appointmentToPay && !this.state.bonusPayment}
					onClose={() => this.setState({ appointmentToPay: null })}
					onShutter={() => this.setState({ appointmentToPay: null })}
				>
					<ConfirmPolicies
						onSubmit={() => {
							this.setState({ bonusPayment: true })
						}}
					/>
				</Modal>
				{Boolean(appointmentToPay)
				&& <BonusPaymentModal
					visible={this.state.bonusPayment}
					price={appointmentToPay.price}
					gotoPayment={(params) => {
						params = params || {}
						this.createPayment(appointmentToPay, params)
						this.setState({ bonusPayment: false })
					}}
				/>}
				<div className='appointments'>
					<h2>Телемедицина</h2>
					{!list.length && !this.hasActiveConference() && (
						<>
							<div className='description' style={{ borderBottom: 'none' }}>
								<p>
									Данный раздел служит для проведения <span className='Bold'>онлайн консультаций</span> с врачами
									клиники.
									<br />
									<span className='Bold'>Записаться на онлайн прием можно здесь.</span>
									<Button
										className='CreateAppointment'
										onClick={() => window.location.replace(`${config.lkurl}/menu/main/makeappointment`)}>
										Записаться на прием
									</Button>
								</p>
							</div>
						</>
					)}
					{this.hasActiveConference() && (
						<>
							<div className='description' style={{ borderBottom: 'none' }}>
								<p>
									Данный раздел служит для проведения <span className='Bold'>онлайн консультаций</span> с врачами
									клиники.
									<br />
									<span className='Bold'>Ваш прием еще не завершен</span>
									<Button className='RejoinConference' onClick={this.rejoinConference}>
										Вернуться на прием
									</Button>
								</p>
							</div>
						</>
					)}
					{list.length > 0 && (
						<>
							{!this.hasActiveConference() && (
								<div className='description'>
									<p>
										Данный раздел служит для проведения <span className='Bold'>онлайн консультаций</span> с врачами
										клиники. <span className='Bold'>За 5 минут</span> до начала приема, пожалуйста,{' '}
										<span className='Bold'>авторизуйтесь</span> с помощью номера телефона в личном кабинете и{' '}
										<span className='Bold'>ждите звонка</span> от врача в данном разделе.
									</p>
									<Button
										className='CreateAppointment'
										onClick={() => window.location.replace(`${config.lkurl}/menu/main/makeappointment`)}>
										Записаться на прием
									</Button>
								</div>
							)}
							<h3>Список приемов</h3>
							<div className='list'>
								{list.map((a, i) => {
									const isTimedOut = timedOut.includes(a.direction_service_id)
									const chat = rooms && rooms[a.direction_service_id] || {}
									const unread = chat.unread || 0
									const isChatActive = this.state.chatRoomAppointmentId == a.direction_service_id
									return (
										<div key={i} className={`appointment ${isChatActive ? 'active' : ''}`}>
											<div className='container'>
												<AppointmentInfo
													appointment={a}
													onClick={() => {
														this.jumpToAppointment(a.direction_service_id)
													}}
													className={isTimedOut ? 'timedout' : ''}
												/>
												{!isTimedOut && (
													<AttachmentList type='rows' attachments={a.attachments} onDelete={this.props.delAttachment} />
												)}
												{!isTimedOut && (
													<div className='buttons'>
														<Button
															className='attach'
															color='pink'
															loading={uploading || fetching || payFetching || chat.fetching}
															disabled={timedOut.includes(a.direction_service_id)}
															onClick={() => {
																this.chooseFile(a)
															}}>
															Прикрепить
														</Button>
														{a.paid && (
															<Button
																className="chat"
																color="pink"
																loading={chat.fetching || uploading || fetching || payFetching}
																onClick={() => {
																	const { chatRoomAppointmentId } = this.state
																	console.log('on chat', { chatRoomAppointmentId, appointment: a })
																	if (chatRoomAppointmentId && chatRoomAppointmentId == a.direction_service_id) {
																		this.props.chatRoomInActiveSet(a.direction_service_id)
																		this.setState({ chatRoomAppointmentId: 0 })
																	} else {
																		this.props.chatRoomByAppointmentRequest(a.direction_service_id)
																		this.setState({ chatRoomAppointmentId: a.direction_service_id })
																		this.props.chatRoomActiveSet(a.direction_service_id)
																	}
																}
																}
															>
																Чат
																{unread > 0 && <div className="badge">{unread}</div>}
															</Button>
														)}
														{!a.paid && (
															<>
																<Button
																	loading={uploading || fetching || payFetching || chat.fetching}
																	className='pay'
																	onClick={() => this.setState({ appointmentToPay: a })}>
																	Оплатить (
																	{
																		<Timer
																			until={+moment(a.inserted_at).toDate() + (a.registered_by_patient === false ? 120 : 20) * 60 * 1000}
																			onTimeout={() => this.onTimeout(a, i)}
																		/>
																	}
																	)
																</Button>
																<div className='payDescription'>
																	{'Для подтверждения онлайн-консультации необходима предварительная оплата'}
																</div>
															</>
														)}
													</div>
												)}
												{!a.paid && isTimedOut && (
													<div className='InfoBox'>
														<span>
															К сожалению, Ваша запись <span className='Bold'>удалена</span>, т.к.{' '}
															<span className='Bold'>оплата не прошла</span>
														</span>
													</div>
												)}
											</div>
										</div>
									)
								})}
							</div>
							<div style={{ display: 'none' }}>
								<FileUpload
									onProgress={this.onProgress}
									onUpload={this.onUpload}
									onUploadFile={this._uploadFile}
									passRef={(fileInputRef) => this.setState({ fileInputRef })}
								/>
							</div>
						</>
					)}
				</div>
				<div className="chatRightSide">
					{room > 0 && (
						<Chat
							title={formatDoctorName(chat.room.doctor)}
							room={room}
							appointment_id={appointmentId}
							onClose={() => {
								this.setState({ chatRoomAppointmentId: 0 })
							}}
						/>
					)}
				</div>
			</ScreenWrapper>
		)
	}
}

const _1day = 24 * 3600 * 1000
const fakeAppointments = [
	{
		direction_service_id: 1,
		started_at: new Date(Date.now() + 10 * 60000).toISOString(),
		inserted_at: '2020-07-14T07:00:00.000Z',
		registered_by_patient: false,
		attachments: [],
	},
	{
		direction_service_id: 4,
		started_at: new Date(Date.now() + _1day - 3600 * 1000).toISOString(),
		inserted_at: new Date(Date.now() - 3600 * 1000 - 1200).toISOString(),
		registered_by_patient: false,
		attachments: [],
	},
	{
		direction_service_id: 2,
		started_at: new Date(Date.now() + _1day + 6 * 60000).toISOString(),
		inserted_at: new Date(Date.now() - 3600 * 1000 - 1200).toISOString(),
		registered_by_patient: false,
		attachments: [],
	},
	{
		direction_service_id: 3,
		started_at: new Date(Date.now() + 39 * _1day).toISOString(),
		inserted_at: new Date(Date.now() - 3600 * 1000 - 1200).toISOString(),
		registered_by_patient: false,
		attachments: [],
	},
]

const mapStateToProps = (state) => ({
	user: state.User.user,
	appointments: state.Appointments,
	// appointments: { data: fakeAppointments },
	attachments: state.Attachments,
	fetching: state.Attachments.fetching || state.Appointments.fetch,
	payFetching: state.Payment.fetch,
	payFormUrl: state.Payment.formUrl,
	session: state.Conference.session,
	token: state.Conference.token,
	rooms: state.ChatRooms,
})

const mapDispatchToProps = (dispatch) => ({
	addAttachment: (params) => dispatch({ type: 'ATTACHMENT_ADD', params }),
	delAttachment: (params) => dispatch({ type: 'ATTACHMENT_DEL', params }),
	getAppointments: (params) => dispatch({ type: 'PATIENT_APPOINTMENTS_GET', params }),
	createPayment: (params) => dispatch({ type: 'PAYMENT_CREATE', params }),
	rejoinConference: (session) => dispatch({ type: 'REJOIN_CONFERENCE', payload: session }),
	chatRoomByAppointmentRequest: (...args) => dispatch(chatRoomByAppointmentRequest(...args)),
	chatRoomActiveSet: (...args) => dispatch(chatRoomActiveSet(...args)),
	chatRoomInActiveSet: (...args) => dispatch(chatRoomInActiveSet(...args)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PatientDiary))
