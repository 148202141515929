import socketIO from 'socket.io-client'
import config from '../../config'
import { videoConferenceJoin, videoConferencePending, videoConferenceStopped } from './conferences.actions'
import { incomingCallAnswered, incomingCallDeclined, incomingCallStarted, incomingCallStopped, incomingCallStoppedOnDisconnect } from './incomingCalls.actions'
import lazyStore from './lazyStore'
import { outcomingCallAnswered, outcomingCallDeclined, outcomingCallError, outcomingCallFailed, outcomingCallStarted, outcomingCallStopped, outcomingCallStoppedOnDisconnect } from './outcomingCall.actions'

/** @type {SocketIOClient.Socket} */
let socket

/**
 * @param {string} id
 * @param {'doctor' | 'patient'} [role]
 */
 export const connect = (id, role) => {
  console.log(connect.name, 'socket.io', role)
  if (socket && (socket.connected || !socket.disconnected)) {
    return false
  }
  forceConnect(id, role)
  return true
}

/**
 * @param {string} id
 * @param {'doctor' | 'patient'} [role]
 */
export const forceConnect = (id, role) => {
  disconnect()
  role = _getRole(role)
  console.log('socket.io', forceConnect.name, id, role)
  const token = _getToken(role)
  socket = socketIO(config.socketurl.replace('/client', '/app'), { query: { token, role, uuid: id } })

  socket.on('connect', () => {
    console.log('socket.io.connected', forceConnect.name, socket.id, id, role)
    if (role == 'doctor') {
      initializeDoctorSocket(socket)
    }
    if (role == 'patient') {
      initializePatientSocket(socket)
    }
  })

  socket.on('disconnect', (reason) => {
    lazyStore.store.dispatch(incomingCallStoppedOnDisconnect())
    lazyStore.store.dispatch(outcomingCallStoppedOnDisconnect())
  })
}

export const disconnect = () => {
  if (socket && (socket.connected || !socket.disconnected)) {
    socket.disconnect()
  }
}

/**
 * @param {'doctor' | 'patient'} [role]
 * @return {string}
 */
const _getToken = (role) => {
  role = _getRole(role)
  if (role == 'doctor') {
    return lazyStore.store.getState().User.session
  }
  if (role == 'patient' && config.isMobile) {
    return lazyStore.store.getState().user.params.token
  } else if (role == 'patient' && !config.isMobile) {
    return lazyStore.store.getState().User.user.medic_token
  }
  return ''
}

/**
 * @param {'doctor' | 'patient'} [role]
 * @return {'doctor' | 'patient'}
 */
const _getRole = (role) => {
  if (role) {
    return role
  }
  if (config.isMobile || lazyStore.store.getState().User.user.medic_token) {
    return 'patient'
  }
  return 'doctor'
}

/**
 * @param {SocketIOClient.Socket} socket
 */
export const initializeDoctorSocket = (socket) => {
  socket.on('outcoming_call_started', onOutcomingCall)
  socket.on('outcoming_call_stopped', onOutcomingCall)
  socket.on('outcoming_call_failed', onOutcomingCall)
  socket.on('outcoming_call_answered', onOutcomingCall)
  socket.on('outcoming_call_declined', onOutcomingCall)
  socket.on('outcoming_call_error', onOutcomingCallError)

  socket.on('conference_pending', onConferencePending)
  socket.on('conference_stopped', onConferenceStopped)
}

/**
 * @param {SocketIOClient.Socket} socket
 */
export const initializePatientSocket = (socket) => {
  socket.on('incoming_call_started', onIncomingCall)
  socket.on('incoming_call_stopped', onIncomingCall)
  socket.on('incoming_call_answered', onIncomingCall)
  socket.on('incoming_call_declined', onIncomingCall)

  socket.on('conference_pending', onConferencePending)
  socket.on('conference_stopped', onConferenceStopped)
}

/** @param {CallParams} params */
const onIncomingCall = (params) => {
  switch (params.call.state) {
    case 'started':
      lazyStore.store.dispatch(incomingCallStarted(params))
      break;
    case 'stopped':
      lazyStore.store.dispatch(incomingCallStopped(params))
      break
    case 'answered':
      lazyStore.store.dispatch(incomingCallAnswered(params))
      break
    case 'declined':
      lazyStore.store.dispatch(incomingCallDeclined(params))
      break
    default:
      break;
  }
}

/** @param {CallParams} params */
const onOutcomingCall = (params) => {
  switch (params.call.state) {
    case 'started':
      lazyStore.store.dispatch(outcomingCallStarted(params))
      break;
    case 'stopped':
      lazyStore.store.dispatch(outcomingCallStopped(params))
      break
    case 'answered':
      lazyStore.store.dispatch(outcomingCallAnswered(params))
      break
    case 'declined':
      lazyStore.store.dispatch(outcomingCallDeclined(params))
      break
    case 'failed':
      lazyStore.store.dispatch(outcomingCallFailed(params))
      break;
    default:
      break;
  }
}

/** @param {CallParams} params */
const onOutcomingCallError = (params) => {
  lazyStore.store.dispatch(outcomingCallError(params))
}

/** @param {CallParams} params */
const onConferencePending = (params) => {
  lazyStore.store.dispatch(videoConferencePending(params))
}

/** @param {CallParams} params */
const onConferenceStopped = (params) => {
  lazyStore.store.dispatch(videoConferenceStopped(params))
}

/**
 * @typedef {{
 *  call: import('./calls.types').Call
 *  token?: string
 *  reason?: string
 *  conference?: import('./calls.types').Conference
 * }} CallParams
 */

/**
 * @param {string} event 
 * @param  {...any} args 
 */
export const emit = (event, ...args) => {
  if (socket && socket.connected) {
    socket.emit(event, ...args)
    return true
  }
  return false
}

export { socket }