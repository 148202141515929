import { combineReducers } from 'redux'
import storage from '../storage'

import { ConferenceReducer } from '../reducers/conference'
import { UserReducer } from '../reducers/user'
import { ErrorReducer } from '../reducers/error'
import { SocketReducer } from '../reducers/socket'
import { UploadReducer } from '../reducers/upload'
import { ChatReducer } from '../reducers/chat'
import { PatientsReducer } from '../reducers/pateints'
import { PaymentReducer } from '../reducers/payment'
import { AppointmentsReducer } from '../reducers/appointments'
import HistoryReducer from '../reducers/history'
import { AttachmentsReducer } from '../reducers/attachments.js'
import { SoundReducer } from '../reducers/sound.js'
import { UsersReducer } from './users'
import { DoctorsReducer } from './doctors'
import { ConnectionTestReducer } from './connectionTest/connectionTest.reducer'
import { TimerReducer } from './timer'
import NetworkReducer from './networkReducer'
import { ChatRoomsReducer } from './chatRooms'
import { BonusReducer } from './bonus'

export const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'Sound',
    'Users',
    'ConnectionTest',
    'Timer',
    'Network',
    'ChatRooms',
    'Bonus',
  ]
}

const reducers = combineReducers({
  Conference: ConferenceReducer,
  User: UserReducer,
  Errors: ErrorReducer,
  Socket: SocketReducer,
  Uploads: UploadReducer,
  Chats: ChatReducer,
  ChatRooms: ChatRoomsReducer,
  Patients: PatientsReducer,
  Payment: PaymentReducer,
  History: HistoryReducer,
  Appointments: AppointmentsReducer,
  Attachments: AttachmentsReducer,
  Sound: SoundReducer,
  Users: UsersReducer,
  Doctors: DoctorsReducer,
  ConnectionTest: ConnectionTestReducer,
  Timer: TimerReducer,
  Network: NetworkReducer,
  Bonus: BonusReducer,
})

export default reducers;
