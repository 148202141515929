import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
	CameraIcon,
	MPhoneIcon,
	ChatIcon,
	CalendarIcon,
	RubleIcon,
	DoctorIcon,
	ServiceIcon,
} from '../../resources/icons'
import moment from 'moment'
import 'moment/locale/ru'
import './style.scss'
moment().locale('ru')

class AppointmentInfo extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	renderRecType = (type) => {
		switch (type) {
			case 'video':
				return (
					<>
						<CameraIcon /> <strong>Видео консультация</strong>
					</>
				)
			case 'audio':
				return (
					<>
						<MPhoneIcon /> <strong>Аудио консультация</strong>
					</>
				)
			case 'chat':
				return (
					<>
						<ChatIcon /> <strong>Чат</strong>
					</>
				)
			default:
				return (
					<>
						<CameraIcon /> <strong>Видео консультация</strong>
					</>
				)
		}
	}

	render() {
		const { appointment = {}, onClick = () => { }, className } = this.props
		const { doctor = {}, type, price, started_at, service } = appointment
		const { speciality, last_name, first_name, middle_name } = doctor
		return (
			<div className={`appointment-info ${className || ''}`} onClick={onClick}>
				<div className='type'>{this.renderRecType(type)}</div>
				<div className='dateprice'>
					<div className='date'>
						<CalendarIcon color='#999999' /> <span>{moment(started_at).format('DD MMMM, HH:mm')}</span>
					</div>
					<div className='price'>
						<RubleIcon /> <span>{price} ₽</span>
					</div>
				</div>
				<div className='doctor'>
					<DoctorIcon /> <span>{`${last_name} ${first_name} ${middle_name} - ${speciality}`}</span>
				</div>
				<div className='service'>
					<ServiceIcon /> <span>{service}</span>
				</div>
			</div>
		)
	}
}

AppointmentInfo.propTypes = {
	appointment: PropTypes.object,
}

export default AppointmentInfo
