const initialState = {
	token: null,
	session: null,
	conference: null,
	calls: [],
	fetch: false,
	appointment_id: null,
	user: undefined,
	reason: '',
	holdon: '',
	format: '',
	started: false,
};

export const ConferenceReducer = (state = initialState, action) => {
	let index;
	switch (action.type) {
		case 'CREATE_CONFERENCE':
			return {
				...state,
				reason: '',
				fetch: true,
				started: false,
			}
		case 'INCOMING_CALL':
			if (state.session === action.params.session && state.conference === action.params.conference) {
				return state;
			}
			return { ...state, calls: [...state.calls, action.params] }
		case 'AUTO_ANSWER_CALL':
			index = state.calls.findIndex((call) => (call.session === action.params.session && call.conference === action.params.conference && call.session && call.conference));
			if (index !== -1) {
				state.calls.splice(index, 1);
			}
			return {
				...state,
				calls: [...state.calls],
				session: action.params.session,
				token: action.params.token,
				conference: action.params.conference,
				appointment_id: action.params.appointment_id,
				user: action.params.user,
				format: action.params.format,
				reason: '',
				holdon: '',
				started: false,
			};
		case 'REJOIN_CONFERENCE':
			return {
				...state,
				holdon: '',
			}
		case 'CONFERENCE_STARTED':
			if (state.session === action.payload) {
				return {
					...state,
					started: true,
				}
			}
			return state;
		case 'ANSWER_CALL':
			const call = state.calls.pop();
			state.session = call.session;
			state.token = call.token;
			state.conference = call.conference;
			state.appointment_id = call.appointment_id;
			state.user = call.user;
			state.format = call.format;
			state.reason = '';
			state.holdon = '';
			state.started = false;
			return { ...state, calls: [...state.calls] }
		case 'RECALL_USER':
			if (state.session !== action.payload) {
				return state;
			}
			return {
				...state,
				holdon: '',
			}
		case 'STOP_CALLING':
			if (state.session === action.payload && state.session) {
				return {
					...state,
					holdon: 'doctor',
				};
			}
			return state;
		case 'REJECT_CALL':
			return {
				...state,
				calls: state.calls.filter(c => c.session !== action.payload),
			};
		case 'DECLINE_CALL':
			state.calls.pop();
			return { ...state, reason: 'declined', calls: [...state.calls] }
		case 'CLEAN_TOKEN':
			if (state.session === action.payload) {
				return {
					...state,
					token: null,
				}
			}
			return state;
		case 'CLEAN_CONFERENCE_ERROR':
			return {
				...state,
				reason: '',
			}
		case 'CLEAN_CONFERENCE':
			if (!action.payload) {
				return {
					...initialState,
					started: state.started,
					calls: state.calls,
				};
			}
			return {
				...initialState,
				reason: action.payload.reason || '',
				started: state.started,
				calls: state.calls.filter(call => call.session !== action.payload),
			}
		case 'HOLDON_CONFERENCE':
			return {
				...state,
				reason: '',
				holdon: action.payload.reason,
			}
		case 'SET_NEW_TOKEN':
			if (state.session === action.payload.session) {
				return {
					...state,
					reason: '',
					holdon: '',
					token: action.payload.token,
				};
			}
			return state;
		case 'SET_ROOM_TOKEN':
			return {
				...state,
				token: action.params.token,
				session: action.params.session,
				conference: action.params.conference,
				appointment_id: action.params.appointment_id,
				user: action.params.user,
				format: action.params.format,
				reason: '',
				holdon: '',
				fetch: false,
			};
		case 'ROOM_DISCONNECT':
			return {
				...state,
				session: null,
				conference: null,
				format: '',
				appointment_id: null,
				token: null,
				user: undefined,
				reason: 'closed',
				holdon: '',
			};
		case 'persist/REHYDRATE':
			if (action.payload) return { ...state, ...action.payload.Conference }
			else return state
		default:
			return { ...state };
	}
}