import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ScreenWrapper from '../../components/ScreenWrapper'
import Webcam from 'react-webcam'
import { ReactMic } from 'react-mic'
import BButton from '../../components/BButton'

import './style.scss'

class Test extends Component {
	constructor(props) {
		super(props)
		this.state = {
			checkWebcam: false,
			checkMic: false,
		}
	}

	componentDidMount() {}

	componentDidUpdate(prevProps, prevState) {}

	startCheckMic = () => {
		this.setState({ checkMic: true })
	}

	stopCheckMic = () => {
		this.setState({ checkMic: false })
	}

	startCheckWebcam = () => {
		this.setState({ checkWebcam: true })
	}

	stopCheckWebcam = () => {
		this.setState({ checkWebcam: false })
	}

	render() {
		const { checkMic, checkWebcam } = this.state
		return (
			<ScreenWrapper style={{ display: 'flex', padding: 20 }} onBack={() => {}}>
				<div className='TestContainer'>
					<div style={{ flex: 1, marginRight: 20 }}>
						<h2>Проверка вебкамеры</h2>
						<div style={{ maxHeight: 400, minHeight: 400, background: '#333333', width: '100%' }} className='webcam'>
							{checkWebcam && (
								<Webcam
									audio={false}
									onUserMediaError={() => {
										alert('Вебкамера не найдена')
									}}
								/>
							)}
						</div>
						<div style={{ display: 'flex', marginTop: 10 }}>
							<BButton onClick={this.startCheckWebcam} style={{ flex: 1, marginRight: 10 }}>
								Начать
							</BButton>
							<BButton onClick={this.stopCheckWebcam} style={{ flex: 1 }}>
								Закончить
							</BButton>
						</div>
					</div>
					<div style={{ flex: 1 }}>
						<h2>Проверка микрофона</h2>
						<ReactMic
							className='mic'
							record={checkMic}
							onStop={this.onStop}
							strokeColor='#B12326'
							backgroundColor='#333333'
						/>
						<div style={{ display: 'flex', marginTop: 6 }}>
							<BButton onClick={this.startCheckMic} style={{ flex: 1, marginRight: 10 }}>
								Начать
							</BButton>
							<BButton onClick={this.stopCheckMic} style={{ flex: 1 }}>
								Закончить
							</BButton>
						</div>
					</div>
				</div>
			</ScreenWrapper>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Test))
