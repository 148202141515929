import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MModal from '../MModal';
import './style.scss'


class Permissions extends React.Component {
  constructor(props) {
    super(props)
    const { visible } = this.props;
    this.state = { status: null, closed: false, visible };
  }

  componentDidMount() {
    this.fetchPermission();
  }

  componentDidUpdate(prevProps) {
    const { visible } = this.props;
    if (prevProps.visible !== visible) {
      this.setState({ visible });
    }
  }

  fetchPermission = async () => {
    if (!navigator.permissions || !navigator.permissions.query) {
      return;
    }
    const { name } = this.props;
    try {
      const permission = await navigator.permissions.query({ name });
      this.setState({
        status: permission.state,
      });
    } catch (e) { }
  }

  onClose = () => {
    this.setState({ closed: true });
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  openGoogle = () => {
    window.open('https://google.com/search?q=' + 'Как разрешить браузеру доступ к камере')
  }

  render() {
    const { user, name } = this.props;
    const title = name === 'microhpone' ? 'Вы запретили доступ к микрофону' : 'Вы запретили доступ к камере';
    const { status, closed, visible } = this.state;
    const _visible = (!!status && !closed && !!user && status === 'denied') || !!visible;
    console.log({ status, closed, visible, _visible, user });
    return <MModal
      className="Permissions"
      title={title}
      onShutter={this.onClose}
      onClose={this.onClose}
      visible={(!!status && !closed && !!user && status === 'denied') || !!visible}
      closeIconColor="#fff"
    >
      <div className="PermissionDescription">
        <p>Для корректной работы аудио/видео консультаций необходимо разрешить доступ в настройках браузера</p>
        <p>Если вы не планируете пользоваться аудио/видео связью, то можете проигнорировать данное сообщение</p>
        <p className="HowTo" onClick={this.openGoogle}>Как настроить камеру?</p>
      </div>
    </MModal>
  }
}

Permissions.propTypes = {
  name: PropTypes.string,
  visible: PropTypes.bool,
}

export default connect(
  state => ({
    user: state.User.user,
  }),
  {}
)(Permissions);