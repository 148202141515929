import { all, put, takeLatest, call } from 'redux-saga/effects';
import keyMirror from './keyMirror';
import { get } from './networking';
import config from '../config'

const initialState = {
  fetch: false,
  list: [],
  error: '',
};

export const patientsTypes = keyMirror({
  PATIENTS_FETCH: null,
  PATIENTS_FETCH_ERROR: null,
  PATIENTS_FETCH_SAVE: null,
});

export const PatientsReducer = (state = initialState, action) => {
  switch (action.type) {
    case patientsTypes.PATIENTS_FETCH:
      return {
        ...state,
        fetch: true,
        error: '',
      };
    case patientsTypes.PATIENTS_FETCH_ERROR:
      return {
        ...state,
        fetch: false,
        error: action.payload.error,
      };
    case patientsTypes.PATIENTS_FETCH_SAVE:
      return {
        list: action.payload.list,
        fetch: false,
        error: '',
      };
    default:
      return state;
  }
};

export function* patientsSaga() {
  try {
    yield all([
      watchPatientsActions(),
    ]);
  } catch (e) {
    console.log('[error] patients: ', e);
  }
}

function* fetchPatientsList() {
  try {
    const response = yield call(get, { url: `${config.url}/api/patients` });
    console.log('[patients] data: ', response);
    if (response.success) {
      const { data: list } = response;
      yield put({ type: patientsTypes.PATIENTS_FETCH_SAVE, payload: { list } })
    } else {
      const { error } = response;
      yield put({ type: patientsTypes.PATIENTS_FETCH_ERROR, payload: { error } });
    }
  } catch (e) {
    console.log('[error] fetch patients: ', e);
    yield put({ type: patientsTypes.PATIENTS_FETCH_ERROR, payload: { error: e.message } });
  }
}

export function* watchPatientsActions() {
  try {
    yield takeLatest(patientsTypes.PATIENTS_FETCH, fetchPatientsList);
  } catch (e) {
    console.log('[error] watch patients saga: ', e);
  }
}