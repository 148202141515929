import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const CheckButton = (props) => {
  const {
    title, description, Icon, onClick, disabled, checked, className, iconProps,
  } = props;
  return <div className={`CheckButton ${checked ? 'checked' : ''} ${className || ''} ${disabled ? 'disabled' : ''}`} onClick={disabled ? undefined : onClick}>
    <div className="CheckButtonIcon"><Icon {...iconProps} /></div>
    <div className="CheckButtonText">
      <div className="Title">{title}</div>
      <div className="Description">{description}</div>
    </div>
  </div>
}

CheckButton.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  Icon: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  className: PropTypes.string,
  iconProps: PropTypes.shape({ color: PropTypes.string }),
};

export default CheckButton;
