import React from 'react';
import RouterNest from './router';
import './colors.scss';
import NetworkController from './utils/networkController'

NetworkController.check()
NetworkController.subscribe()

function App() {
  return (
    <RouterNest/>
  );
}

export default App;
