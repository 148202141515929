export const UploadReducer = (state = { update: 0 }, action) => {
    switch (action.type) {
        case 'COUP_UPLOADER':
            return { ...state, [action.params]: [], update: state.update + 1 }
        case 'DECOUP_UPLOADER':
            if (state[action.params]) delete state[action.params];
            return { ...state, update: state.update + 1 }
        case 'SUCCESS_CHUNK':
            state[action.params.id].push(action.params.index);
            state[action.params.id].name = action.params.name;
            return { ...state, update: state.update + 1 }
        default:
            return state;
    }
}