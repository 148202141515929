import React, { Component } from 'react';
import './ToolbarComponent.css';
import './ToolbarComponent.scss';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

// import Mic from '@material-ui/icons/Mic';
// import MicOff from '@material-ui/icons/MicOff';
// import Videocam from '@material-ui/icons/Videocam';
// import VideocamOff from '@material-ui/icons/VideocamOff';
// import Fullscreen from '@material-ui/icons/Fullscreen';
// import FullscreenExit from '@material-ui/icons/FullscreenExit';
// import PictureInPicture from '@material-ui/icons/PictureInPicture';
// import ScreenShare from '@material-ui/icons/ScreenShare';
// import StopScreenShare from '@material-ui/icons/StopScreenShare';
// import Tooltip from '@material-ui/core/Tooltip';
// import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';
// import QuestionAnswer from '@material-ui/icons/QuestionAnswer';

// import IconButton from '@material-ui/core/IconButton';

import OvTestBottomButtons from '../../../components/OvTestBottomButtons';
import OvTestCenterButtons from '../../../components/OvTestCenterButtons';

import {
    CameraActiveIcon,
    CameraInactiveIcon,
    MicrophoneActiveIcon,
    MicrophoneInactiveIcon,
    CallEndIcon,
    MicOff,
    CamOff,
    MicOn,
    CamOn,
    Phone24
} from '../../../resources/icons'

const MicrophoneIcon = ({ off }) => <div className="CallBtn" >{off ? <MicOff /> : <MicOn />}</div>
const CameraIcon = ({ off }) => <div className="CallBtn" >{off ? <CamOff /> : <CamOn />}</div>
const PhoneIcon = () => <div className="PhoneBtn CallBtn"><Phone24 /></div>

export default class ToolbarComponent extends Component {
    constructor(props) {
        super(props);
        this.state = { fullscreen: false };
        this.camStatusChanged = this.camStatusChanged.bind(this);
        this.micStatusChanged = this.micStatusChanged.bind(this);
        this.screenShare = this.screenShare.bind(this);
        this.stopScreenShare = this.stopScreenShare.bind(this);
        this.toggleFullscreen = this.toggleFullscreen.bind(this);
        this.leaveSession = this.leaveSession.bind(this);
        this.toggleChat = this.toggleChat.bind(this);
    }


    micStatusChanged() {
        try {
            this.props.micStatusChanged();
        } catch (e) {
            console.log(e);
        }
    }

    camStatusChanged() {
        try {
            this.props.camStatusChanged();
        } catch (e) {
            console.log(e);
        }
    }

    screenShare() {
        this.props.screenShare();
    }

    stopScreenShare() {
        this.props.stopScreenShare();
    }

    toggleFullscreen() {
        this.setState({ fullscreen: !this.state.fullscreen });
        this.props.toggleFullscreen();
    }

    leaveSession() {
        if (this.props.role === 'patient') {
            this.props.leaveSession();
        }
        const { onLeaveSession } = this.props;
        if (onLeaveSession) {
            onLeaveSession();
        }
    }

    toggleChat() {
        this.props.toggleChat();
    }

    render() {
        const mySessionId = this.props.sessionId;
        const localUser = this.props.user;
        const { title, centerAudio, centerChat, customButtons, test } = this.props;
        const isAudioActive = localUser && localUser.isAudioActive();
        const isVideoActive = localUser && localUser.isVideoActive();
        return (
            <AppBar className={`toolbar ${test ? 'TEST' : ''}`} id="header">
                <Toolbar className="toolbar">
                    <div id="navSessionInfo">
                        <img
                            id="header_img"
                            alt="OpenVidu Logo"
                            src="https://raw.githubusercontent.com/OpenVidu/openvidu-call/master/front/openvidu-call/src/assets/images/openvidu_logo.png"
                        />

                        {this.props.sessionId && <div id="titleContent">
                            <span id="session-title">{mySessionId}</span>
                        </div>}
                    </div>

                    {!!title && <div className="toolbarTitle">{title}</div>}
                    {!isVideoActive && !test && <div className="toolbarCenter">
                        {isAudioActive ? centerAudio : centerChat}
                    </div>}
                    {test && <div className="toolbarCenterTest">
                        <OvTestCenterButtons onRight={this.props.onViewTestRecord} onLeft={this.props.onRepeatTest} />
                    </div>}
                    <div className="buttonsContent">
                        {customButtons ? <OvTestBottomButtons
                            onMicClick={this.micStatusChanged}
                            onCamClick={this.camStatusChanged}
                            isMicActive={localUser && localUser.isAudioActive()}
                            isCamActive={localUser && localUser.isVideoActive()}
                        /> : (<>
                        <div className="MicrophoneButton" onClick={this.micStatusChanged}>
                            <MicrophoneIcon off={!(localUser !== undefined && localUser.isAudioActive())} />
                        </div>

                        <div className="CallButton" onClick={this.leaveSession}><PhoneIcon /></div>
                        <div className="CameraButton" onClick={this.camStatusChanged}>
                            <CameraIcon off={!(localUser !== undefined && localUser.isVideoActive())} />
                        </div>
                        </>)
                        }

                        {/* <IconButton color="inherit" className="navButton" onClick={this.screenShare}>
                            {localUser !== undefined && localUser.isScreenShareActive() ? <PictureInPicture /> : <ScreenShare />}
                        </IconButton> */}

                        {/* {localUser !== undefined &&
                            localUser.isScreenShareActive() && (
                                <IconButton onClick={this.stopScreenShare} id="navScreenButton">
                                    <StopScreenShare color="secondary" />
                                </IconButton>
                            )} */}

                        {/* <IconButton color="inherit" className="navButton" onClick={this.toggleFullscreen}>
                            {localUser !== undefined && this.state.fullscreen ? <FullscreenExit /> : <Fullscreen />}
                        </IconButton>
                        <IconButton color="secondary" className="navButton" onClick={this.leaveSession} id="navLeaveButton">
                            <PowerSettingsNew />
                        </IconButton>
                        <IconButton color="inherit" onClick={this.toggleChat} id="navChatButton">
                            {this.props.showNotification && <div id="point" className="" />}
                            <Tooltip title="Chat">
                                <QuestionAnswer />
                            </Tooltip>
                        </IconButton> */}
                    </div>
                </Toolbar>
            </AppBar>
        );
    }
}
