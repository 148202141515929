import React from 'react';
import {
  AvatarIcon,
  CalendarIcon,
  ClockIcon,
  DocumentIcon,
  ChatIcon,
  CameraIcon,
  MPhoneIcon,
} from '../../resources/icons';
import Button from '../../components/Button';
import {
  formatDate,
  dayDiff,
  formatTime,
  formatUserMeta,
  getAppointmentTypeData,
  minuteDiff,
  formatDuration,
  getFullName,
} from './utils';

const DayDelimiter = ({ date, prevDate, index }) => {
  if (index === 0) {
    return <div className="DayDelimiter">{dayDiff(date, prevDate) ? formatDate(date, prevDate) : 'Сегодня'}</div>;
  } else if (dayDiff(date, prevDate)) {
    return <div className="DayDelimiter">{formatDate(date)}</div>;
  }
  return null;
};

const TextItem = ({ type, Icon, isLink, href, children, ...rest }) => {
  let className;
  switch (type) {
    case 'title':
      className = 'AppointmentCardTitle';
      break;
    case 'document':
    case 'attachment':
      className = 'AppointmentCardDocument';
      break;
    case 'text':
    default:
      className = 'AppointmentCardText';
  }
  className = `${className} AppointmentCardItem`;
  return <div {...rest} className={className} >
    <div className="icon"><Icon color="currentColor" /></div>
    {isLink ? (
      <a href={href} className="text-content" target="_blank">{children}</a>
    ) : (
        <div className="text-content" >{children}</div>
      )}
  </div>;
};

const ButtonText = ({ Icon, children, badge }) => {
  return <div className="ListButtonText">
    <div className="icon"><Icon color="currentColor" /></div>
    <div className="text-content">{children}</div>
    {Boolean(badge) && <div className="badge">{badge}</div>}
  </div>
}

class List extends React.Component {
  renderAttacment = (attachment, index) => {
    const { name } = attachment;
    const { openAttachment } = this.props;
    return <TextItem
      key={index}
      type="attachment"
      Icon={DocumentIcon}
      onClick={() => openAttachment(attachment)}
      isLink
      href={`/${attachment.path}`}
    >
      {name}
    </TextItem>
  }

  isActiveChat = (item, index) => {
    const {
      startAppointment,
      activeIndex,
      activeItems = [],
      activeChatRoomAppointmentId,
    } = this.props;
    const appointment_id = item.direction_service_id || item.appointment_id
    if (startAppointment) {
      return activeChatRoomAppointmentId == appointment_id
    }
    const isActiveChat = activeIndex === index && activeItems.includes('chat');
    return isActiveChat
  }

  renderButtons = (item, index) => {
    const {
      startAppointment,
      toggleChat,
      toggleMedia,
      activeIndex,
      activeItems = [],
      rooms,
    } = this.props;
    const { type } = item;
    const isActiveChat = activeIndex === index && activeItems.includes('chat');
    const appointment_id = item.direction_service_id || item.appointment_id
    const chat = rooms && rooms[appointment_id] || {}
    const unread = chat.unread || 0
    if (startAppointment) {
      // Кнопки для "Предстоящие записи"
      const { datetime: startTime = item.started_at } = item;
      const buttonDisabled = false; // minuteDiff(startTime) > 30;
      const isChatActive = this.props.activeChatRoomAppointmentId == appointment_id
      return <>
        <Button
          title={'Начать приём'}
          className={`StartAppointment ${buttonDisabled ? 'disabled' : ''}`}
          onClick={() => startAppointment(item)}
          disabled={buttonDisabled}
        />
        <Button
          className={`ChatButton PastButton`}
          onClick={() => this.props.onChat(appointment_id)}
          title={(
          <ButtonText Icon={ChatIcon} badge={unread}>Открыть чат</ButtonText>
          )}
        />
      </>
    }
    // Кнопки для "Прошедшие записи"
    const isActiveMedia = activeIndex === index && activeItems.includes('media');
    let MediaIcon = () => null;
    let MediaText = 'None';
    // Функционал "Прослушать"/"Посмотреть" пока отсутствует, поэтому закомментил
    // switch (type) {
    //   case 'audio':
    //     MediaIcon = MPhoneIcon;
    //     MediaText = 'Прослушать разговор';
    //     break;
    //   case 'video':
    //     MediaIcon = CameraIcon;
    //     MediaText = 'Посмотреть запись';
    //     break;
    //   default:
    //     MediaIcon = () => null;
    //     MediaText = 'None';
    // }
    return <>
      <Button
        className={`ChatButton PastButton`}
        onClick={() => {
          if (this.props.onChat) {
            this.props.onChat(appointment_id)
          } else if (isActiveChat) {
            this.props.chatRoomInActiveSet(appointment_id)
          } else {
            this.props.chatRoomActiveSet(appointment_id)
          }
          toggleChat(index)
        }}
        title={<ButtonText Icon={ChatIcon} badge={unread}>Открыть чат</ButtonText>}
      />
      {type !== 'chat' && MediaText !== 'None' && <Button
        className={`MediaButton PastButton ${isActiveMedia ? 'active' : ''}`}
        onClick={() => toggleMedia(index)}
        title={<ButtonText Icon={MediaIcon}>{MediaText}</ButtonText>}
      />}
    </>
  }

  renderItem = (item, index, list) => {
    const prevItem = list[index - 1] || {};
    const { datetime: prevDate = prevItem.started_at } = prevItem;
    const { type, datetime: startTime = item.started_at, patient: user, attachments, duration } = item;
    const { description, Icon } = getAppointmentTypeData(type);
    const isActiveChat = this.isActiveChat(item, index)
    return <div className={`ListItem ${isActiveChat ? 'active' : ''}`} key={index}>
      <DayDelimiter date={startTime} prevDate={prevDate} index={index} />
      <div className="ItemContent">
        <div className="left">
          <TextItem type="title" Icon={Icon}>{description}</TextItem>
          <TextItem Icon={CalendarIcon}>{`Начало - ${formatTime(startTime)}`}</TextItem>
          <TextItem Icon={AvatarIcon}>{getFullName(user)}</TextItem>
          <TextItem Icon={AvatarIcon}>{formatUserMeta(user)}</TextItem>
          {duration && <>
            <TextItem Icon={ClockIcon}>{`Продолжительность - ${formatDuration(duration)}`}</TextItem>
            <div style={{ display: 'none' }} />
          </>}
          <>{attachments && attachments.map(this.renderAttacment)}</>
        </div>
        <div className="right">
          {this.renderButtons(item, index)}
        </div>
      </div>
      <div className={`delimiter ${(index === list.length - 1) ? 'last' : ''}`} />
    </div>
  }

  render() {
    const { data } = this.props;
    return <div className="ListContainer">
      {data.map(this.renderItem)}
    </div>
  }
}

export default List;