import { connect } from 'react-redux'
import OvTestBottomButtons from './OvTestBottomButtons'
import * as connectionTest from '../../reducers/connectionTest/types'

export default connect(
  state => ({
    user: state.User,
    test: state.ConnectionTest,
  }),
  {
    startTestRecord: () => ({ type: connectionTest.CONNECTION_TEST_RECORD_START }),
    stopTestRecord: () => ({ type: connectionTest.CONNECTION_TEST_RECORD_STOP }),
    stopConnectionTest: () => ({ type: connectionTest.CONNECTION_TEST_STOP }),
  }
)(OvTestBottomButtons)
