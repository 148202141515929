import React from 'react';
import { connect } from 'react-redux';
import './style.scss';
import events from '../../reducers/events';

import Input from '../Input';
import FileUpload from '../FileUpload';
import moment from 'moment';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import { formatName } from '../../screens/DoctorsDiary/utils';

import config from '../../config';

import { SendMessage, AddFile, AttachPin, CloseIcon } from '../../resources/icons';
import ChatActivity from './Activity';
import uploadFile from '../../utils/uploadFile';

class Chat extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			text: '',
			messageslength: 0,
			fileInputRef: null,
			fileProgress: 1,
			virtualSelect: 0,
		}

		this.ref = React.createRef();
		this.onFileMessage = debounce(this.onFileMessage, 500);
		events.on('CHAT_MESSAGE', this.onChatMessage);
		this.sendActivity = throttle(this.sendActivity, 500);
	}

	componentDidMount() {
		if (this.props.room) this.props.initChat(this.props.room);
		this.ref.current.scrollTo({
			top: this.ref.current.scrollHeight,
			behavior: 'smooth'
		});
		this.props.getPresets();
		events.on('CHAT_MESSAGE_DELETED', this.onChatMessageDeleted);
	}

	componentWillUnmount() {
		if (this.props.room) this.props.unmountChat(this.props.room);
		events.off('CHAT_MESSAGE', this.onChatMessage);
		events.off('CHAT_MESSAGE_DELETED', this.onChatMessageDeleted);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.room !== this.props.room) {
			if (prevProps.room) this.props.unmountChat(this.props.room);
			if (this.props.room) this.props.initChat(this.props.room);
		}
		if (this.ref.current.scrollTop == 0 && this.ref.current.scrollHeight) {
			this.scrollToBottom()
		}
		if (this.state.messageslength !== (this.props.chats[this.props.room] || []).length) {
			this.setState({ messageslength: (this.props.chats[this.props.room] || []).length }, () => {
				if (this.ref.current.scrollHeight - this.ref.current.scrollTop - 300 < this.ref.current.offsetHeight) {
					this.ref.current.scrollTo({
						top: this.ref.current.scrollHeight,
						behavior: 'smooth'
					});
				}
			})
		}
	}

	onChatMessage = (message) => {
		const { room } = this.props;
		if (room == message.room) {
			const file = message.body.match(/\[file\](.*)\[\/file\]/i);
			if (file) {
				this.onFileMessage();
			}
		}
	}

	onChatMessageDeleted = (message) => {
		const { room } = this.props;
		if (room === message.room) {
			const file = message.body.match(/\[file\](.*)\[\/file\]/i);
			if (file) {
				this.onFileMessageDeleted();
			}
		}
	}

	sendMessage = () => {
		if (!this.state.text || this.state.text === '') return
		this.props.sendMessage({ text: this.state.text, room: this.props.room });
		this.setState({ text: '', virtualSelect: 0 });
	}

	getPresets = () => {
		if (!this.state.text || this.state.text === '') return
		const validpresets = this.props.presets.filter(preset => preset.text.includes(this.state.text));
		if (validpresets.length) {
			return <div className='PresetBox'>
				{validpresets.map((preset, index) => <div className={`PresetItem ${this.state.virtualSelect===index+1?'Selected':''}`} onClick={() => this.setState({ text: preset.text })}>
					{preset.text.replace(this.state.text, '[part]' + this.state.text + '[part]').split('[part]').map((part, index) => {
						if (index === 1) {
							return <span>{part}</span>
						} else {
							return <>{part}</>
						}
					})}
				</div>)}
			</div>
		}
	}

	onFileMessage = () => {
		const { getAttchments, appointment_id, onFileMessage } = this.props;
		if (getAttchments && appointment_id) {
			this.props.getAttchments({ appointment_id });
		}
		if (onFileMessage) {
			onFileMessage();
		}
	}

	onFileMessageDeleted = () => {
		const { getAttchments, appointment_id, onFileMessageDeleted } = this.props;
		if (getAttchments && appointment_id) {
			this.props.getAttchments({ appointment_id });
		}
		if (onFileMessageDeleted) {
			onFileMessageDeleted();
		}
	}


	onFileUpload = (filename, originalName) => {
		const { addAttachment, appointment_id, onFileUpload, room } = this.props;
		if (addAttachment && appointment_id) {
			addAttachment({ path: filename, name: originalName, appointment_id, conference: room });
		}
		if (onFileUpload) {
			onFileUpload(filename, originalName);
		}
	}

	/**
	 * @param {File} file
	 * @param {(error?: any) => void} [callback]
	 */
	_uploadFile = (file, callback) => {
		const { appointment_id, onFileUpload, room } = this.props;
		uploadFile({
			appointment_id,
			conference_id: room,
			file,
			onDone: (name) => {
				onFileUpload(name, file.name)
				callback && callback(null)
			},
			onError: (e) => {
				callback && callback(e)
			}
		})
	}

	onChangeText = (text) => {
		this.setState({ text, virtualSelect: 0 }, this.sendActivity);
	}

	sendActivity = () => {
		this.props.sendActivity(this.props.room);
	}

	scrollToBottom = () => {
		this.messageEnd.scrollIntoView({ behavior: 'smooth' })
	}

	render() {
		const { room, activity } = this.props;
		const activeUser = activity[0];
		return <div className='ChatWrapper'>
			<div className='Header'>
				{this.props.title && <div className="ChatTitle">{this.props.title}</div>}
				{this.props.onClose && <div className="ChatClose" onClick={this.props.onClose}><CloseIcon color="white" /></div>}
			</div>
			<div className='Messages' ref={this.ref}>
				{(this.props.chats[this.props.room] || []).map((message, index) => <div key={message.chat_message_id} className={`Message ${this.props.user.user_id === message.user_id ? 'My' : 'Other'}`}>
					<div className='author'>{formatName(message)}</div>
					<div className='body'>
						{(() => {
							const file = message.body.match(/\[file\](.*)\[\/file\]/i);
							if (file) {
								const parts = message.body.split(file[0]);
								return <>{parts[0]}<a href={config.url + '/' + file[1]} target='_blank' rel="noopener noreferrer">{file[1]}<AddFile /></a>{parts[1]}</>
							} else {
								return message.body
							}
						})()}
					</div>
					<div className='timestamp'>{moment(message.inserted_at).format('hh:mm')}</div>
				</div>)}
				<div ref={ref => { this.messageEnd = ref }}/>
			</div>
			<ChatActivity user={activeUser} />
			<div className='Control'>
				<div className='UploadProgressBarContainer'>
					{this.state.fileProgress !== 1 && <div className='UploadProgressBar' style={{ right: this.state.fileProgress !== 1 ? `${(1 - this.state.fileProgress) * 100}%` : `100%` }} />}
				</div>
				{this.getPresets()}
				{console.log(this.state.virtualSelect)}
				<Input
					onEnter={this.state.virtualSelect === 0 ? this.sendMessage : () => this.setState({ virtualSelect: 0, text: this.props.presets.filter(preset => preset.text.includes(this.state.text))[this.state.virtualSelect-1].text })}
					onChange={this.onChangeText}
					value={this.state.text}
					title={'Введите сообщение'}
					onDown={() => this.setState({ virtualSelect: Math.min(this.props.presets.filter(preset => preset.text.includes(this.state.text)).length, this.state.virtualSelect+1) })}
					onUp={() => this.setState({ virtualSelect: Math.max(0, this.state.virtualSelect-1) })}
				/>
				<div
					className='Attach'
					onClick={() => {
						if (this.state.fileProgress !== 1) return
						this.state.fileInputRef.current.click();
					}}>
					<AttachPin />
					<FileUpload
						onProgress={data => this.setState({ fileProgress: data.progress })}
						onUpload={this.onFileUpload}
						onUploadFile={this._uploadFile}
						passRef={fileInputRef => this.setState({ fileInputRef })} />
				</div>
				<div className='Send' onClick={this.sendMessage}><SendMessage /></div>
			</div>
		</div>
	}
}

export default connect((state, ownProps) => (
	{
		user: state.User.user,
		chats: state.Chats,
		update: state.Chats.update,
		presets: state.Chats.presets,
		activity: state.Chats.activity[ownProps.room] || [],
	}),
	{
		sendMessage: params => ({ type: 'SEND_MESSAGE', params }),
		initChat: id => ({ type: 'COUP_CHAT', params: id }),
		unmountChat: id => ({ type: 'DECOUP_CHAT', params: id }),
		getAttchments: params => ({ type: 'ATTACHMENTS_GET', params }),
		addAttachment: (params) => ({ type: 'ATTACHMENT_ADD', params }),
		getPresets: () => ({ type: 'GET_PRESETS' }),
		sendActivity: (room) => ({ type: 'SEND_CHAT_ACTIVITY', params: { room } }),
	},
)(Chat);