import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ScreenWrapper from '../../components/ScreenWrapper'
import Button from '../../components/BButton'
import FileUpload from '../../components/FileUpload'
import Timer from '../../components/Timer'
import AttachmentList from '../../components/AttachmentList'
import AppointmentInfo from '../../components/AppointmentInfo'
import './style.scss'
import moment from 'moment'
import 'moment/locale/ru'
moment().locale('ru')

class Appointment extends Component {
	constructor(props) {
		super(props)
		this.state = {
			fileInputRef: undefined,
			uploading: false,
		}
	}

	componentDidMount() {
		const { appointment_id } = this.props.location.state || {}
		if (!appointment_id) {
			this.props.history.replace('/PatientDiary')
		} else {
			this.props.getAppointment({ appointment_id })
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { attachments, location } = this.props
		const { appointment_id } = location.state || {}
		if (prevProps.attachments.fetching && !attachments.fetching) {
			this.props.getAppointment({ appointment_id })
		}
	}

	chooseFile = () => {
		const { fileInputRef } = this.state
		fileInputRef.current.click()
	}

	onProgress = ({ progress }) => {
		if (progress !== 1) {
			this.setState({ uploading: true })
		}
	}

	onUpload = (path, name) => {
		const { appointment_id } = this.props.location.state || {}
		this.setState({ uploading: false })
		this.props.addAttachment({ path, name, appointment_id })
	}

	onDelete = (file) => {
		this.props.delAttachment(file)
	}

	createPayment = (appointment) => {
		const { createPayment } = this.props;
		console.log('createPayment.appointment: ', typeof createPayment, appointment)
		createPayment({
			amount: appointment.price * 100,
			direction_service_id: appointment.direction_service_id,
		});
	}

	onTimeout = (appointment) => {
		console.log('onTimeout.appointment: ', appointment);
		const { direction_service_id } = appointment;
		const { timedOut = [] } = this.state;
		this.setState({
			timedOut: [...timedOut, direction_service_id],
		}, () => {
			const { attachments } = appointment;
			for (const att of attachments) {
				this.props.delAttachment(att);
			}
		});
	}

	renderDescription = () => {
		const { appointment } = this.props
		const { started_at } = appointment
		const time = moment(started_at).format('HH:mm')
		const date = moment(started_at).format('DD MMMM')
		const weekday = moment(started_at).format('dddd').toLocaleLowerCase()
		return (
			<div className='description'>
				<p>
					<b>{`В ${time}, ${date}, ${weekday}`}</b> - доктор свяжется с Вами. Если вы хотите показать врачу результаты
					предыдущих исследований можете приложить их в этой форме заранее.
				</p>
			</div>
		)
	}

	renderAppointment = () => {
		const { uploading } = this.state
		const { appointment, fetching } = this.props
		const { attachments = [], paid } = appointment
		return (
			<div className='appointment'>
				<div className='container'>
					<AppointmentInfo appointment={appointment} />
					<AttachmentList type='rows' attachments={attachments} onDelete={this.onDelete} />
					<div className='buttons'>
						<Button
							className='attach'
							color='pink'
							loading={uploading || fetching}
							onClick={() => {
								this.chooseFile(appointment)
							}}>
							Прикрепить
						</Button>
						{!paid && (
							<Button loading={uploading || fetching} className='pay' onClick={() => this.createPayment(appointment)}>
								Оплатить ({<Timer until={+moment.utc(appointment.inserted_at || Date.now()).toDate() + 20 * 60 * 1000} onTimeout={() => this.onTimeout(appointment)} />})
							</Button>
						)}
					</div>
				</div>
			</div>
		)
	}

	render() {
		const { appointment } = this.props
		return (
			<ScreenWrapper
				style={{ display: 'flex', padding: 0, maxWidth: 882 }}
				onBack={() => {
					this.props.history.replace('/PatientDiary')
				}}>
				<div className='appointment'>
					<h2>Запись на прием</h2>
					<div className='banner'></div>
					{!!appointment && (
						<>
							{this.renderDescription()}
							<div className='container'>
								{this.renderAppointment()}
								<div style={{ display: 'none' }}>
									<FileUpload
										onProgress={this.onProgress}
										onUpload={this.onUpload}
										passRef={(fileInputRef) => this.setState({ fileInputRef })}
									/>
								</div>
							</div>
						</>
					)}
				</div>
			</ScreenWrapper>
		)
	}
}

const mapStateToProps = (state) => ({
	appointment: state.Appointments.appointment,
	attachments: state.Attachments,
	fetching: state.Attachments.fetching || state.Appointments.fetch,
})

const mapDispatchToProps = (dispatch) => ({
	addAttachment: (params) => dispatch({ type: 'ATTACHMENT_ADD', params }),
	delAttachment: (params) => dispatch({ type: 'ATTACHMENT_DEL', params }),
	getAppointment: (params) => dispatch({ type: 'PATIENT_APPOINTMENT_GET', params }),
	createPayment: params => dispatch({ type: 'PAYMENT_CREATE', params }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Appointment))
