import { call, put } from 'redux-saga/effects';
import { api } from './networking'
import config from '../config'

export const initialTimerState = {
  diftime: 0,
  fetching: false,
  error: null,
}

/** @typedef {{ diftime: number; fetching: boolean; error: string | null | Error }} ITimerState */
/** @typedef {{ type: string; params?: any; payload?: any; error?: any }} IAction */

/**
 * @param {ITimerState} state
 * @param {IAction} action
 * @return {ITimerState}
 */
export const TimerReducer = (state = initialTimerState, action) => {
  switch (action.type) {
    case 'TM_DIFTIME_FETCH':
      return {
        ...state,
        fetching: true,
        error: null,
      }
    case 'TM_DIFTIME_SET':
      return {
        ...state,
        fetching: false,
        error: null,
        diftime: action.params,
      }
    case 'TM_DIFTIME_ERROR':
      return {
        ...state,
        fetching: false,
        error: action.error || action.params,
      }
    default:
      return state;
  }
}

export function* getDiftime() {
  try {
    const result = yield call(api.get, { url: `${config.url}/api/diftime?t=${Date.now()}` });
    if (result.diftime !== undefined) {
      yield put({ type: 'TM_DIFTIME_SET', params: result.diftime });
    } else {
      yield put({ type: 'TM_DIFTIME_ERROR', params: result.error || 'unknown' });
    }
  } catch (e) {
    console.log('getDiftime error', e);
    yield put({ type: 'TM_DIFTIME_ERROR', error: e.message });
  }
}
