import { connect } from 'react-redux';
import ModalAppointmentConfirmation from './ModalAppointmentConfirmation';

const _1day = 24 * 3600 * 1000;
const fakeAppointments = [{
  direction_service_id: 1,
  started_at: new Date(Date.now() + 10 * 60000).toISOString(),
}, {
  direction_service_id: 4,
  started_at: new Date(Date.now() + _1day - 3600 * 1000).toISOString(),
}, {
  direction_service_id: 2,
  started_at: new Date(Date.now() + _1day + 6 * 60000).toISOString(),
}, {
  direction_service_id: 3,
  started_at: new Date(Date.now() + 39 * _1day).toISOString(),
}];

export default connect(
  state => ({
    user: state.User.user,
    appointments: state.Appointments.data,
    session: state.User.session,
    conference: state.Conference,
  }),
  {
    fetchAppointments: () => ({ type: 'PATIENT_APPOINTMENTS_GET' }),
  }
)(ModalAppointmentConfirmation);