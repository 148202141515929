import { post } from './networking';
import { call, put } from 'redux-saga/effects';
import config from '../config';

export const PaymentReducer = (state = { formUrl: null, fetch: false, error: null }, action) => {
	switch (action.type) {
		case 'CREATE_PAYMENT':
		case 'PAYMENT_CREATE':
			return { ...state, fetch: true };
		case 'SET_FORMURL':
			return { ...state, formUrl: action.params, fetch: false };
		case 'ERROR_PAYMENT':
			return { ...state, error: action.params, fetch: false }
		case 'RESET_PAYMENT':
			return { ...state, formUrl: null, fetch: false };
		default:
			return { ...state };
	}
}

export function* _CreatePayment({ params }) {
	let bonus_params = {}
	if (params.auth_token || params.bonus_code || params.bonus_points) {
		bonus_params = {
			bonus_points: params.bonus_points,
			auth_token: params.auth_token,
			bonus_code: params.bonus_code,
		}
	}
	const result = yield call(post, {
		url: `${config.url}/api/v2/ecom_create`,
		data: {
			amount: params.amount,
			url: config.url+'/PatientDiary',
			direction_service_id: params.direction_service_id,
			...bonus_params,
		},
	});
	if (result.success) {
		yield put({ type: 'SET_FORMURL', params: result.formUrl });
	} else {
		yield put({ type: 'ERROR_PAYMENT' })
		yield put({ type: 'ERROR_PUSH', params: result.error })
	}
}
