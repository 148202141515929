/** @typedef {{
 *    sessionId: string | null;
 *    token: string | null;
 *    secondToken: string | null;
 *    starting: boolean;
 *    stopping: boolean;
 *    stopped: boolean;
 *    error: Error | string | null;
 *    maxDuration: number | null;
 *    insertedAt: number | null;
 *    record: {
 *      stopping: boolean;
 *      starting: boolean;
 *      error: Error | string | null;
 *      startTime: number | null;
 *      recordingId: string | null;
 *      recordingUrl: string | null;
 *      maxDuration: number | null;
 *    };
 * }} IConnectionTestState
 * 
 * @typedef {{
 *    type: string;
 *    payload?: any;
 *    error?: any;
 * }} IAction
 */

/** @type {IConnectionTestState} */
const initState = {
  sessionId: null,
  token: null,
  secondToken: null,
  starting: false,
  stopping: false,
  stopped: true,
  error: null,
  maxDuration: 10 * 60 * 1000,
  insertedAt: null,
  record: {
    stopping: false,
    starting: false,
    error: null,
    startTime: null,
    recordingId: null,
    recordingUrl: null,
    maxDuration: null,
  },
}

export default initState;