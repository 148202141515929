
/**
 * @param {import('./incomingCalls.reducer').CallItem} item
 * @returns {import('./outcomingCall.reducer').OutcomingCallAction}
 */
export const outcomingCallStarted = (item) => ({ type: 'OUTCOMING_CALL_STARTED', payload: item })

/**
 * @param {import('./incomingCalls.reducer').CallItem} item
 * @returns {import('./outcomingCall.reducer').OutcomingCallAction}
 */
export const outcomingCallStopped = (item) => ({ type: 'OUTCOMING_CALL_STARTED', payload: item })

/**
 * @param {import('./incomingCalls.reducer').CallItem} item
 * @returns {import('./outcomingCall.reducer').OutcomingCallAction}
 */
export const outcomingCallFailed = (item) => ({ type: 'OUTCOMING_CALL_FAILED', payload: item })

/**
 * @param {import('./incomingCalls.reducer').CallItem} item
 * @returns {import('./outcomingCall.reducer').OutcomingCallAction}
 */
export const outcomingCallAnswered = (item) => ({ type: 'OUTCOMING_CALL_ANSWERED', payload: item })

/**
 * @param {import('./incomingCalls.reducer').CallItem} item
 * @returns {import('./outcomingCall.reducer').OutcomingCallAction}
 */
export const outcomingCallDeclined = (item) => ({ type: 'OUTCOMING_CALL_DECLINED', payload: item })

/**
 * @param {import('./incomingCalls.reducer').CallItem} item
 * @returns {import('./outcomingCall.reducer').OutcomingCallAction}
 */
export const outcomingCallError = (item) => ({ type: 'OUTCOMING_CALL_ERROR', payload: item })

/**
 * @returns {import('./outcomingCall.reducer').OutcomingCallAction}
 */
export const outcomingCallErrorRemove = () => ({ type: 'OUTCOMING_CALL_ERROR_REMOVE', payload: null })

/**
 * @returns {import('./outcomingCall.reducer').OutcomingCallAction}
 */
export const outcomingCallStoppedOnDisconnect = () => ({ type: 'OUTCOMING_CALL_STOPPED_ON_DISCONNECT', payload: null })