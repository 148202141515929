import React from 'react';
import { connect } from 'react-redux';
import {
	withRouter,
} from "react-router-dom";
import './style.scss';
import ScreenWrapper from '../../components/ScreenWrapper';
import Button from '../../components/Button';
import config from '../../config';

class ConferenceSummary extends React.Component {
	componentDidMount() {
		this.props.showNavigation()
	}

	render() {
		return <ScreenWrapper className='ConferenceSummaryScreen'>
			<h2>Прием завершен!</h2>
			{(() => {
				switch(this.props.user.role) {
					case 'doctor':
						return <>
							<p style={{ marginBottom: 50 }}>Не забудьте заполнить отчет в МИС!</p>
							<Button onClick={() => this.props.history.push('/DoctorsDiary')} title={'Закрыть'}/>
						</>
					case 'patient':
					default:
						return <>
							<p>Спасибо за выбор нашей клиники! Ваше заключение появится в течение <b>10 минут</b> в разделе <b>"Медкарта"</b></p>
							{this.props.currentDoctor && <Button onClick={() => this.props.history.push('/DoctorRating')} title={'Оценить врача'}/>}
							<Button onClick={() => (window.location = `${config.lkurl}/menu/main/medcard/visits`)} title={'Медкарта'} className='inverted'/>
						</>
				}
			})()}
		</ScreenWrapper>
	}
}

export default withRouter(connect(state => (
	{
		user: state.User.user,
		currentDoctor: state.Doctors.currentDoctor,
	}),
	{
	},
)(ConferenceSummary));