import React from 'react'
import PropTypes from 'prop-types'
import BButton from '../BButton'
import Input from '../Input'

import './style.scss'
import BonusItem from './BonusItem'

class BonusPayment extends React.Component {
  constructor(props) {
    super(props)
    this.state = { bonus_code: '' }
  }

  componentDidUpdate(prevProps) {
    const { bonus } = this.props
    if (!bonus.points.isFetching && prevProps.bonus.points.isFetching && !bonus.points.error) {
      this.setState({ isReady: true, bonus_points: this.getAvailablePoints() })
    }
  }

  onAccept = () => {
    const { onAccept } = this.props
    const { bonus_code } = this.state
    onAccept && onAccept(bonus_code)
  }

  onDecline = () => {
    const { onDecline } = this.props
    onDecline && onDecline()
  }

  /** @param {string} text */
  onChangeBonusCode = (text) => {
    if (text.length <= 6 && (!text || this.isNumber(text))) {
      this.setState({ bonus_code: text, isReady: false })
    }
  }

  /** @param {string} text */
  isNumber = (text = '') => {
    return /^\d+$/.test(text.toString())
  }

  isValidCode = (bonus_code) => {
    return this.isNumber(bonus_code) && bonus_code.toString().length == 6
  }

  resetBonusPoints = () => {
    this.setState({ bonus_points: 0 })
  }

  onChangeBonusPoints = (text) => {
    if (text && !this.isNumber(text)) {
      return
    }
    const points = parseInt(text || 0, 10)
    const total_points = this.getTotalPoints()
    if (points > total_points) {
      return
    }
    const available_points = this.getAvailablePoints()
    if (points > available_points) {
      return
    }
    this.setState({
      bonus_points: points,
    })
  }

  getAvailablePoints = () => {
    const { price } = this.props
    const max_percent = this.getMaxPercent()
    const total_points = this.getTotalPoints()
    const max_points = price * max_percent / 100
    const available_points = Math.min(total_points, max_points)
    return Math.floor(available_points)
  }

  getMaxPercent = () => {
    const { bonus } = this.props
    try {
      return bonus.settings.data.data.settings.max_percent || 0
    } catch (e) {
      return 0
    }
  }

  getTotalPoints = () => {
    const { bonus } = this.props
    try {
      return Math.round(bonus.points.data.data.points.amount || 0)
    } catch (e) {
      return 0
    }
  }

  render() {
    const { bonus, price } = this.props
    const {
      bonus_code,
      isReady,
      bonus_points,
    } = this.state
    const totalPoints = this.getTotalPoints()
    const availablePoints = this.getAvailablePoints()
    return <div className="bonus-content">
      <div className="bonus-title">Введите код из мобильного приложения</div>
      <div className="bonus-code">
        <Input
          className="bonus-input"
          type="text"
          maxLength={6}
          value={bonus_code}
          onChange={this.onChangeBonusCode}
        />
        <BButton
          className="bonus-code-apply"
          onClick={this.onAccept}
          loading={bonus.points.fetching || bonus.settings.fetching}
          disabled={!this.isValidCode(bonus_code) || isReady}
        >
          {'Применить'}
        </BButton>
      </div>
      {!isReady
      && <>
        <div className="bonus-instruction">
          <span>В мобильном приложении зайдите в раздел "Бонусная программа", нажмите "Показать QR-код" и введите 6 цифр.</span>
        </div>
        <div className="bonus-button-decline">
          <div className="bonus-button-inverse" onClick={this.onDecline}>Пропустить</div>
        </div>
      </>}
      {Boolean(isReady)
      && <div className="bonus-payment">
        <BonusItem
          name="Всего баллов"
          value={totalPoints}
        />
        <BonusItem
          name="Сумма к оплате"
          value={`${price} ₽`}
        />
        <BonusItem
          name="Доступно к списанию"
          value={availablePoints}
        />
        <div className="bonus-max-percent">{`Оплата баллами возможна не более ${this.getMaxPercent()}% от общей суммы`}</div>
        <div className="bonus-use-points">Использовать баллы</div>
        <div className="bonus-points">
          <Input
            className="bonus-input"
            value={bonus_points || ''}
            onChange={(points) => {
              this.onChangeBonusPoints(points)
            }}
          />
        </div>
        <div className="bonus-button-reset">
          <div className="bonus-button-inverse" onClick={this.resetBonusPoints}>Не использовать баллы</div>
        </div>
        <div className="bonus-final-price">
          <BonusItem
            name="Итого к оплате"
            value={`${price - (bonus_points || 0)} ₽`}
          />
        </div>
        <BButton
          className="bonus-button-pay"
          onClick={() => {
            this.props.onPay(bonus_points)
          }}
        >
          {'Оплатить'}
        </BButton>
      </div>}
    </div>
  }
}

BonusPayment.propTypes = {
  onAccept: PropTypes.func,
  onDecline: PropTypes.func,
  onPay: PropTypes.func,
  isReady: PropTypes.bool,
  bonus: PropTypes.shape(),
  price: PropTypes.number,
}

export default BonusPayment