import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import BonusPayment from '../BonusPayment/BonusPayment'
import Modal from '../MModal'
import { bonusPointsRequest, bonusSettingsRequest } from '../../reducers/bonus'

class BonusPaymentModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = { }
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    const { visible } = this.props
    console.log('bonus modal', this.props)
    if (visible && !prevProps.visible) {
      this.props.bonusSettingsRequest()
    }
  }

  /** @param {number} bonus_points */
  gotoPaymentWithBonus = (bonus_points) => {
    const { user } = this.props
    const { bonus_code } = this.state
    const params = {
      auth_token: user.medic_token,
      bonus_points: Math.floor(bonus_points),
      bonus_code,
    }
    this.props.gotoPayment(params)
  }

  gotoPaymentWithoutBonus = () => {
    this.props.gotoPayment()
  }

  render() {
    const { visible, bonus, price } = this.props
    return <Modal
      title="Бонусная программа"
      visible={visible}
      className="bonus-modal"
    >
      <BonusPayment
        bonus={bonus}
        price={price}
        onAccept={(bonus_code) => {
          console.log('bonus_code', bonus_code)
          this.props.bonusPointsRequest(bonus_code)
          this.setState({ bonus_code })
        }}
        onDecline={() => {
          this.gotoPaymentWithoutBonus()
        }}
        onPay={(bonus_points) => {
          this.gotoPaymentWithBonus(bonus_points)
        }}
      />
    </Modal>
  }
}

BonusPaymentModal.propTypes = {
  visible: PropTypes.bool,
  bonusSettingsRequest: PropTypes.func,
  bonusPointsRequest: PropTypes.func,
  gotoPayment: PropTypes.func,
  price: PropTypes.number,
  user: PropTypes.shape(),
}

export default connect(
  (state) => ({
    user: state.User.user,
    bonus: state.Bonus,
  }), {
    bonusPointsRequest,
    bonusSettingsRequest,
  }
)(BonusPaymentModal)
