import React from 'react'
import PropTypes from 'prop-types'

const BonusItem = (props) => {
  const { name, value, style, nameTextStyle, valueTextStyle } = props
  return <div style={style} className="bonus-item">
    <div className="bonus-item-name" style={nameTextStyle}>{name}</div>
    <div className="bonus-item-value" style={valueTextStyle}>{value}</div>
  </div>
}

BonusItem.propTypes = {
  name: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  value: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  style: PropTypes.shape(),
  nameTextStyle: PropTypes.shape(),
  valueTextStyle: PropTypes.shape(),
}

export default BonusItem