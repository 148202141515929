/**
 * @param {Object.<string,any>} options
 * @return {Object.<string, string>}
 */
const keyMirror = options => Object.keys(options)
  .reduce((acc, key) => {
    acc[key] = key;
    return acc;
  }, {});

export default keyMirror;