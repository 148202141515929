import React from 'react';
import { connect } from 'react-redux';
import {
	withRouter,
} from "react-router-dom";

import ScreenWrapper from '../../components/ScreenWrapper';
import Button from '../../components/Button';
import Input from '../../components/Input';

import { TrashIcon } from '../../resources/icons';

import './style.scss';

class Presets extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			text: null,
			index: null,
			editing: false,
		};
	}

	componentDidMount() {
		this.props.GetPresets();
	}

	handleSend = () => {
		this.props.AppendPreset({ id: this.state.index, text: this.state.text });
		this.setState({ text: null, index: null, editing: false });
	}

	render() {
		return <ScreenWrapper className={'PresetsScreen'}>
			<h1>Шаблоны</h1>
			<div className='EditBox'>
				<Input
					placeholder={'Введите новый шаблон'}
					onChange={text => this.setState({ text })}
					onEnter={this.handleSend}
					value={this.state.text}
					/>
				<Button
					title={ this.state.editing ? 'Обновить' : 'Сохранить' }
					onClick={this.handleSend}
					/>
			</div>
			<div className='PresetList'>
				{(this.props.presets || []).map(preset => <>
					<div key={preset.id} className='PresetItem'>
						<div className='Content'>{preset.text}</div>
						{this.state.editing && this.state.index === preset.id ?
							<div className='EditLink Cancel' onClick={() => this.setState({ editing: false, index: null, text: null })}>Отменить</div>
							:
							<div className='EditLink' onClick={() => this.setState({ editing: true, index: preset.id, text: preset.text })}>Изменить</div>
						}
						<div className='Delete' onClick={() => this.props.DetachPreset({ id: preset.id })}>
							<TrashIcon color={'var(--icons)'}/>
						</div>
					</div>
				</>)}
			</div>
		</ScreenWrapper>
	}
}

export default withRouter(connect(state => (
    {
		presets: state.Chats.presets,
    }),
    {
		AppendPreset: params => ({ type: 'APPEND_PRESET', params }),
		DetachPreset: params => ({ type: 'DETACH_PRESET', params }),
		GetPresets: () => ({ type: 'GET_PRESETS' }),
    },
)(Presets));