import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import events from '../../reducers/events';
import sound from './sound'

sound.init();
const time = () => `[${new Date().toISOString().slice(11, -1)}]`

class Controllers extends React.Component {
  componentDidMount() {
    document.body.click();
    events.on('play-sound', (data) => {
      console.log(time(), 'play-sound', data);
      this.play()
    });
    events.on('stop-sound', (data) => {
      console.log(time(), 'stop-sound', data);
      this.stop();
    });
  }

  componentDidUpdate(prevProps) {
    const { user, reason, started } = this.props;
    if (reason === 'closed' && reason !== prevProps.reason) {
      if (started || user.role === 'patient') {
        this.props.history.push('/ConferenceSummary')
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    events.off('play-sound');
    events.off('stop-sound');
  }

  play = () => {
    sound.play();
    this.interval = setInterval(() => sound.play(), 3000);
  }

  stop = () => {
    sound.stop();
    clearInterval(this.interval);
  }

  render() {
    return <></>;
  }
}

export default connect(
  state => ({
    user: state.User.user,
    session: state.Conference.session,
    token: state.Conference.token,
    reason: state.Conference.reason,
    started: state.Conference.started
  }),
  {
  }
)(withRouter(Controllers));