import React from 'react'
import { connect } from 'react-redux'
import throttle from 'lodash/throttle'
import './style.scss'

const time = () => `[${new Date().toISOString().replace('T', ' ')}]`;

class FileUpload extends React.Component {
	constructor(props) {
		super(props)

		this.original = null
		this.id = `${+new Date()}-${Math.floor(Math.random() * Math.pow(10, 10))}`
		this.state = {
			chunks: null,
		}
		this.ref = React.createRef()
		this.updateProgress = throttle(this.updateProgress, 500)
	}

	componentDidMount() {
		if (this.props.passRef) {
			this.props.passRef(this.ref)
		}
	}

	componentWillUnmount() {
		this.props.unmountUploader(this.id)
	}

	componentDidUpdate(prevProps) {
		const { successChunks, onUpload = () => {} } = this.props
		if (prevProps.update !== this.props.update) {
			if (successChunks[this.id] && this.state.chunks !== null && this.props.onProgress) {
				this.updateProgress()
			}
			if (successChunks[this.id] && this.state.chunks !== null && this.state.chunks === successChunks[this.id].length) {
				this.setState({ chunks: null }, () => {
					onUpload(successChunks[this.id].name, this.original)
					this.props.unmountUploader(this.id)
					this.id = `${+new Date()}-${Math.floor(Math.random() * Math.pow(10, 10))}`
				})
			}
		}
	}

	updateProgress = () => {
		const { successChunks } = this.props
		if (successChunks[this.id]) {
			this.props.onProgress({
				progress: +(successChunks[this.id].length / this.state.chunks).toFixed(2),
				totalChunks: this.state.chunks,
				successChunks: successChunks[this.id].length,
			})
		} else {
			this.props.onProgress({
				progress: 1,
			})
		}
	}

	/**
	 * @param {File} file
	 * @param {(error?: any) => void} callback
	 */
	_onUploadFile = (file, callback) => {
		const { onUploadFile } = this.props
		onUploadFile && onUploadFile(file, callback)
	}

	render() {
		const {
			chunksize = 256 * 1024, //256KB per packet
		} = this.props

		return (
			<div className='FileUploader'>
				<div className='UploaderInterface'>Отправить файл</div>
				<input
					ref={this.ref}
					type={'file'}
					onChange={(event) => {
						const file = event.target.files[0]
						// return this._onUploadFile(file, () => {
						// 	if (this.ref) {
						// 		this.ref.current.value = null
						// 	}
						// })
						this.props.initUploader(this.id)
						if (!file) return
						this.original = file.name
						const fileReader = new FileReader()
						fileReader.onload = async (evt) => {
							const ArrBuffer = evt.target.result
							this.setState({ chunks: +Math.ceil(ArrBuffer.byteLength / chunksize) })
							if (this.ref) {
								this.ref.current.value = null;
							}
							new Array(+Math.ceil(ArrBuffer.byteLength / chunksize)).fill(1).map((item, index) => {
								const chunk = ArrBuffer.slice(chunksize * index, chunksize * (index + 1))
								return this.props.sendChunk({
									id: this.id,
									chunk,
									length: +Math.ceil(ArrBuffer.byteLength / chunksize),
									index,
									name: file.name,
								})
							})
						}
						fileReader.readAsArrayBuffer(event.target.files[0])
					}}
				/>
			</div>
		)
	}
}

export default connect(
	(state) => ({
		successChunks: state.Uploads,
		update: state.Uploads.update,
	}),
	{
		sendChunk: ({ chunk, id, length, index, name }) => ({
			type: 'SEND_CHUNK',
			params: { id, chunk, length, index, name },
		}),
		initUploader: (id) => ({ type: 'COUP_UPLOADER', params: id }),
		unmountUploader: (id) => ({ type: 'DECOUP_UPLOADER', params: id }),
	}
)(FileUpload)
