import { get, post } from './networking';
import { call, put, take } from 'redux-saga/effects';
import config from '../config';

export const UserReducer = (state = { user: null, session: null, fetchuser: false, fetchsession: false, medic_user: null }, action) => {
	switch (action.type) {
		case 'AUTOLOGIN':
		case 'LOGIN':
		case 'MEDIC_LOGIN':
			return { ...state, fetchuser: true, fetchsession: true };
		case 'SET_MEDIC_USER':
			return {
				...state,
				medic_user: action.params,
			}
		case 'SET_USER':
			return { ...state, user: action.params, fetchuser: false };
		case 'SET_SESSION':
			return { ...state, session: action.params, fetchsession: false };
		case 'LOGOUT':
			return { ...state, user: null, session: null, fetch: false }
		case 'persist/REHYDRATE':
			if (action.payload) return { ...state, ...action.payload.User }
			else return state
		default:
			return { ...state };
	}
}

export function* _AutoLogin({ params }) {
	const result = yield call(post, {
		url: `${config.url}/api/autologin`,
		data: {
			user: params.login,
			token: params.token,
		},
	});
	if (result.success) {
		yield put({ type: 'SET_USER', params: { ...result.data.user, medic_token: params.token } });
		yield put({ type: 'SET_SESSION', params: result.data.session });
		yield put({ type: 'SOCKET:CONNECT', params: result.data.session });
		if (params.call && params.call.conference && params.call.session) {
			yield take('SOCKET:CONNECTED');
			yield put({ type: 'AUTO_ANSWER_CALL', params: params.call });
		}
	} else {
		yield put({ type: 'LOGOUT' })
	}
}

export function* _MedicLogin({ params }) {
	const result = yield call(post, {
		url: `${config.url}/api/mediclogin`,
		data: {
			user: params.login,
			password: params.password,
		},
	})
	if (result.success) {
		yield put({ type: 'SET_USER', params: result.data.user });
		yield put({ type: 'SET_SESSION', params: result.data.session });
		yield put({ type: 'SOCKET:CONNECT', params: result.data.session });
	} else {
    switch (result.error) {
      case 'Invalid login or password':
        yield put({ type: 'ERROR_PUSH', params: 'Неверный логин или пароль' })
        break
      case 'User is locked':
        yield put({ type: 'ERROR_PUSH', params: 'Пользователь заблокирован' })
        break
      default: 
        yield put({ type: 'ERROR_PUSH', params: 'Неизвестная ошибка' })
    }
		yield put({ type: 'LOGOUT' })
	}
}

export function* _Login({ params }) {
	const result = yield call(post, {
		url: `${config.url}/api/login`,
		data: {
			user: params.login,
			password: params.password,
		},
	});
	if (result.success) {
		yield put({ type: 'SET_USER', params: result.data.user });
		yield put({ type: 'SET_SESSION', params: result.data.session });
		yield put({ type: 'SOCKET:CONNECT', params: result.data.session });
	} else {
		yield put({ type: 'LOGOUT' })
	}
}

export function* _CheckTMAuth() {
	yield call(get, { url: `${config.url}/api/check_auth` });
}

export function* _Logout({ params }) {
	yield call(get, {
		url: `${config.url}/api/logout`,
	});
	yield put({ type: 'SOCKET:DISCONNECT_FORCE' });
}