import { setNetworkModal } from '../reducers/networkReducer'
import { store } from '../store'

const noop = () => {}

export const onOffline = (callback = noop) => {
  store.dispatch(setNetworkModal({
    visible: true,
    title: 'Интернет',
    message: 'Отсутствует подключение к сети Интернет',
    onClose: () => {
      store.dispatch(setNetworkModal({ visible: false }))
      callback()
    },
  }))
  return 'offline'
}

export const onOnline = (callback = noop) => {
  store.dispatch(setNetworkModal({ visible: false }))
  callback()
  return 'online'
}

/**
 * @return {'offline' | 'online'}
 */
export const check = (callback = noop) => {
  if (window.navigator.onLine) {
    return onOnline(callback)
  } else {
    return onOffline(callback)
  }
}

const listenerOnline = () => {
  onOnline()
}

const listenerOffline = () => {
  onOffline()
}

export const subscribe = () => {
  window.addEventListener('offline', listenerOffline)
  window.addEventListener('online', listenerOnline)
}

export const unsubscribe = () => {
  window.removeEventListener('offline', listenerOffline)
  window.removeEventListener('online', listenerOnline)
}

export default {
  onOffline,
  onOnline,
  check,
  subscribe,
  unsubscribe,
}