import React from 'react';
import './style.scss';

export default class Input extends React.Component {
	constructor(props) {
		super(props);
		
		this.id = 'button-'+Math.floor(Math.random()*Math.pow(10,7));
	}

	render() {
		const calcedStyle = {};
		if (this.props.radius) {
			calcedStyle.height = this.props.radius;
			calcedStyle.width = this.props.radius;
			calcedStyle.borderRadius = this.props.radius/2;
		}
		if (this.props.disabled) calcedStyle.opacity = 0.4
		else calcedStyle.opacity = 1 
		return <div
			tabIndex={-1}
			className={`Button${this.props.className?' '+this.props.className:''}`}
			style={{ ...calcedStyle, ...this.props.style }}
			onClick={!this.props.disabled ? () => this.props.onClick() : () => {}}>
			{this.props.title}
		</div>
	}
}