
/**
 * @param {import('./conferences.reducer').ConferenceItem} [params]
 * @return {import('./conferences.reducer').ConferenceAction}
 */
export const videoConferenceJoin = (params) => ({ type: 'VIDEO_CONFERENCE_JOIN', payload: params })

/**
 * @param {import('./conferences.reducer').ConferenceItem} params
 * @return {import('./conferences.reducer').ConferenceAction}
 */
export const videoConferencePending = (params) => ({ type: 'VIDEO_CONFERENCE_PENDING', payload: params })

/**
 * @param {import('./conferences.reducer').ConferenceItem} params
 * @return {import('./conferences.reducer').ConferenceAction}
 */
export const videoConferenceStopped = (params) => ({ type: 'VIDEO_CONFERENCE_STOPPED', payload: params })