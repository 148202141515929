
/**
 * @param {import('./incomingCalls.reducer').CallItem} call 
 * @returns {import('./incomingCalls.reducer').IncomingCallsAction}
 */
export const incomingCallStarted = (call) => ({ type: 'INCOMING_CALL_STARTED', payload: call })

/**
 * @param {import('./incomingCalls.reducer').CallItem} call 
 * @returns {import('./incomingCalls.reducer').IncomingCallsAction}
 */
export const incomingCallStopped = (call) => ({ type: 'INCOMING_CALL_STOPPED', payload: call })

/**
 * @param {import('./incomingCalls.reducer').CallItem} call 
 * @returns {import('./incomingCalls.reducer').IncomingCallsAction}
 */
export const incomingCallAnswered = (call) => ({ type: 'INCOMING_CALL_ANSWERED', payload: call })

/**
 * @param {import('./incomingCalls.reducer').CallItem} call 
 * @returns {import('./incomingCalls.reducer').IncomingCallsAction}
 */
export const incomingCallDeclined = (call) => ({ type: 'INCOMING_CALL_DECLINED', payload: call })

/**
 * @returns {import('./incomingCalls.reducer').IncomingCallsAction}
 */
export const incomingCallStoppedOnDisconnect = () => ({ type: 'INCOMING_CALL_STOPPED_ON_DISCONNECT', payload: null })

/**
 * @param {{ uuid: string }} params
 * @returns {import('./incomingCalls.reducer').IncomingCallsAction}
 */
export const answerIncomingCall = (params) => ({ type: 'ANSWER_INCOMING_CALL', payload: params.uuid })

/**
 * @param {{ uuid: string }} params
 * @returns {import('./incomingCalls.reducer').IncomingCallsAction}
 */
export const declineIncomingCall = (params) => ({ type: 'DECLINE_INCOMING_CALL', payload: params.uuid })
