import React from 'react'
import PropTypes from 'prop-types'
import {
  CameraActiveIcon,
  CameraInactiveIcon,
  MicrophoneActiveIcon,
  MicrophoneInactiveIcon,
  CallEndIcon,
} from '../../resources/icons'
import BButton from '../BButton'
import Timer from '../Timer'
import './style.scss'

const MicIcon = (props) => {
  return props.isActive ? <MicrophoneInactiveIcon /> : <MicrophoneActiveIcon />;
}

const CamIcon = (props) => {
  return props.isActive ? <CameraInactiveIcon /> : <CameraActiveIcon />;
}

export default class OvBottomButtons extends React.Component {
  render() {
    const {
      test,
      isMicActive,
      isCamActive,
    } = this.props;
    const recordingStarted = !!test.record.recordingId && !!test.record.startTime;
    if (test.stopped && test.record.recordingUrl) {
      return null;
    }
    return <div className="ConnectionTestButtons">
      <div className="MicButton" onClick={this.props.onMicClick}>
        <MicIcon isActive={isMicActive} />
      </div>
      <div className="CamButton" onClick={this.props.onCamClick}>
        <CamIcon isActive={isCamActive} />
      </div>
      {recordingStarted && (
        <span className="RecordTimer">
          <Timer until={test.record.startTime + (test.record.maxDuration || 15 * 1000)} onTimeout={() => this.props.stopConnectionTest()} />
        </span>
      )}
      {!recordingStarted && (
        <BButton
          className="StartRecord"
          style={{ borderRadius: '30px' }}
          onClick={() => this.props.startTestRecord()}
        >
          <span>Начать запись</span>
        </BButton>
      )}
      {recordingStarted && (
        <div className="CallButton" onClick={() => this.props.stopConnectionTest()}>
          <CallEndIcon />
        </div>
      )}
    </div>
  }
}

OvBottomButtons.propTypes = {
  onMicClick: PropTypes.func,
  onCamClick: PropTypes.func,
  isMicActive: PropTypes.bool,
  isCamActive: PropTypes.bool,
  test: PropTypes.shape({
    sessionId: PropTypes.string,
    token: PropTypes.string,
    record: PropTypes.shape({
      recordingId: PropTypes.string,
      startTime: PropTypes.number,
      recordingUrl: PropTypes.string,
    }).isRequired,
  }),
  startTestRecord: PropTypes.func,
  stopTestRecord: PropTypes.func,
  stopConnectionTest: PropTypes.func,
}
