import React from 'react';
import PropTypes from 'prop-types';
import { LArrowIcon as BackIcon, CloseIcon } from '../../resources/icons';
import './style.scss';

const Modal = (props) => {
  if (!props.visible) {
    return null;
  }
  const { className, closeIconColor } = props;
  return <div className={`Modal ${className || ''}`}>
    <div className='Shutter' onClick={props.onShutter} />
    <div className='Content'>
      <div className="Header">
        <div className="Title">
          {props.onBack && <div className="BackIcon" onClick={props.onBack}><BackIcon /></div>}
          <div className="TitleText">{props.title}</div>
        </div>
        {props.onClose && <div className="CloseIcon" onClick={props.onClose}><CloseIcon color={closeIconColor} /></div>}
      </div>
      <div className="Body">
        {props.children}
      </div>
    </div>
  </div>
}

Modal.propTypes = {
  closeIconColor: PropTypes.string,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onBack: PropTypes.func,
  onShutter: PropTypes.func,
  title: PropTypes.string,
};

Modal.defaultProps = {
  closeIconColor: '#fff',
}

export default Modal;