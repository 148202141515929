import * as types from './types'
import initState from './initState'

/**
 * @param {IConnectionTestState} state
 * @param {IAction} action 
 */
export const ConnectionTestReducer = (state = initState, action) => {
  switch (action.type) {
    case types.CONNECTION_TEST_START:
      return {
        ...initState,
        record: {
          ...initState.record,
        },
        starting: true,
        error: null,
      };
    case types.CONNECTION_TEST_STARTED:
      return {
        ...state,
        starting: false,
        error: null,
        sessionId: action.payload.sessionId,
        token: action.payload.token,
        secondToken: action.payload.secondToken,
        maxDuration: action.payload.maxDuration || state.maxDuration,
        insertedAt: action.payload.insertedAt || Date.now(),
        stopped: false,
      }
    case types.CONNECTION_TEST_START_ERROR:
      return {
        ...state,
        starting: false,
        error: action.error,
      }
    case types.CONNECTION_TEST_STOP:
      return {
        ...state,
        stopping: true,
        error: null,
      }
    case types.CONNECTION_TEST_STOPPED:
      return {
        ...state,
        stopped: true,
        stopping: false,
        record: {
          ...state.record,
          recordingUrl: action.payload.recordingUrl || state.record.recordingUrl,
        },
      }
    case types.CONNECTION_TEST_STOP_ERROR:
      return {
        ...state,
        stopping: false,
        error: action.error,
      }
    case types.CONNECTION_TEST_END:
      return {
        ...initState,
        record: {
          ...initState.record,
        }
      }
    case types.CONNECTION_TEST_RECORD_START:
      return {
        ...state,
        record: {
          ...state.record,
          starting: true,
          error: null,
        }
      }
    case types.CONNECTION_TEST_RECORD_STARTED:
      return {
        ...state,
        record: {
          ...state.record,
          starting: false,
          error: null,
          startTime: action.payload.startTime,
          recordingId: action.payload.recordingId,
          recordingUrl: null,
          maxDuration: action.payload.maxDuration,
        }
      }
    case types.CONNECTION_TEST_RECORD_START_ERROR:
      return {
        ...state,
        record: {
          ...state.record,
          starting: false,
          error: action.error,
        }
      }
    case types.CONNECTION_TEST_RECORD_STOP:
      return {
        ...state,
        record: {
          ...state.record,
          stopping: true,
          error: null,
        }
      }
    case types.CONNECTION_TEST_RECORD_STOPPED:
      return {
        ...state,
        record: {
          ...state.record,
          stopping: false,
          error: null,
          recordingUrl: action.payload ? action.payload.recordingUrl : state.record.recordingUrl,
        }
      }
    case types.CONNECTION_TEST_RECORD_STOP_ERROR:
      return {
        ...state,
        record: {
          ...state.record,
          stopping: false,
          error: action.error,
        }
      }
    default:
      return state;
  }
}
