import React from 'react'
import PropTypes from 'prop-types'
import { LogoutIcon, LArrowIcon, HeaderLogoIcon } from '../../resources/icons'
import './style.scss'
import config from '../../config'
import MedicLogoIcon from '../../resources/medic_logo_grey.png';

const AvatarDoctor = (props) => {
	const { photo, name, onLogout } = props
	const photoUrl = photo ? `${config.url}/${photo}` : null
	return (
		<div className='AvatarDoctor'>
			<div className='Avatar'>
				<img src={photoUrl} alt='' />
			</div>
			<div className='Name'>{name}</div>
			<div className='Logout' onClick={onLogout}>
				<LogoutIcon />
			</div>
		</div>
	)
}

AvatarDoctor.propTypes = {
	photo: PropTypes.string,
	onLogout: PropTypes.func,
	name: PropTypes.string,
}

const BackButton = (props) => {
	const { onBack = () => {} } = props
	return (
		<div className='BackButton' onClick={onBack}>
			<div className='LeftArrow'>
				<LArrowIcon />
			</div>
			<div className='BackText'>Назад</div>
		</div>
	)
}

BackButton.propTypes = {
	onBack: PropTypes.func,
}

const Header = (props) => {
	const { onLogout, doctor, onBack, showLogo, conferenceId } = props
	return (
		<div className='ScreenHeader'>
			{!!onBack && <BackButton onBack={onBack} />}
			{conferenceId ? <div className="conference-id">{conferenceId}</div> : null}
			{!!showLogo && (
				<div className='HeaderLogo'>
					<img src={MedicLogoIcon} />
				</div>
			)}
			{!!onLogout && <AvatarDoctor onLogout={onLogout} name={doctor && doctor.name} photo={doctor && doctor.photo} />}
		</div>
	)
}

Header.propTypes = {
	onLogout: PropTypes.func,
	doctor: PropTypes.shape({ name: PropTypes.string, photo: PropTypes.string }),
	onBack: PropTypes.func,
	showLogo: PropTypes.bool,
}

export default Header
