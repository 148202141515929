import { get, post } from './networking'
import { call, put } from 'redux-saga/effects'
import config from '../config'

const initialState = {
	data: [],
	file: null,
	fetching: false,
}

export const AttachmentsReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'ATTACHMENT_ADD':
		case 'ATTACHMENT_DEL':
		case 'ATTACHMENTS_GET':
			return { ...state, fetching: true }
		case 'ATTACHMENTS_SET':
			return { ...state, fetching: false, data: action.params }
		default:
			return { ...state }
	}
}

export function* _getAttachments({ params }) {
	const result = yield call(get, { url: `${config.url}/api/attachments`, params })
	if (result.success) {
		yield put({ type: 'ATTACHMENTS_SET', params: result.data })
	}
}

export function* _addAttachment({ params }) {
	const result = yield call(post, { url: `${config.url}/api/attachments/add`, data: params })
	if (result.success) {
		yield put({ type: 'ATTACHMENTS_SET', params: result.data })
	}
}

export function* _delAttachment({ params }) {
	const result = yield call(post, { url: `${config.url}/api/attachments/del`, data: params })
	if (result.success) {
		yield put({ type: 'ATTACHMENTS_SET', params: result.data })
	}
}
