import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Button from '../../components/BButton'
import Logo from '../../resources/medic_logo_grey.png'
import LogoFull from '../../resources/medic_logo_full.png'
import AppStore from '../../resources/appstore.png'
import GooglePlay from '../../resources/googleplay.png'
import MaterialDesignInput from '../../components/MaterialDesignInput'
import { PersonIcon, LockIcon } from '../../resources/icons'
import './style.scss'

class Auth extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			pagestate: 'mediclogin',
			login: '',
			email: '',
			password: '',
			passwordconfirm: '',
			token: '',
		}
	}

	componentDidMount() {
		if (this.props.session) {
			this.props.Logout()
		}
		this.props.hideNavigation()

		if (window.location.search) {
			const params = window.location.search
				.slice(1)
				.split('&')
				.reduce((obj, datum) => ({ ...obj, [datum.split('=')[0]]: datum.split('=')[1] }), {})
			if (params.login && params.token) {
				if (params.call) {
					try {
						params.call = JSON.parse(decodeURIComponent(params.call));
					} catch (e) { }
				}
				this.setState(
					{
						login: params.login,
						token: params.token,
						redirect_url: params.redirect_url,
						call: params.call,
					},
					() => this.handleAutoLogin()
				)
			}
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.session !== prevProps.session && this.props.session) {
			this.props.showNavigation()
			const { redirect_url } = this.state;
			if (redirect_url) {
				window.location.href = decodeURIComponent(redirect_url);
			} else {
				this.props.history.replace('/')
			}
		}
	}

	handleAutoLogin = () => {
		this.props.AutoLogin({ login: this.state.login, token: this.state.token, call: this.state.call })
	}

	handleLogin = () => {
		console.log('handleLogin')
		if (this.state.password && this.state.login && this.state.password !== '' && this.state.login !== '') {
			this.props.Login({ login: this.state.login, password: this.state.password })
		}
	}

	handleMedicLogin = () => {
		console.log('handleMedicLogin')
		if (this.state.password && this.state.login && this.state.password !== '' && this.state.login !== '') {
			this.props.MedicLogin({ login: this.state.login, password: this.state.password })
		}
	}

	onClickLogin = () => {
		const { pagestate } = this.state
	 pagestate === 'login' ? this.handleLogin() : this.handleMedicLogin()
	}

	renderForm = () => {
		return (
			<form 
				onSubmit={(e) => {
					e.preventDefault()
					this.onClickLogin()
				}}
			>
				<MaterialDesignInput
					icon={<PersonIcon />}
					title='Логин / Email'
					autoFocus
					placeholder='Логин из БАРС'
					value={this.state.login}
					onChange={(login) => this.setState({ login })}
				/>
				<MaterialDesignInput
					icon={<LockIcon />}
					placeholder='Пароль из БАРС'
					title='Пароль'
					type='password'
					value={this.state.password}
					onChange={(password) => this.setState({ password })}
				/>
				<input type="submit" value="Enter" style={{ display: 'none' }}></input>
				<Button
					color='pink'
					onClick={() => this.onClickLogin()}>
					Вход
				</Button>
			</form>
		)
	}

	renderSwitch = () => {
		const { pagestate } = this.state
		return (
			<Button color='pink' onClick={() => this.setState({ pagestate: pagestate === 'login' ? 'mediclogin' : 'login' })}>
				{pagestate === 'login' ? 'Личный кабинет' : 'Кабинет врача'}
			</Button>
		)
	}

	render() {
		const { pagestate } = this.state
		return (
			<div className='auth'>
				<div className='auth__header'>
					<div className='auth__header__logo'>
						<img className='logo' src={Logo} alt='Medic-logo' />
						<div className='title'>Телемедицина</div>
					</div>
					<div className='auth__header__switch'>{this.renderSwitch()}</div>
				</div>
				<div className='auth__content'>
					<div className='auth__content__logo'>
						<img className='logo' src={LogoFull} alt='Medic-logo' />
					</div>
					<div className='auth__content__form'>{this.renderForm()}</div>
					{pagestate === 'login' && <div className='auth__content__stores'>
						<img src={AppStore} alt='App Store' />
						<img src={GooglePlay} alt='Google Play' />
					</div>}
					{pagestate === 'login' && <p>Также вы можете воспользоваться нашим мобильным приложением!</p>}
					{pagestate === 'mediclogin' && <p>Авторизация врача</p>}
				</div>
			</div>
		)
	}
}

export default withRouter(
	connect(
		(state) => ({
			session: state.User.session,
		}),
		{
			MedicLogin: (params) => ({ type: 'MEDIC_LOGIN', params }),
			Login: (params) => ({ type: 'LOGIN', params }),
			AutoLogin: (params) => ({ type: 'AUTOLOGIN', params }),
			Logout: () => ({ type: 'LOGOUT' }),
		}
	)(Auth)
)
