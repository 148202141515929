export const CONNECTION_TEST_START = 'CONNECTION_TEST_START';
export const CONNECTION_TEST_STARTED = 'CONNECTION_TEST_STARTED';
export const CONNECTION_TEST_START_ERROR = 'CONNECTION_TEST_START_ERROR';
export const CONNECTION_TEST_STOP = 'CONNECTION_TEST_STOP';
export const CONNECTION_TEST_STOPPED = 'CONNECTION_TEST_STOPPED';
export const CONNECTION_TEST_STOP_ERROR = 'CONNECTION_TEST_STOP_ERROR';
export const CONNECTION_TEST_END = 'CONNECTION_TEST_END';
export const CONNECTION_TEST_RECORD_START = 'CONNECTION_TEST_RECORD_START';
export const CONNECTION_TEST_RECORD_STARTED = 'CONNECTION_TEST_RECORD_STARTED';
export const CONNECTION_TEST_RECORD_START_ERROR = 'CONNECTION_TEST_RECORD_START_ERROR';
export const CONNECTION_TEST_RECORD_STOP = 'CONNECTION_TEST_RECORD_STOP';
export const CONNECTION_TEST_RECORD_STOPPED = 'CONNECTION_TEST_RECORD_STOPPED';
export const CONNECTION_TEST_RECORD_STOP_ERROR = 'CONNECTION_TEST_RECORD_STOP_ERROR';
