export const SocketReducer = (state = { connected: false, connecting: false }, action) => {
	switch (action.type) {
		case 'SOCKET:CONNECT':
			return { ...state, connecting: true };
		case 'SOCKET:CONNECTED':
			return { ...state, connected: true, connecting: false };
		case 'SOCKET:DISCONNECT_FORCE':
		case 'SOCKET:DISCONNECT':
			return { ...state, connected: false, connecting: false };
		default:
			return { ...state };
	}
}