import React from 'react'
import { connect } from 'react-redux'
import { Link, Route, Switch, withRouter } from 'react-router-dom'
import throttle from 'lodash/throttle'
import debounce from 'lodash/debounce'
import ScreenWrapper from '../../components/ScreenWrapper'
import List from './List'
import Chat from '../../components/Chat'
import Modal from '../../components/MModal'
import CheckBox from '../../components/CheckBox'
import ArchiveConference from '../../components/Archive'
import Button from '../../components/BButton'
import { historyActions } from '../../reducers/history'
import { formatName, formatDoctorName, getFullName } from './utils'

import './style.scss'
import Header from '../../components/Header'
import moment from 'moment'
import { chatRoomByAppointmentRequest, chatRoomActiveSet, chatRoomInActiveSet } from '../../reducers/chatRooms'
import { store } from 'react-notifications-component'

// delete me
const time = () => `[${new Date().toISOString().slice(11, -1)}]`

/**
 * @typedef {{
 * 	conference_id: number;
 *  name: string;
 *  conference_key: string;
 *  inserted_at: string;
 *  closed: 0 | 1;
 *  updated_at: string;
 *  appointment_id: number;
 *  roled_id: 1 | 2;
 *  users: any[],
 *  attachments: any[],
 *  started_at: string;
 *  ended_at: string;
 *  datetime: string;
 *  duration: number;
 *  patient: any;
 * }} IConference
 * @param {IConference[]} pastConferences
 * @return {IConference[]}
 */
const composePastConferences = (pastConferences) => {
	/** @type {{ [x: string]: IConference[] }} */
	const appointmentMap = pastConferences.reduce((acc, cur) => {
		acc[cur.appointment_id] = acc[cur.appointment_id] || []
		acc[cur.appointment_id].push(cur)
		return acc
	}, {})
	/** @type {IConference[]} */
	const appointments = []
	for (const appointment_id of Object.keys(appointmentMap)) {
		const conferences = appointmentMap[appointment_id].sort((a, b) => a.conference_id - b.conference_id)
		const appointment = conferences[conferences.length - 1]
		appointment.attachments = conferences.reduce((acc, cur) => {
			acc.push(...cur.attachments)
			return acc
		}, [])
		appointment.attachments = appointment.attachments.filter(
			(item, index, array) => array.findIndex((item2) => item2.attachment_id === item.attachment_id) === index
		)
		appointments.push(appointment)
	}
	return appointments
		.filter((item, index, array) => array.findIndex((item2) => item2.appointment_id === item.appointment_id) === index)
		.sort((a, b) => moment(b.started_at).diff(a.started_at))
}

class DoctorsDiary extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			activeIndex: -1,
			activeItems: [],
			ignoreInvalidPhone: false,
			invalidPhone: false,
		}
		this.toggleItem = throttle(this.toggleItem, 400)
		this.startAppointment = throttle(this.startAppointment, 1000)
		this.onPlan = debounce(this.onPlan, 500)
		this.onPast = debounce(this.onPast, 500)
	}

	componentDidMount() {
		this.props.PatientsFetch()
		this.props.historyFetch()
	}

	componentDidUpdate(prevProps) {
		const chat = this.chat()
		const prevChat = this.chat(prevProps)
		const room = this.chatRoom()
		if (!chat.fetching && prevChat.fetching && room > 0) {
			this.setState({ chatRoomFetching: false })
		}
	}

	chat = (props) => {
		const { rooms } = props || this.props
		const { chatRoomAppointmentId } = this.state
		return rooms[chatRoomAppointmentId] || {}
	}

	chatRoom = (props) => {
		const chat = this.chat(props)
		return chat.room ? chat.room.room : -1
	}

	appointmentId = (props) => {
		const chat = this.chat(props)
		return chat.appointment_id || 0
	}

	toggleItem = (index, name, cb) => {
		const { activeIndex, activeItems } = this.state
		if (index !== activeIndex) {
			this.setState(
				{
					activeIndex: index,
					activeItems: [name],
				},
				cb
			)
			return
		}
		const _index = activeItems.indexOf(name)
		if (_index === -1) {
			this.setState(
				{
					activeItems: [...activeItems, name],
				},
				cb
			)
		} else {
			this.setState(
				{
					activeItems: activeItems.filter((_, idx) => idx !== _index),
				},
				cb
			)
		}
	}

	toggleChat = (index, type) => {
		this.toggleItem(index, 'chat')
	}

	toggleMedia = (index) => {
		const { history } = this.props
		this.toggleItem(index, 'media', () => {
			const { activeIndex, activeItems } = this.state
			const { hideNavigation, showNavigation, pastList } = this.props
			if (activeItems.includes('media')) {
				history.push(`/DoctorsDiary/Media/${pastList[activeIndex].conference_id}`)
				hideNavigation()
			} else {
				history.push('/DoctorsDiary/History')
				showNavigation()
			}
		})
	}

	openAttachment = (attachment) => {
		console.log('openAttacment: ', attachment)
	}

	startAppointment = (item) => {
		const { user = item.patient.login, direction_service_id: appointment_id, type: format } = item
		const { ignoreInvalidPhone } = this.state
		if (ignoreInvalidPhone || /^\+?\d+$/.test(user)) {
			this.props.CallPatient({ user, appointment_id, format })
		} else {
			this.setState({ invalidPhone: true, appointment: item })
		}
	}

	_startAppointmentUnsafe = (item) => {
		if (!item) {
			console.error('appointment not found')
			return
		}
		const { user = item.patient.login, direction_service_id: appointment_id, type: format } = item
		this.props.CallPatient({ user, appointment_id, format })
	}

	renderPlan = () => {
		const { planList, rooms } = this.props
		const { chatRoomAppointmentId } = this.state
		return (
			<List
				data={planList.filter((p) => p.paid || p.dms)}
				toggleChat={this.toggleChat}
				openAttachment={this.openAttachment}
				startAppointment={this.startAppointment}
				activeChatRoomAppointmentId={chatRoomAppointmentId}
				rooms={rooms}
				onChat={this.onChat}
			/>
		)
	}

	/**
	 * @param {number} direction_service_id
	 */
	onChat = (direction_service_id) => {
		const { chatRoomAppointmentId } = this.state
		if (chatRoomAppointmentId && chatRoomAppointmentId == direction_service_id) {
			this.setState({ chatRoomAppointmentId: 0 })
			this.props.chatRoomInActiveSet(chatRoomAppointmentId)
		} else {
			this.props.chatRoomByAppointmentRequest(direction_service_id)
			this.setState({
				chatRoomAppointmentId: direction_service_id,
			})
			this.props.chatRoomActiveSet(direction_service_id)
		}
	}

	renderPast = () => {
		const { pastList, rooms } = this.props
		const { activeIndex, activeItems } = this.state
		const data = composePastConferences(pastList)
		return (
			<List
				data={data}
				toggleChat={this.toggleChat}
				toggleMedia={this.toggleMedia}
				activeIndex={activeIndex}
				activeItems={activeItems}
				openAttachment={this.openAttachment}
				chatRoomActiveSet={this.props.chatRoomActiveSet}
				chatRoomInActiveSet={this.props.chatRoomInActiveSet}
				rooms={rooms}
				onChat={this.onChat}
			/>
		)
	}

	renderMedia = () => {
		const { activeIndex } = this.state
		const { pastList, user, history, location, showNavigation, historyFetch } = this.props
		const item = pastList[activeIndex]
		return (
			<ArchiveConference
				item={item}
				user={user}
				history={history}
				location={location}
				historyFetch={historyFetch}
				showNavigation={showNavigation}
			/>
		)
	}

	renderRightSide = () => {
		const { pastList } = this.props
		const { activeIndex, activeItems } = this.state
		const pastItem = activeIndex > -1 && activeItems.length ? pastList[activeIndex] : null
		const isPast = pastItem && activeItems.includes('chat')
		// я не помню, зачем чат был так разделен, но теперь все работает
		if (pastItem && activeItems.includes('chat') && false) {
			const { conference_id: room, patient, appointment_id } = pastItem
			return (
				<div className='ConferenceChat'>
					<Chat
						room={room}
						title={getFullName(patient)}
						onClose={() => this.toggleChat(activeIndex)}
						appointment_id={appointment_id}
					/>
				</div>
			)
		}
		const chat = this.chat()
		const room = this.chatRoom()
		const appointmentId = this.appointmentId()
		const { chatRoomAppointmentId } = this.state
		if (!chat.fetching && room > 0 && chatRoomAppointmentId) {
			return (
				<div className='ConferenceChat'>
					<Chat
						room={room}
						title={getFullName(chat.room.patient)}
						onClose={() => this.onChat(appointmentId)}
						appointment_id={appointmentId}
					/>
				</div>
			)
		}
	}

	closeMedia = () => {
		console.log('close video')
		const { activeIndex } = this.state
		this.toggleMedia(activeIndex)
	}

	logout = () => {
		const { history } = this.props
		history.replace('/Auth')
	}

	renderHeader = () => {
		const { user } = this.props
		const name = formatDoctorName(user)
		const { photo } = user
		const { activeItems } = this.state
		const isMediaScreen = activeItems.includes('media')
		return (
			<Header
				onLogout={this.logout}
				doctor={{ name, photo }}
				showLogo={isMediaScreen}
				onBack={isMediaScreen ? this.closeMedia : null}
			/>
		)
	}

	onPlan = () => {
		this.setState({
			activeIndex: -1,
			activeItems: [],
		})
		this.props.PatientsFetch()
		this.setState({ chatRoomAppointmentId: 0 })
		this.props.chatRoomActiveSet(-1)
	}

	onPast = () => {
		this.props.historyFetch()
		this.setState({ chatRoomAppointmentId: 0 })
		this.props.chatRoomActiveSet(-1)
	}

	hasActiveConference = () => {
		const { session, token } = this.props
		return session && !token
	}

	rejoinConference = () => {
		console.log('rejoinConference.session', this.props.session)
		console.log('this.rejoinConference: ', typeof this.props.rejoinConference)
		this.props.rejoinConference(this.props.session)
	}

	getUnreadPast = () => {
		const { rooms, pastList } = this.props
		return pastList.reduce((count, conference) => {
			const { appointment_id } = conference
			const r = rooms[appointment_id]
			return count + (r ? r.unread : 0)
		}, 0)
	}

	getUnreadFuture = () => {
		const { rooms, planList } = this.props
		return planList.reduce((count, appointment) => {
			const { appointment_id = appointment.direction_service_id } = appointment
			const r = rooms[appointment_id]
			return count + (r ? r.unread : 0)
		}, 0)
	}

	render() {
		const { location, history } = this.props
		const { invalidPhone } = this.state
		if (location.pathname === '/DoctorsDiary') {
			history.push('/DoctorsDiary/Plan')
		}
		const unreadPast = this.getUnreadPast()
		const unreadFuture = this.getUnreadFuture()
		return (
			<ScreenWrapper style={{ display: 'flex', padding: 0 }} ScreenHeader={this.renderHeader()}>
				<Switch>
					<Route path='/DoctorsDiary/Media' render={this.renderMedia}></Route>
					<Route path='/DoctorsDiary'>
						<div className='DoctorsContainer'>
							<h2>Дневник врача</h2>
							<div className='DoctorsDiaryBlock'>
								{this.hasActiveConference() && (
									<div className='RejoinAppointment'>
										<span className='Bold'>Прием еще не завершен</span>
										<Button className='RejoinConference' onClick={this.rejoinConference}>
											Вернуться на прием
										</Button>
									</div>
								)}
								<div className='Header'>
									<Link
										to='/DoctorsDiary/Plan'
										onClick={this.onPlan}
										className={
											location.pathname === '/DoctorsDiary/Plan' || location.pathname === '/DoctorsDiary'
												? 'active'
												: ''
											}
									>
										<span>Предстоящие записи</span>
										{unreadFuture ? <div className="badge">{unreadFuture}</div> : null}
									</Link>
									<Link
										to='/DoctorsDiary/History'
										onClick={this.onPast}
										className={location.pathname === '/DoctorsDiary/History' ? 'active' : ''}
									>
										<span>Прошедшие записи</span>
										{unreadPast ? <div className="badge">{unreadPast}</div> : null}
									</Link>
								</div>
								<Switch>
									<Route path='/DoctorsDiary/Plan' render={this.renderPlan} />
									<Route path='/DoctorsDiary/History' render={this.renderPast} />
								</Switch>
							</div>
						</div>
						<div className='ConferenceRightBlock'>{this.renderRightSide()}</div>
					</Route>
				</Switch>
				<Modal
					title={'Ошибка'}
					visible={this.props.reason == 'patient has debts'}
					onClose={() => this.props.cleanConferenceError()}
					onShutter={() => this.props.cleanConferenceError()}
				>
					<div style={{ padding: '20px' }}>
						<span>{'У пациента имеется задолженность за проведенную онлайн-консультацию'}</span>
					</div>
				</Modal>
				<Modal
					title={'Неверный номер телефона'}
					visible={invalidPhone}
					onClose={() => this.setState({ invalidPhone: false })}
					onShutter={() => this.setState({ invalidPhone: false })}>
					<div class='InvalidPhone'>
						<span>{'Номер пациента в МИС указан не корректно'}</span>
						{/* <CheckBox
							className="IgnoreInvalidPhone"
							checked={this.state.ignoreInvalidPhone}
							onClick={() => this.setState({ ignoreInvalidPhone: !this.state.ignoreInvalidPhone })}
							label={<span>{'Игнорировать эту ошибку'}</span>}
						/> */}
						<Button
							className='IgnoreInvalidPhone'
							onClick={() => {
								this.setState(
									{
										invalidPhone: false,
									},
									() => this._startAppointmentUnsafe(this.state.appointment)
								)
							}}>
							{<span>{'Все равно позвонить'}</span>}
						</Button>
					</div>
				</Modal>
			</ScreenWrapper>
		)
	}
}

const fakePatientsList = [
	{
		direction_service_id: 111675107,
		paid: true,
		type: 'video',
		price: 520,
		service: 'Онлайн-консультация терапевта',
		started_at: '2020-11-18T12:00:00.000+03:00',
		inserted_at: '2020-11-12T10:01:27.000+03:00',
		registered_by_patient: true,
		patient: {
			user_id: 102895201,
			login: '9063802990',
			first_name: 'Иван',
			last_name: 'Скворцов',
			middle_name: 'Алексеевич',
			phone: '+79063802990',
			gender: 'm',
			years: 30,
		},
		doctor: {
			id: 107875455,
			first_name: 'Тест',
			last_name: 'Иван',
			middle_name: 'Иванович',
			speciality: 'Врач-терапевт ',
		},
		attachments: [],
	},
]

export default withRouter(
	connect(
		(state) => ({
			user: state.User.user,
			pastList: state.History.list,
			pastFetch: state.History.fetch,
			pastError: state.History.error,
			planList: state.Patients.list,
			// planList: fakePatientsList,
			session: state.Conference.session,
			token: state.Conference.token,
			rooms: state.ChatRooms,
			reason: state.Conference.reason,
		}),
		{
			historyFetch: historyActions.historyFetch,
			PatientsFetch: () => ({ type: 'PATIENTS_FETCH' }),
			CallPatient: (params) => ({ type: 'CREATE_CONFERENCE', params }),
			rejoinConference: (session) => ({ type: 'REJOIN_CONFERENCE', payload: session }),
			chatRoomByAppointmentRequest: chatRoomByAppointmentRequest,
			chatRoomActiveSet: chatRoomActiveSet,
			chatRoomInActiveSet: chatRoomInActiveSet,
			cleanConferenceError: () => ({ type: 'CLEAN_CONFERENCE_ERROR' }),
		}
	)(DoctorsDiary)
)
