import React from 'react'
import { BrowserRouter as Router, Link, Redirect, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { OpenViduLogger } from 'openvidu-browser'


import RoomJoin from '../screens/RoomJoin'
import Conference from '../screens/Conference'
import Auth from '../screens/Auth'
import Contacts from '../screens/Contacts'
import DoctorsDiary from '../screens/DoctorsDiary'
import DoctorRating from '../screens/DoctorRating'
import Information from '../screens/Information'
import PatientDiary from '../screens/PatientDiary'
import Appointment from '../screens/Appointment'
import PresetsScreen from '../screens/Presets'
import ConnectionTest from '../screens/ConnectionTest'
import ConferenceSummary from '../screens/ConferenceSummary'
import Test from '../screens/Test'

import Controllers from '../components/Controllers'
import ModalAppointmentConfirmation from '../components/ModalAppointmentConfirmation'

import CallManager from '../components/CallManager'
import Permissions from '../components/Permissions'

import './style.scss'
import Logo from '../resources/medic_logo_grey.png'

import { Circle as Preloader } from 'react-preloaders'

import { CogIcon, PhoneIcon, PowerIcon, DocumentIcon, InfoIcon, PenIcon } from '../resources/icons'
import Modal from '../components/Modal'
import AppointmentType from '../modals/AppointmentType'

import config from '../config'

import { formatName } from '../screens/DoctorsDiary/utils'
import ReactNotification, { store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

const { default: logger } = require('../utils/logger')


window.OpenViduLogger = OpenViduLogger
OpenViduLogger.setLogger(logger)

const Screens = new Map()
Screens.set('/Join', {
	Component: (props) => <RoomJoin {...props} />,
	name: 'Присоединиться',
	role: ['doctor'],
})
Screens.set('/Conference', {
	Component: (props) => <Conference {...props} />,
	name: 'Конференция',
})
Screens.set('/Auth', {
	Component: (props) => <Auth {...props} />,
	name: 'Авторизация',
	noauth: true,
})
Screens.set('/Information', {
	Component: (props) => <Information {...props} />,
	name: 'Информация',
	role: ['patient'],
})
Screens.set('/Contacts', {
	Component: (props) => <Contacts {...props} />,
	name: 'Контакты',
	role: ['patient'],
})
Screens.set('/DoctorsDiary', {
	Component: (props) => <DoctorsDiary {...props} />,
	name: 'Дневник врача',
	role: ['doctor'],
})
Screens.set('/PatientDiary', {
	Component: (props) => <PatientDiary {...props} />,
	name: 'Предстоящие записи',
	role: ['patient'],
})
Screens.set('/DoctorRating', {
	Component: (props) => <DoctorRating {...props} />,
	name: 'Оценка врача',
	role: ['patient'],
})
Screens.set('/Appointment', {
	Component: (props) => <Appointment {...props} />,
	name: 'Запись на прием',
	role: ['patient'],
})
Screens.set('/Presets', {
	Component: (props) => <PresetsScreen {...props} />,
	name: 'Шаблоны',
	role: ['doctor'],
})
Screens.set('/ConferenceSummary', {
	Component: (props) => <ConferenceSummary {...props} />,
	name: 'Прием завершен!',
})
Screens.set('/Test', {
	Component: (props) => <Test {...props} />,
	name: 'Проверка устройств',
	role: ['doctor'],
})
Screens.set('/ConnectionTest', {
	Component: (props) => <ConnectionTest {...props} />,
	name: 'Проверить связь',
})

class RouterNest extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			route: '',
			navigation: true,
			navwidth: 0,
		}
	}

	componentDidMount() {
		this.handleResize()
		window.addEventListener('resize', this.handleResize)
		if (this.props.connection) {
			this.fetchConference()
		}
	}

	componentDidUpdate(prevProps) {
		const { connection } = this.props
		if (connection && !prevProps.connection) {
			this.fetchConference()
		}
		const { rooms } = this.props
		if (rooms.totalUnread > prevProps.rooms.totalUnread) {
			this.addNotification()
		}
		const { notifications = [] } = this.state
		if (rooms.totalUnread == 0 && (prevProps.rooms.totalUnread > 0 || notifications.length > 0)) {
			this.removeNotifications()
		} else if (rooms.totalUnread < prevProps.rooms.totalUnread) {
			this.removeNotifications(rooms.totalUnread)
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize)
		this.removeNotifications()
	}

	addNotification = () => {
		const { user } = this.props
		const id = store.addNotification({
			title: 'Новое сообщение',
			message: 'У Вас новое сообщение от ' + (user.role == 'doctor' ? 'пациента' : 'врача'),
			type: 'info',
			insert: 'top',
			container: 'top-right',
			width: window.screen.width * 0.33,
			onRemoval: (id, removedBy) => {
				const { notifications = [] } = this.state
				if (notifications.indexOf(id) != -1) {
					this.setState({ notifications: notifications.filter(n_id => n_id != id) })
				}
			}
		})
		const { notifications = [] } = this.state
		this.setState({
			notifications: [...notifications, id],
		})
	}

	removeNotifications = (unread = 0) => {
		const { notifications = [] } = this.state
		const rm = notifications.length - unread
		notifications.forEach((id, index) => {
			if (index < rm) {
				store.removeNotification(id)
			}
		})
	}

	handleResize = () => {
		let navwidth = window.innerWidth * 0.25
		navwidth = Math.max(navwidth, 345)
		//navwidth = Math.max(navwidth, 150);
		this.setState({ navwidth })
	}

	fetchConference = () => {
		const { conference, session, fetchConference } = this.props
		if (conference.session && session) {
			fetchConference(conference.session)
		}
	}

	toggleNavigation = () => this.setState({ navigation: !this.state.navigation })

	hideNavigation = () => this.setState({ navigation: false })
	showNavigation = () => this.setState({ navigation: true })

	renderLink = (datum) => {
		if (this.props.session || datum.noauth) {
			if (datum.role && !datum.role.includes(this.props.user.role)) {
				return <Redirect to={{ pathname: '/' }} />
			} else if (this.props.connection || datum.noauth) {
				return (
					<div className='routeRoot'>
						{datum.Component({
							navigation: this.state.navigation,
							toggleNavigation: this.toggleNavigation,
							hideNavigation: this.hideNavigation,
							showNavigation: this.showNavigation,
						})}
						<CallManager />
					</div>
				)
			} else {
				return <Preloader color={'#ce112f'} />
			}
		} else {
			return <Redirect to={{ pathname: '/Auth' }} />
		}
	}

	render() {
		const { user } = this.props
		return (
			<Router>
				<ReactNotification />
				<div
					className='Navigator'
					style={{ width: '20%', left: this.state.navigation ? 0 : -this.state.navwidth }}>
					<img className='Logo' src={Logo} alt='Medic-logo' />
					{this.props.user && (
						<>
							{['patient'].includes(this.props.user.role) && (
								<a href={`${config.lkurl}/menu/main/makeappointment`}>
									<PenIcon />
									<span>Записаться на прием</span>
								</a>
							)}
							{['doctor'].includes(this.props.user.role) && (
								<Link to='/Join'>
									<PhoneIcon />
									<span>Звонок</span>
								</Link>
							)}
							{['patient'].includes(this.props.user.role) && (
								<Link to='/Information'>
									<InfoIcon />
									<span>Информация</span>
								</Link>
							)}
							{['doctor'].includes(this.props.user.role) && (
								<Link to='/DoctorsDiary'>
									<DocumentIcon />
									<span>Дневник врача</span>
								</Link>
							)}
							{['doctor'].includes(this.props.user.role) && (
								<Link to='/Presets'>
									<DocumentIcon />
									<span>Шаблоны</span>
								</Link>
							)}
							{['patient'].includes(this.props.user.role) && (
								<Link to='/PatientDiary'>
									<DocumentIcon />
									<span>Предстоящие записи</span>
								</Link>
							)}
							<div className='menuDelimeter' />
							{user && user.role === 'patient' && (
								<a href={`${config.lkurl}`}>
									<PowerIcon />
									<span>Личный кабинет</span>
								</a>
							)}
							{user && user.role === 'doctor' && (
								<Link to='/Test'>
									<DocumentIcon />
									<span>Тест</span>
								</Link>
							)}
							{user && (
								<Link to='/ConnectionTest'>
									<DocumentIcon />
									<span>Проверить связь</span>
								</Link>
							)}
							{user && user.role === 'doctor' && (
								<Link to='/Auth'>
									<PowerIcon />
									<span>Выход</span>
								</Link>
							)}
							{this.props.user && <div className='CurrentUser'>Добро пожаловать, {this.props.user.login}</div>}
						</>
					)}
				</div>
				<div className='Screen' style={{ paddingLeft: this.state.navigation ? '20%' : 0 }}>
					<Switch>
						{(() => {
							const Links = []
							Screens.forEach((datum, route) => {
								Links.push(
									<Route key={route} path={route}>
										{this.renderLink(datum)}
									</Route>
								)
							})
							Links.push(
								<Route key='/' path='/'>
									{this.renderLink({
										role: [this.props.user ? this.props.user.role : 'patient'],
										Component: (props) =>
											this.props.user && this.props.user.role === 'doctor' ? (
												<Redirect to='/DoctorsDiary' />
											) : (
												<Redirect to='/PatientDiary' />
											),
										name: 'Main',
									})}
								</Route>
							)
							return Links
						})()}
					</Switch>
				</div>
				<Modal
					visible={this.props.errors.length}
					dismissable
					title={'Произошла ошибка'}
					onClose={() => this.props.PopError()}>
					<div className='error'>{this.props.errors[this.props.errors.length - 1]}</div>
				</Modal>
				<Modal
					visible={this.props.offline.length}
					dismissable
					title={'Пользователь не в сети'}
					onClose={this.props.shiftOffline}>
					<div className='UserOffline'>{formatName(this.props.offline[0])}</div>
				</Modal>
				<Modal
					visible={this.props.network.modal.visible}
					dismissable={false}
					title={this.props.network.modal.title}
					onClose={() => {
						this.props.network.modal.onClose()
					}}
				>
					<div className="NetworkState">{this.props.network.modal.message}</div>
				</Modal>
				{window.location.pathname !== '/Auth' && <ModalAppointmentConfirmation />}
				<Permissions name='microphone' />
				<Permissions name='camera' />
				<AppointmentType />
				<Controllers />
			</Router>
		)
	}
}

export default connect(
	(state) => ({
		errors: state.Errors,
		session: state.User.session,
		connection: state.Socket.connected,
		user: state.User.user,
		conference: state.Conference,
		offline: state.Users.offline,
		network: state.Network,
		rooms: state.ChatRooms,
	}),
	{
		PopError: () => ({ type: 'ERROR_POP' }),
		fetchConference: (session) => ({ type: 'FETCH_CONFERENCE', payload: session }),
		shiftOffline: () => ({ type: 'SHIFT_USER_OFFLINE' }),
	}
)(RouterNest)
