import React from 'react';
import { connect } from 'react-redux';
import {
	withRouter,
} from "react-router-dom";
// import OpvSession from 'openvidu-react';
import OpvSession from '../../openvidu-react';
import debounce from 'lodash/debounce';
import './style.scss';
import ScreenWrapper from '../../components/ScreenWrapper';
import Permissions from '../../components/Permissions'
import Timer from '../../components/Timer';

import Chat from '../../components/Chat';
import {
	AttachPin,
	AvatarLightIcon as AvatarIcon,
	PhoneGrayIcon,
	ChatGrayIcon,
	CameraGrayIcon,
} from '../../resources/icons';
import AttachmentList from '../../components/AttachmentList';
import { formatName } from '../DoctorsDiary/utils';
import Button from '../../components/Button';
import Modal from '../../components/MModal';
import events from '../../reducers/events';
import { chatRoomActiveSet, chatRoomInActiveSet } from '../../reducers/chatRooms';

/**
 * @param {{ appointment: number; session: string }} props
 */
const AppointmentDetailes = (props) => (<div className="DetailsContainer">
	<div className="DetailsData">
		<span>Прием: № {props.appointment}</span>
		<span>Конференция: {props.session}</span>
	</div>
</div>);


const Title = ({ name, timerUntil, onTimeout }) => (<div className="Title">
	<div className="User">
		<div className="Avatar"><AvatarIcon /></div>
		<div className="Name">{name}</div>
	</div>
	{/* <div className="Timer"><Timer until={timerUntil} onTimeout={onTimeout} /></div> */}
	<div className="Timer">{null}</div>
</div>);

const Center = ({ type, name, timerUntil, avatar, onTimeout }) => (<div className="Center">
	<div className="AvatarContainer">
		<div className="Avatar">
			{avatar ? <img src={avatar} /> : <AvatarIcon width={'200'} height={'200'} />}
		</div>
		<div className="Badge">
			{(() => {
				switch (type) {
					case 'audio':
						return <PhoneGrayIcon />
					case 'chat':
						return <ChatGrayIcon />
					case 'video':
					default:
						return <CameraGrayIcon />
				}
			})()}
		</div>
	</div>
	<div className="Name">{name}</div>
	{/* <div className="Timer"><Timer until={timerUntil} onTimeout={onTimeout} /></div> */}
	<div className="Timer">{null}</div>
</div>);

class Conference extends React.Component {
	constructor(props) {
		super(props);
		this.leaveConference = debounce(this.leaveConference, 500);

		this.offlineText = 'Пациент не авторизован и не доступен!';
		this.standByText = 'Пожалуйста, ожидайте соединения...';
		this.state = {
			onhold: false,
			format: props.format,
			standByText: this.standByText,
			techHidden: false,
		}
	}

	componentDidMount() {
		this.timerUntil = Date.now() + 20 * 60 * 1000; // TODO
		this.checkAuth();
		if (this.props.navigation) {
			this.props.hideNavigation();
		}
		const { getAttchments, appointment_id } = this.props;
		if (getAttchments && appointment_id) {
			this.props.getAttchments({ appointment_id });
		}
		this.checkOffline();
		if (appointment_id) {
			this.props.chatRoomActiveSet(appointment_id)
		}
	}
	componentDidUpdate(prevProps) {
		this.checkAuth();
		const { format, holdon, offline, online, participant, appointment_id } = this.props;
		if (prevProps.format !== format) {
			this.setState({ format });
		}
		if (appointment_id && !prevProps.appointment_id) {
			this.props.chatRoomActiveSet(appointment_id)
		}
		if (prevProps.holdon !== holdon) {
			if (holdon) {
				this.props.leaveConference(this.props.session);
			}
		}
		if (prevProps.offline.length !== offline.length || prevProps.online.length !== online.length) {
			this.checkOffline();
		}
	}

	componentWillUnmount() {
		if (!this.props.navigation) {
			this.props.showNavigation();
		}
		this.props.chatRoomActiveSet()
	}

	checkOffline = () => {
		const { offline, participant } = this.props;
		const index = offline.findIndex(u => (u.user_id === participant.user_id));
		if (index !== -1) {
			this.setState({ standByText: this.offlineText });
		} else {
			this.setState({ standByText: this.standByText });
		}
	}

	checkAuth = () => {
		const { user, started } = this.props;
		if (!this.props.token && !this.props.session) {
			switch (this.props.reason) {
				case 'closed':
					if (started || user.role === 'patient') {
						this.props.history.replace('/ConferenceSummary')
					} else if (user.role === 'doctor') {
						this.props.history.replace('/DoctorsDiary')
					}
					break;
				default:
					if (user.role === 'doctor') {
						this.props.history.replace('/DoctorsDiary')
					} else {
						this.props.history.replace('/PatientDiary')
					}
			}
		} else if (!this.props.token && this.props.session) {
			if (user.role === 'patient') {
				this.props.history.replace('/PatientDiary');
			}
			if (user.role === 'doctor') {
				this.props.history.replace('/DoctorsDiary');
			}
		}
	}

	leaveConference = () => {
		// отрабатывает когда пользователь сам кликнул выйти, обновил страницу, закрыл страницу
		this.props.cleanToken(this.props.session); // зануляет только token
	}

	onLeaveSession = () => {
		// отрабатывает когда пользователь сам кликнул выйти
		this.props.leaveConference(this.props.session); // зануляет token, session, conference, appointment_id, format, user
	}

	onInitPublisher = (error) => {
		if (error && error.name === 'DEVICE_ACCESS_DENIED') {
			this.setState({ deviceAccessDenied: true });
			console.log('вы не разрешили использовать камеру')
		}
	}

	tryRecallUser = () => {
		const { recallUser, session } = this.props;
		const { participantJoined } = this.state;
		if (!participantJoined) {
			recallUser(session)
			return 1;
		}
		return 0;
	}

	setVideoFormat = () => {
		const res = this.tryRecallUser();
		if (!res) {
			this.ref.setVideoFormat();
		}
	}

	setAudioFormat = () => {
		const res = this.tryRecallUser();
		if (!res) {
			this.ref.setAudioFormat();
		}
	}

	setChatFormat = () => {
		const res = this.tryRecallUser();
		if (!res) {
			this.ref.setChatFormat();
		}
	}

	onConnectionCreated = (data) => {
		const { user, session } = this.props;
		if (user.user_id !== data.serverData.userId) {
			this.setState({ participantJoined: true });
			this.props.conferenceStarted(session);
			events.emit('stop-sound')
			if (user.role === 'doctor') {
				this.ref.setVideoFormat();
			}
		}
	}

	onConnectionDestroyed = (data) => {
		const { user } = this.props;
		if (user.user_id !== data.serverData.userId) {
			this.setState({ participantJoined: false })
		}
	}

	onChangeSubscribers = (size) => {
	}

	getRef = (ref) => { this.ref = ref; }

	render() {
		console.log(this.state.onhold)
		const hasAttachments = !!this.props.attachments.length;
		const { user, appointment_id, participant, isTrusted, sessionId } = this.props;
		const { onhold, format, standByText } = this.state;
		return <ScreenWrapper style={{ padding: 0 }} showLogo conferenceId={user && user.role == 'doctor' ? sessionId : null}>
			<div className='ConferencePlane'>
				<div className='conferenceGUI'>
					{/* <div className='ConferenceHoldGUI' style={onhold ? {} : { display: 'none' }}>
						<div className='header'>
							<Title name={formatName(participant)} timerUntil={this.timerUntil} />
						</div>
						<div className='body'>
							<Center
								type={'video'}
								name={formatName(participant)}
								timerUntil={this.timerUntil}
							/>
							<h2>Звонок завершен!</h2>
							<p className='timer'>Осталось <Timer until={this.timerUntil} onTimeout={() => { }} /> минут</p>
							<div className='buttons'>
								<Button onClick={() => this.setState({ onhold: false }, this.setVideoFormat)} title={<><CameraGrayIcon /> Видео звонок</>} />
								<Button onClick={() => this.setState({ onhold: false }, this.setAudioFormat)} title={<><PhoneGrayIcon /> Аудио звонок</>} />
								<Button onClick={() => this.setState({ onhold: false }, this.setChatFormat)} title={<><ChatGrayIcon /> Чат</>} />
								<Button onClick={() => this.props.leaveConference(this.props.session)} title={<>Завершить прием</>} className='red' />
							</div>
							<small>При нажатии на “Завершить прием”, связь с пациентом возобновить не получится!</small>
						</div>
					</div> */}
					{(!!isTrusted || (user && user.role === 'doctor') ) && <OpvSession
						id="opv-session"
						// style={onhold ? { display: 'none' } : {}}
						sessionName={this.props.session}
						user={formatName(user)}
						token={this.props.token}
						resolution={'1280x720'}
						// resolution={'320x240'}
						role={user.role}
						format={format}
						joinSession={e => console.info(e)}
						leaveSession={this.leaveConference}
						onInitPublisher={this.onInitPublisher}
						onLeaveSession={this.onLeaveSession}
						onConnectionCreated={this.onConnectionCreated}
						onConnectionDestroyed={this.onConnectionDestroyed}
						error={e => console.error(e)}
						title={<Title name={formatName(participant)} timerUntil={this.timerUntil} />}
						onChangeSubscribers={this.onChangeSubscribers}
						standByText={standByText}
						centerChat={<Center
							type={'chat'}
							name={formatName(participant)}
							timerUntil={this.timerUntil}
						/>}
						centerAudio={<Center
							type={'audio'}
							name={formatName(participant)}
							timerUntil={this.timerUntil}
						/>}
						ref={this.getRef}
					/>}
					<Modal
						visible={!isTrusted && (user && user.role !== 'doctor')}
						className="TrustMe"
						title="Онлайн-консультация"
						onShutter={() => { }}
					>
						<Button
							title={'Нажмите сюда, чтобы продолжить'}
							onClick={() => { }}
							style={{ padding: 10, margin: 10 }}
						/>
					</Modal>
				</div>
				<div className={`ConferenceRight ${hasAttachments ? 'HasAttachments' : ''}`}>
					{hasAttachments && <div className="ConferenceAttachments">
						<div className="Title">Документы</div>
						<AttachmentList attachments={this.props.attachments} onDelete={this.props.delAttachment} showDate title="Документы" />
					</div>}
					<Chat title="Чат" room={this.props.conference} appointment_id={appointment_id} />
				</div>
			</div>
			<Permissions
				name="camera"
				visible={this.state.deviceAccessDenied}
				onClose={() => {
					this.setState({ deviceAccessDenied: false });
					this.leaveConference();
				}}
			/>
		</ScreenWrapper>
	}
}

Conference.defaultProps = {
	attachments: [],
}

const mockAttachments = [
	{
		attachment_id: 1,
		name: '15404995030130.png',
		path: '1586768690662-7626607425.png',
		appointment_id: 107338076,
		inserted_at: '2020-04-13T09:06:18.000Z',
		user_id: 2
	},
	{
		attachment_id: 2,
		name: '15420250973580.jpg',
		path: '1586768876130-548972196.jpg',
		appointment_id: 107338076,
		inserted_at: '2020-04-13T09:07:59.000Z',
		user_id: 2
	}
]

export default withRouter(connect(state => (
	{
		user: state.User.user,
		session: state.Conference.session,
		token: state.Conference.token,
		reason: state.Conference.reason,
		holdon: state.Conference.holdon,
		attachments: state.Attachments.data,
		participant: state.Conference.user,
		// attachments: [...mockAttachments, ...mockAttachments, ...mockAttachments, ...mockAttachments, ...mockAttachments],
		conference: state.Conference.conference,
		appointment_id: state.Conference.appointment_id,
		sessionId: state.Conference.session,
		format: state.Conference.format,
		isTrusted: state.Sound.isTrusted,
		started: state.Conference.started,
		offline: state.Users.offline,
		online: state.Users.online,
	}),
	{
		leaveConference: params => ({ type: 'ROOM_DISCONNECT', params }),
		getAttchments: params => ({ type: 'ATTACHMENTS_GET', params }),
		delAttachment: (params) => ({ type: 'ATTACHMENT_DEL', params }),
		cleanToken: (session) => ({ type: 'CLEAN_TOKEN', payload: session }),
		recallUser: (session) => ({ type: 'RECALL_USER', payload: session }),
		stopCalling: (session) => ({ type: 'STOP_CALLING', payload: session }),
		conferenceStarted: (session) => ({ type: 'CONFERENCE_STARTED', payload: session }),
		shiftOffline: () => ({ type: 'SHIFT_USER_OFFLINE ' }),
		chatRoomActiveSet: chatRoomActiveSet,
	},
)(Conference));